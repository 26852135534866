<template>
  <div class="stack">
    <Toast position="top-right" />
    <page-block :title="$t('title.main')" size="bigger">
      <template #action>
        <plain-button color="del" nowrap @click="gotoPanel">
          {{ $t('panel') }}
        </plain-button>
      </template>
      <main-card class="security-password">
        <div class="security-password__form">
          {{ $t('access') }}<br />
          <plain-button
            v-tooltip="{
              content: 'скачать',
              placement: 'top-start',
              container: 'body',
            }"
            color="primary"
            class="security-password-left"
            @click="getFileRc()"
          >
            {{ $t('download') }}
          </plain-button>
          <plain-button
            v-tooltip="{
              content: 'скачать',
              placement: 'top-start',
              container: 'body',
            }"
            color="primary"
            class="security-password-left"
            @click="getFileYaml()"
          >
            {{ $t('downloadCloudsYaml') }}
          </plain-button>
        </div>
        <div class="security-password__form">
          <div class="security-password__field">
            <base-input
              :value="login"
              type="text"
              :label="$t('login')"
              :readonly="true"
              class="form__field--input security-password__field"
            >
            </base-input>
          </div>
          <div class="security-password__field">
            <base-input
              :value="password"
              :type="type"
              :label="$t('password')"
              :readonly="true"
              class="form__field--input security-password__field"
            >
              <template #fieldAfter>
                <plain-button
                  :icon="type === 'text' ? 'eye-closed' : 'eye-open'"
                  color="dark"
                  tabindex="-1"
                  @click="showPassword()"
                />
              </template>
            </base-input>
          </div>
        </div>
        <base-stack-collapser :more="'Изменить пароль'">
          <recover-stack-password :recovery-info="formData" class="security__card" />
        </base-stack-collapser>
        <base-stack-collapser :more="'Задать порог уведомления'">
          <div class="security-password__form">
            <div class="security-password__field">
              <base-input
                :value="notifyTreshhold.value"
                type="number"
                :label="notifyTreshhold.label"
                use-reactive-validation
                :hint="notifyTreshhold.hint"
                class="form__field--input collapser"
                @input="changeNotifyTreshhold($event)"
              >
              </base-input>
            </div>
            <div class="security-password__field security-password__btn">
              <base-button
                v-if="notifyTreshhold.label"
                :loading="clickedButton"
                :disabled="!notify || notify === notifyTreshhold.value"
                class="base-autoprolong__btn"
                @click="sendNotifyTreshhold()"
              >
                {{ $t('sendNotifyTreshhold') }}
              </base-button>
            </div>
          </div>
        </base-stack-collapser>
      </main-card>
    </page-block>
    <page-block :title="$t('secrets')" size="bigger" class="vps-detail-backup__block">
      <template #action>
        <plain-button color="primary" nowrap @click="addContainer()">
          {{ $t('newSecret') }}
        </plain-button>
      </template>
      <tariffs-table-secrets />
    </page-block>
    <page-block :title="$t('ssh')" size="bigger">
      <template #action>
        <plain-button color="primary" nowrap @click="newSsh()">
          {{ $t('newSsh') }}
        </plain-button>
      </template>
      <tariffs-table-ssh />
    </page-block>
    <page-block id="s3Keys" :title="$t('s3Keys')" size="bigger">
      <template #action>
        <plain-button
          :disabled="!isAvailableCreateS3Key"
          color="primary"
          nowrap
          @click="newS3Key()"
        >
          {{ $t('newS3Key') }}
        </plain-button>
      </template>
      <tariffs-table-s3-keys :id="id" @toast="openToast($event)" />
    </page-block>
    <page-block v-if="flavors.length" :title="$t('flavor')" size="bigger">
      <tariffs-table-flavors />
    </page-block>
    <div class="stack-info"></div>
  </div>
</template>

<script>
import TariffsTableSsh from '../../Main/components/TariffsTableSsh';
import RecoverStackPassword from '@/layouts/Stack/components/RecoverStackPassword';
import newSsh from '@/mixins/newSsh';
import Toast from 'primevue/toast';
import addContainer from '@/mixins/addContainer';
import BaseInput from '@/components/BaseInput/BaseInput';
import BaseStackCollapser from '@/components/BaseCollapser/BaseStackCollapser.vue';
import TariffsTableSecrets from '@/layouts/Stack/pages/Main/components/TariffsTableSecrets';
import TariffsTableFlavors from '@/layouts/Stack/pages/Main/components/TariffsTableFlavors';
import { saveAs } from 'file-saver';
import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import showErrorModal from '@/mixins/showErrorModal';
import redirectLink from '@/mixins/redirectLink';
import TariffsTableS3Keys from '@/layouts/Stack/pages/Main/components/TariffsTableS3Keys';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
export default {
  name: 'ViewSsh',
  components: {
    TariffsTableS3Keys,
    TariffsTableSecrets,
    TariffsTableFlavors,
    TariffsTableSsh,
    RecoverStackPassword,
    Toast,
    BaseStackCollapser,
    BaseInput,
  },
  mixins: [newSsh, addContainer, billMgrToolActions, showErrorModal, redirectLink],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      timerCnt: 1,
      clickedButton: false,
      notify: '',
      blob: [],
      type: 'password',
      formData: {},
      isRequest: true,
    };
  },
  computed: {
    flavors() {
      return this.$store.state.moduleStack.flavors.filter(fl => !fl['os-flavor-access:is_public']);
    },
    anchorTag() {
      return this.$route.hash;
    },
    password() {
      return this.$store.getters['moduleStack/GET_CURRENT_SERVER'] &&
        this.$store.getters['moduleStack/GET_CURRENT_SERVER']._info
        ? this.$store.getters['moduleStack/GET_CURRENT_SERVER']._info.customfields.password.value
        : '';
    },
    notifyTreshhold() {
      return this.$store.getters['moduleStack/GET_CURRENT_SERVER'] &&
        this.$store.getters['moduleStack/GET_CURRENT_SERVER']._info
        ? this.$store.getters['moduleStack/GET_CURRENT_SERVER']._info.customfields.notify_treshhold
        : {};
    },
    isAvailableCreateS3Key() {
      return this.$store.state.moduleStack.s3Keys.length < 10;
    },
    login() {
      return `user${this.id}`;
    },
    projectName() {
      return `vdc${this.id}`;
    },
    yaml() {
      return `# This is a clouds.yaml file, which can be used by OpenStack tools as a source
# of configuration on how to connect to a cloud. If this is your only cloud,
# just put this file in ~/.config/openstack/clouds.yaml and tools like
# python-openstackclient will just work with no further config. (You will need
# to add your password to the auth section)
# If you have more than one cloud account, add the cloud entry to the clouds
# section of your existing file and you can refer to them by name with
# OS_CLOUD=openstack or --os-cloud=openstack
clouds:
  openstack:
    auth:
      auth_url: https://cloudapi.rusonyx.ru/identity
      username: "${this.login}"
      project_id: ${this.project}
      project_name: "${this.projectName}"
      user_domain_name: "Default"
    region_name: "ru-msk"
    interface: "public"
    identity_api_version: 3
      `;
    },
    accessRc() {
      return `#!/usr/bin/env bash
# To use an OpenStack cloud you need to authenticate against the Identity
# service named keystone, which returns a Token and Service Catalog.
# The catalog contains the endpoints for all services the user/tenant has
# access to - such as Compute, Image Service, Identity, Object Storage, Block
# Storage, and Networking (code-named nova, glance, keystone, swift,
# cinder, and neutron).
#
# *NOTE*: Using the 3 *Identity API* does not necessarily mean any other
# OpenStack API is version 3. For example, your cloud provider may implement
# Image API v1.1, Block Storage API v2, and Compute API v2.0. OS_AUTH_URL is
# only for the Identity API served through keystone.

export OS_AUTH_URL=https://cloudapi.rusonyx.ru/identity
# With the addition of Keystone we have standardized on the term project
# as the entity that owns the resources.
export OS_PROJECT_ID=${this.project}
export OS_PROJECT_NAME="${this.projectName}"
export OS_USER_DOMAIN_NAME="Default"
if [ -z "$OS_USER_DOMAIN_NAME" ]; then unset OS_USER_DOMAIN_NAME; fi
export OS_PROJECT_DOMAIN_ID="default"
if [ -z "$OS_PROJECT_DOMAIN_ID" ]; then unset OS_PROJECT_DOMAIN_ID; fi
# unset v2.0 items in case set
unset OS_TENANT_ID
unset OS_TENANT_NAME
# In addition to the owning entity (tenant), OpenStack stores the entity
# performing the action as the user.
export OS_USERNAME="${this.login}"
# With Keystone you pass the keystone password.
echo "Please enter your OpenStack Password for project $OS_PROJECT_NAME as user $OS_USERNAME: "
read -sr OS_PASSWORD_INPUT
export OS_PASSWORD=$OS_PASSWORD_INPUT
# If your configuration has multiple regions, we set that information here.
# OS_REGION_NAME is optional and only valid in certain environments.
export OS_REGION_NAME="ru-msk"
# Don't leave a blank variable, unset it if it was empty
if [ -z "$OS_REGION_NAME" ]; then unset OS_REGION_NAME; fi
export OS_INTERFACE=public
export OS_IDENTITY_API_VERSION=3`;
    },
  },
  mounted() {
    this.getOpenStackApiKey()
      .then(() => {
        this.newFetch();
      })
      .catch(error => {
        this.showError(error);
        setTimeout(() => this.$router.push({ name: 'Login' }), 400);
      });
    this.$store.dispatch('moduleStack/fetchOsKeypair');
    this.$store.dispatch('moduleStack/fetchDetail', this.id);
    this.$store.dispatch('moduleStack/getS3StoragesKeys', this.id);
    this.$nextTick(() => {
      if (this.anchorTag) {
        const refName = this.anchorTag;
        this.scrollToAnchorPoint(refName);
      }
    });
  },
  methods: {
    getFileRc() {
      const blob = new Blob([this.accessRc], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'access.rc');
    },
    scrollToAnchorPoint(refName) {
      const el = document.querySelector(`#${refName}`);
      const url = window.location.href.replace(refName, '');

      el.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => (window.location.href = url), 1000);
    },
    newS3Key() {
      this.$store
        .dispatch('moduleStack/createS3StoragesKey', this.id)
        .then(data => {
          if (data && data.result === 'ok') {
            this.showSuccess(
              this.$t('created', { access: data.ec2.access, secret: data.ec2.secret })
            );
          }
        })
        .then(() => this.$store.dispatch('moduleStack/getS3StoragesKeys', this.id))
        .catch(e => this.showError(e));
    },
    getFileYaml() {
      const blob = new Blob([this.yaml], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'clouds.yaml');
    },
    getOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/getOpenStackApiKey', this.id);
    },
    validateOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/validateOpenStackApiKey', this.id);
    },
    showPassword() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
    changeNotifyTreshhold(event) {
      this.notify = event;
    },
    sendNotifyTreshhold() {
      this.clickedButton = true;
      const params = {
        clicked_button: 'on',
        sok: 'ok',
        notify_treshhold: this.notify,
        func: 'vdc3.edit',
        elid: this.id,
        auth: localStorage.getItem('token'),
        out: 'bjson',
      };
      this.sendBillMgrAction(params)
        .then(async data => {
          if (data && data.ok) {
            await this.$store.dispatch('moduleStack/fetchList');
            await setTimeout(() => this.$store.dispatch('moduleStack/fetchDetail', this.id), 1500);
            this.showSuccess(this.$t('success'));
          }
        })
        .catch(e => this.showError(e))
        .finally(() => (this.clickedButton = false));
      // }
    },
    showSuccess(msg) {
      this.$modals.open({
        name: 'Success',
        html: true,
        text: msg,
        footer: {
          centered: true,
          confirm: {
            props: { title: this.$t('close') },
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
    openToast(event) {
      this.$toast.add({
        severity: event.type,
        summary: event.details + ' ' + event.item,
        detail: event.title,
        life: event.life,
      });
    },
    gotoPanel() {
      let redirectLink = window.open();
      this.$store
        .dispatch('moduleStack/fetchBillMgrToolAction', {
          func: 'gotoserver',
          elid: this.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            redirectLink.location.href = data.ok.v;
            // window.open(data.ok.v);
            if (!redirectLink) {
              this.$modals.close();
              this.showModal(data.ok.v);
            }
          }
        });
    },
  },
};
</script>

<i18n>
{
  "ru": {
      "ssh": "SSH-ключи",
      "s3Keys": "S3 ключи",
      "flavor": "Пользовательские конфигурации сервера",

    "login": "Логин",
    "sendNotifyTreshhold": "Подтвердить",
    "success": "Порог уведомления о низком балансе успешно изменён.",
    "notifyTreshhold": "Порог уведомления",
    "title": {
      "main":"Реквизиты для доступа к услуге",
      "certificate": "Форма добавления сертификата",
      "ssh": "Форма добавления SSH-ключа"
    },
    "password": "Пароль",
    "download": "access.rc",
    "downloadCloudsYaml": "clouds.yaml",
    "secrets": "Менеджер сертификатов",
    "panel": "Перейти в панель",
    "access": "Реквизиты доступа к услуге для подключения внешних утилит",
    "newPass": "Подтвердить",
    "sure": {
      "confirm": "Добавить ключ",
      "certificate": "Добавить сертификат",
      "success": "Сертификат успешно добавлен",
      "key": "Ключ успешно добавлен",
      "close": "Закрыть"
    },
    "created": "Создан новый ключ S3: <br/><br/> <b>access</b>: %{access}<br/> <b>secret</b>: %{secret} ",
    "newSsh": "Добавить SSH - ключ",
    "newS3Key": "Добавить S3 ключ",
    "newSecret": "Добавить сертификат"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.security-password {
  margin-bottom: 2.5rem;
  &-left {
    margin-left: 2rem;

    +breakpoint(ms-and-down) {
      margin: 10px 0;
    }
  }

  &__form {
    +breakpoint(sm-and-up) {
      flexy(flex-start, flex-end, wrap);
    }
    +breakpoint(md-and-up) {
      flex-wrap: nowrap;
      padding-bottom: 1rem;
    }
  }

  &__field {
    +breakpoint(sm-and-up) {
      flex: 1 1 auto;
    }

    & + & {
      margin-top: 0.25rem;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
  &__btn{
    margin-bottom: 0.6em;
  }
  &__footer {
    margin-top: 2.5rem;
    margin-left: 1rem;
    text-align: center;
  }

  &__submit {
    //&__submit {
      min-width: 165px;
    //}
    //width: 100%;
    text-align: center;
    white-space: nowrap;
    //margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      width: auto;
    }
    +breakpoint(md-and-up) {
      margin-top: 0;
      margin-left: 1.5rem;
    }
  }
}
  .collapser {
    min-height: 80px;
  }
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

    &__create {
      flex: 0 0 auto;
      margin-top: 1.5rem;
      max-width: 160px;

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
