<template>
  <div class="new-ticket">
    <transition name="fade" mode="out-in" @after-enter="setFocus()">
      <base-loader v-if="isLoading" />
      <div v-else class="new-ticket__content">
        <div class="new-ticket__form" @keyup.enter="submit">
          <div class="l-col">
            <!--            <label class="typo__label">{{ $t('label') }}</label>-->
            <base-select
              v-if="internalValue !== '3'"
              v-model="services"
              select-label=""
              :hide-selected="true"
              placeholder="Выберите услугу, с которой связан Ваш запрос"
              :field-label="$t('label')"
              :required="true"
              :searchable="true"
              :options="options"
              size="medium"
              :allow-empty="false"
              class="new-ticket__services"
            >
            </base-select>
            <!--              :preselect-first="true"-->

            <base-input
              v-model="subject"
              :placeholder="$t('ticket.placeholder')"
              size="small"
              :label="subjectValue || $t('ticket.label')"
              class="allowed-user-settings-form__field allowed-user-settings-form__field--input"
            >
            </base-input>
          </div>
          <!--          <div class="l-flex-1_sm-2 gutter-24 send-message__container-department">-->
          <!--            <div v-for="opt in department" :key="opt.k" class="send-message__department">-->
          <!--              <base-radio-->
          <!--                v-if="internalValue !== '3'"-->
          <!--                v-model="internalValue"-->
          <!--                :value="opt.k"-->
          <!--                theme="tile"-->
          <!--                class="paymethods-list-block__radio"-->
          <!--              >-->
          <!--                {{ opt.v }}-->
          <!--              </base-radio>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="l-col send-message">
            <div class="send-message__input">
              <base-input
                :label="textValue || $t('message.label')"
                v-model="sendingText"
                type="textarea"
                :placeholder="$t('message.placeholder')"
              />
            </div>
          </div>
          <div class="send-message__send" :class="classes">
            <div v-if="internalValue !== '3'" class="send-message__container">
              <vue-dropzone
                id="dropzone"
                ref="Dropzone"
                class="dropzone"
                :duplicate-check="true"
                :include-styling="false"
                :use-custom-slot="true"
                :options="dropzoneOptions"
                @vdropzone-file-added="fileAdded"
                @vdropzone-success-multiple="success"
              >
                <div class="dropzone-container">
                  <plain-button
                    v-if="this.$store.getters['moduleSupport/GET_FILES'].length < 5"
                    color="primary"
                    class="dropzone-container__file-link"
                  >
                    <div class="send-message__text">
                      <svg-icon name="clip" class="send-message__icon" />{{ $t('add_file') }}
                    </div>
                  </plain-button>
                </div>
              </vue-dropzone>
              <AttachmentList
                class="send-message__container-dropzone__list"
                :temp-attachments="getTempAttachments"
                :attachments="getAttachments"
              />
            </div>
            <div class="send-message__container-btns">
              <base-button
                :disabled="!services || !subject || !sendingText"
                class="send-message__send-btn"
                :loading="sendingMessage"
                @click="sendMessageTo()"
                >{{ $t('btn.send') }}
              </base-button>
              <base-button
                theme="outlined"
                class="send-message__send-btn"
                :loading="sendingMessage"
                @click="modalClose()"
                >{{ $t('btn.cancel') }}
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * @TODO:
 * - сравить NewTicket и SendMessage, вынести повторяющийся код или рассмотреть возможность использования одного компонента с разными настройками
 */
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import vue2Dropzone from 'vue2-dropzone';
import AttachmentList from './AttachmentList';
import showErrorModal from '@/mixins/showErrorModal';
// import BaseRadio from '@/components/Checkboxes/BaseRadio.vue';
import { NOTWORKDAY } from '../../../../public/config';
import { format } from 'date-fns';
import Vue from 'vue';
import { isEqual } from 'lodash';
export default {
  name: 'NewTicket',
  components: {
    BaseInput,
    BaseSelect,
    vueDropzone: vue2Dropzone,
    AttachmentList,
    // BaseRadio,
  },
  mixins: [setFocus, showErrorModal],
  props: {
    formLoading: {
      type: Boolean,
      default: false,
    },
    ticketThemes: {
      type: String,
      default: '',
    },
    ticketText: {
      type: String,
      default: '',
    },
    propsOptions: {
      type: String,
      default: '',
    },
    isInternalValue: {
      type: String,
      default: '',
    },
    subjectValue: {
      type: String,
      default: '',
    },
    textValue: {
      type: String,
      default: '',
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      internalValue: this.isInternalValue || '1010331',
      isLoading: !!this.user,
      services: this.propsOptions || '',
      subject: this.ticketThemes || '',
      message: '',
      initialDataBackup: null,
      file: '',
      status: '',
      isHoliday: false,
      sendingText: this.ticketText || '',
      params: {},
      sendingMessage: false,
      tempAttachments: [],
      attachments: [],
      dropzoneOptions: {
        // url: 'https://my.rusonyx.ru/billmgr',
        url: `${this.$store.state.moduleApp.host}/billmgr`,
        method: 'post',
        maxFilesize: 10,
        maxFiles: 5,
        autoProcessQueue: false,
        clickable: true,
        includeStyling: false,
        previewsContainer: false,
        uploadMultiple: true,
        headers: {
          'Content-Type': 'application/json',
        },
        parallelUploads: 5,
        // acceptedFiles: '.jpeg,.jpg,.png,.gif,.JPEG,.JPG,.PNG,.GIF,.pdf,.doc,.docx',
      },
    };
  },
  computed: {
    classes() {
      return this.isInternalValue === '3' ? 'send-message__send-banner' : '';
    },
    department() {
      return this.$store.getters['moduleSupport/GET_DEPARTMENT'].filter(x =>
        this.isWorkingDay
          ? x.k !== '1093256' && x.k !== '3' && x.k !== '1094990'
          : x.k !== '1094990' && x.k !== '1010344' && x.k !== '1093256' && x.k !== '3'
      );
    },
    hasChanges() {
      return !!this.subject || !!this.sendingText;
    },
    isWorkingDay() {
      const workingDay = Array.from({ length: 5 }, (v, k) => k + 1);
      return workingDay.includes(new Date().getDay()) && !this.isHoliday;
    },
    options() {
      return this.$store.getters['moduleSupport/GET_SERVICE_LIST']
        ? this.$store.getters['moduleSupport/GET_SERVICE_LIST']
            .map(x => x.v)
            .sort(function (a, b) {
              if (a > b) {
                return 1;
              }
              if (a < b) {
                return -1;
              }
              return 0;
            })
        : [this.$t('ticket.no_tittle')];
    },
    values() {
      return this.$store.getters['moduleSupport/GET_SERVICE_LIST'];
    },
    getValue() {
      if (this.values.length > 0) {
        return this.values.filter(i => i.v === this.services).map(x => x.k);
      } else {
        return '';
      }
    },
    getTempAttachments() {
      return this.tempAttachments;
    },
    getAttachments() {
      return this.attachments;
    },
  },
  watch: {
    hasChanges(val) {
      this.$emit('change', val);
    },
  },
  mounted() {
    this.getCalendar();
  },
  methods: {
    getCalendar() {
      const currentDate = format(new Date(), 'dd-MM-yyyy');
      if (NOTWORKDAY.includes(currentDate)) return (this.isHoliday = true);
      else return (this.isHoliday = false);
    },
    sendMessageTo() {
      this.sendingMessage = true;
      const files = this.isInternalValue !== '3' ? this.$refs.Dropzone.getQueuedFiles() : '';
      let formData = new FormData();
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          formData.append(`file_${i + 1}`, file);
          formData.delete(`file[${i}]`);
        }
      }
      formData.append('auth', localStorage.getItem('token'));
      formData.append('sok', 'ok');
      formData.append('message', this.sendingText);
      formData.append('ticket_item', this.getValue[0]);
      formData.append('subject', this.subject);
      formData.append('client_department', this.internalValue);
      formData.append('out', 'bjson');
      formData.append('func', 'clientticket.edit');

      if (this.sendingText.length > 0) {
        this.$store
          .dispatch('moduleSupport/sendMessage', formData)
          .then(data => {
            if (data.ok) {
              // return Promise.all([this.$store.dispatch('moduleSupport/fetchAllTickets')])
              return (
                Promise.all([this.$store.dispatch('moduleSupport/fetchServiceList')])
                  .then(() => setTimeout(() => (this.isSending = false), 500))
                  .then(() => (this.sendingText = ''))
                  // .then(() => this.$store.dispatch('moduleSupport/fetchServiceList'))
                  .then(() => this.$nextTick())
              );
            } else {
              // console.log(data);
            }
          })
          .catch(e => {
            this.$modals.close();
            this.showAlert(e.msg);
            this.isSending = false;
            this.showError(e);
          })
          .finally(() => {
            this.$modals.close();
            // this.$store.dispatch('moduleSupport/fetchServiceList');
            this.sendMessageToDone();
          });
      } else {
        this.showAlert(this.$t('error.null'));
        return setTimeout(() => {
          this.sendingMessage = false;
        }, 1500);
      }
    },
    modalClose() {
      this.$modals.close();
    },
    sendMessageToDone() {
      if (!this.modal) this.makeModal('sendMessageDone');
      Vue.set(this.modal, 'text', this.$t('done'));
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'footer', {
        centered: true,
        confirm: {
          props: { title: this.$t('btn.ok') },
          on: { click: () => this.$modals.close() },
        },
      });
    },
    makeModal(name) {
      this.$modals.open({
        name: name,
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
      });
    },
    fileAdded(file) {
      let attachment = {};
      attachment.file = file;
      attachment._id = file.upload.uuid;
      attachment.title = file.name;
      attachment.type = 'file';
      attachment.extension = '.' + file.type.split('/')[1];
      attachment.isLoading = true;
      attachment.progress = null;
      attachment.size = file.size;
      if (
        attachment.size < 10 * 1024 * 1024 &&
        this.tempAttachments.length < 5 &&
        !this.tempAttachments.map(t => t.title).includes(attachment.title)
      ) {
        if (this.tempAttachments.length !== this.$store.state.moduleSupport.files.length) {
          this.$store.dispatch('moduleSupport/resetFiles');
        }
        this.tempAttachments = [...this.tempAttachments, attachment];
        this.$store.dispatch('moduleSupport/addFiles', file);
      } else {
        this.showError();
      }
    },
    success() {
      this.tempAttachments = [];
      this.attachments = [];
    },
    submit() {
      if (this.isValid && this.hasChanges) {
        const data = this.getFormData();
        this.$emit('submit', data);
        this.$store.dispatch('moduleSupport/fetchAllTickets');
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": "С какой услугой связан запрос:",
    "done": "Запрос успешно отправлен",
    "ticket": {
      "placeholder":"Тема запроса",
      "label": "Краткая формулировка проблемы:",
      "no_tittle": "Запрос не связан с услугой"
    },
    "message": {
      "label": "Описание проблемы:",
      "placeholder": "Содержимое"
    },
    "add_file": "Загрузить файл",
    "btn": {
      "send": "Подтвердить",
      "cancel": "Отменить",
      "ok": "Понятно"
    },
    "error": {
      "title": "Добавление файла",
      "null": "Сообщение не должно быть пустым",
      "text": "Добавляемых файлов должно быть не более 5-ти, размер каждого не должен превышать 10 МиБ и они должны отличаться друг от друга"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.new-ticket__services {
  text-overflow: unset;
  overflow: visible;
}
.allowed-user-settings-form {
  &__field {
    &  {
      margin-top: 1.25rem;
    }

  }
  &__footer {
    +breakpoint(sm-and-up) {
      margin: 1.5rem auto 0;
      flexy(flex-end, center);
    }
  }
  &__btn {
    width: 100%;
    +breakpoint(sm-and-up) {
      width: auto;
    }
  }
}
.send {
  width:100%
}

.send-message__input {
  margin-top: 10px;
  //max-height: 200px;
  max-height: auto;
}
.send-message__icon {
  width: 16px;
  color: $color-light.light-blue;
}

.send-message__title {
  display: flex;
  font-size: $font-size-base;
  padding-bottom: 10px;
}
  .send-message__container-department {
  display: flex;
    padding: 0 1rem;
    justify-content: space-between;
    +breakpoint(ms-only) {
    padding: 0 1.25rem;
    }
    +breakpoint(sm-only) {
      padding: 0 1.5rem;
    }
    +breakpoint(md-and-up) {
    padding: 0 1.75rem
    }
  }
.send-message__department {
  display: flex;
  justify-content: space-around;
  margin-top: 0.5rem;
}
.send-message__send {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}
.send-message__send-banner {
  justify-content:  flex-end;
}
.send-message{
&__send{
&-btn {
  //margin-top: 20px
  margin-right: 0;
  //+breakpoint(ms-and-up) {
  //  margin-top: 60px;
  //}

  & + & {
  +breakpoint(sm-and-up) {
    margin-top: 0;
    margin-left: 1.5rem;
  }
  }
}
}
}
  .send-message__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-self: flex-start;
  }
.send-message__container-dropzone__list{
  display: flex;
}
.send-message{

&__container{
&-btns {
  padding: 1.5rem 1rem;
  flexy(flex-end, center, wrap);

    ^[-1]--centered & {
    justify-content: center;
  }
  +breakpoint(ms-and-down) {
    width: 100%;
  }
  +breakpoint(sm-and-up) {
    flex: 0 0 auto;
  }

}
}
}
.send-message__text {
  font-size: 0.8rem;
}
  .dropzone__list {
    display: flex;
  }
</style>
