import NewIp from '@/layouts/Stack/components/NewIp';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, storeMixin, modals],
  data() {
    return {
      device_id: '',
      data: {},
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {
    network() {
      return this.$store.state.moduleStack.networks.filter(x => x['router:external'] === true);
    },
  },
  methods: {
    newIp: function () {
      const that = this;
      let network = '';
      return new Promise(() => {
        const selfName = 'newIp';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: NewIp,
          closeOnBackdrop: false,
          props: {
            instance: this.network,
          },
          text: this.$t('sure.text'),
          on: {
            change: data => {
              network = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm'), loading: false },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });

                  this.addFloatIp(network)
                    .then(async data => {
                      const msg = data.floatingip.floating_ip_address;
                      this.fetchServer();

                      that.$modals.close();
                      // this.showResModal(this.$t('success', { msg }));
                      const text = `Плавающий IP <b>${msg}</b> успешно выделен.`;
                      this.showResModal(text);
                    })
                    .catch(e => {
                      this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    addFloatIp(payload) {
      return this.$store.dispatch('moduleStack/createFloatIp', payload).catch(e => {
        this.$modals.close();
        this.showError(e);
      });
    },
  },
};
