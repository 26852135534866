<template>
  <div class="stack">
    <base-loader v-if="isRequest" class="open-ticket__loader"> </base-loader>
    <page-block v-else-if="!isRequest" style="margin-top: 0">
      <!--      <transition name="fade" mode="out-in" appear>-->
      <div class="stack-info__content">
        <page-title v-if="s3keys.length" class="stack-info__title">
          {{ $t('title.storage') }}
        </page-title>
        <div v-if="s3keys.length" class="stack-info__create">
          <div class="mr">
            <base-button
              :class="{ visible: s3keys.length }"
              class="stack-info__create-btn"
              @click="newStorage(id)"
            >
              {{ $t('newStorage') }}
            </base-button>
          </div>
          <div>
            <base-button
              theme="outlined"
              class="stack-info__create-btn"
              color="primary"
              :to="{ name: 'containerAccess', hash: 's3Keys' }"
            >
              {{ $t('s3keys') }}
            </base-button>
          </div>
        </div>
      </div>
      <base-empty
        v-if="!s3keys.length"
        :title="$t('empty.title')"
        :text="$t('empty.text')"
        class="ssl-info__empty"
        ><template #link>
          <base-button :loading="isEmpty" @click="addKey">
            {{ $t('empty.add') }}
          </base-button>
        </template>
      </base-empty>
      <tariffs-table-storage v-else :id="id" :storages="storages" :table-head="tableHead" />
      <!--      </transition>-->
    </page-block>
  </div>
</template>

<script>
import TariffsTableStorage from '../../Main/components/TariffsTableStorage';
import newStorage from '@/mixins/newStorage';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
// import { S3Client, ListBucketsCommand, ListObjectsV2Command } from '@aws-sdk/client-s3';
// import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
// import { S3Client } from '@aws-sdk/client-s3';
// const client = new S3Client({
//   region: 'ru-msk',
//   endpoint: 'https://s3.rusonyxcloud.ru',
//   credentials: {
//     accessKeyId: 'aa4ed1dfcf1845e5a8f4cbfc9cfa1618',
//     secretAccessKey: 'a2a6fda4ee424ce9a2aafb48aab3fc9b',
//   },
// });
export default {
  name: 'ViewStorage',
  components: {
    TariffsTableStorage,
    BaseEmpty,
  },
  mixins: [newStorage, showErrorOpenStackModal],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'icon',
          style: {
            width: '20px',
            maxWidth: '20px',
            minWidth: '20px',
            textAlign: 'right',
            // maxWidth: '400px',
          },
        },
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            width: '400px',
            maxWidth: '400px',
          },
          sort: {
            prop: 'name',
            order: 'asc',
          },
        },
        // {
        //   key: 'rule',
        //   label: this.$t('tableHead.rule'),
        //   style: {
        //     width: '130px',
        //     maxWidth: '130px',
        //   },
        // },

        {
          key: 'type',
          label: this.$t('tableHead.type'),
          style: {
            width: '100px',
          },
          // sort: {
          //   prop: 'operating_status',
          //   order: 'asc',
          // },
        },
        // {
        //   key: 'state',
        //   label: this.$t('tableHead.state'),
        //   style: {
        //     width: '48px',
        //   },
        //   sort: {
        //     prop: 'provisioning_status',
        //     order: 'asc',
        //   },
        // },
        {
          key: 'volume',
          label: this.$t('tableHead.volume'),
          style: {
            width: '60px',
          },
        },
        {
          label: this.$t('tableHead.items'),
          key: 'items',
          style: {
            width: '44px',
          },
        },
        {
          // label: this.$t('tableHead.menu'),
          key: 'delete',
          style: {
            width: '14px',
            textAlign: 'center',
          },
        },
      ],
      isRequest: true,
      isEmpty: false,
      diskId: '',
    };
  },
  computed: {
    // project() {
    //   return this.$store.getters['moduleStack/project_id'];
    // },
    storages() {
      return this.$store.getters['moduleStack/getStorages'];
      // return this.$store.state.moduleStack.storages;
    },
    s3KeyState() {
      return this.$store.state.moduleStack.noS3Key;
    },
    s3keys() {
      return this.$store.state.moduleStack.s3Keys;
    },
    headers() {
      return this.$store.state.moduleStack.storagesHeaders;
    },
    // containersCount() {
    //   return this.storages && this.storages.length ? this.storages.length : null;
    // return this.headers['x-account-container-count'];
    // },
  },
  watch: {
    s3KeyState: {
      handler: function (event) {
        if (event) {
          if (this.$store.state.moduleStack.s3Keys.length) {
            this.$store.dispatch(
              'moduleStack/setS3KeyDefault',
              this.$store.state.moduleStack.s3Keys[0]
            );
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    this.$store
      .dispatch('moduleStack/getS3StoragesKeys', this.id)
      .then(async data => {
        console.log(data);
        if (data === 'nokey') this.isRequest = false;
        // this.fetchStorages(this.s3keys[0]); //.then(data => {
        if (!this.s3KeyState) await this.fetchStorages();
        //.then(data => {
      })
      .then(async () => {
        console.log('wewewewewewe');
        await this.getS3BucketList();
      });
  },
  beforeDestroy() {
    // console.log('beforeDestroy');
    // this.$store.dispatch('moduleStack/resetStorages');
  },
  methods: {
    getS3BucketList() {
      return this.$store.dispatch('moduleStack/getS3BucketList', {
        id: this.id,
      });
    },
    // async createBucket() {
    //   const params = {
    //     name: 'test-bucket',
    //     type: 'private',
    //     key: this.s3keys[0],
    //   };
    //   await this.$store.dispatch('moduleStack/createS3Bucket', params);
    // },
    async addKey() {
      this.isRequest = true;
      this.isEmpty = true;
      // this.isKeyAdded = true;
      await this.$store
        .dispatch('moduleStack/createS3StoragesKey', this.id)
        .then(async data => {
          if (data && data.result === 'ok') {
            // console.log(data);
            await this.showSuccess(
              this.$t('created', { access: data.ec2.access, secret: data.ec2.secret })
            );

            // await this.fetchStorages(data.ec2);
          }
        })
        .then(async data => {
          console.log(data);

          await this.$store.dispatch('moduleStack/getS3StoragesKeys', this.id);
          await this.fetchStorages();
          // .then(() => (this.isRequest = false));
          // this.isEmpty = false;
        })
        .catch(e => this.showError(e));
    },
    getOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/getOpenStackApiKey', this.id);
    },
    async fetchStorages() {
      // await this.$store.dispatch('moduleStack/fetchProjectStorages', payload);
      // setTimeout(
      //   () => this.$store.dispatch('moduleStack/fetchProjectStorages', { delimiter: '/' }),
      await this.$store
        .dispatch('moduleStack/fetchProjectStorages', { delimiter: '/', id: this.id })
        .then(data => console.log(data));
      // 1000
      // );

      this.isRequest = false;
    },
    showSuccess(msg) {
      this.$modals.open({
        name: 'Success',
        html: true,
        // title: this.$t('success'),
        text: msg,
        footer: {
          centered: true,
          confirm: {
            props: { title: this.$t('close') },
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "storage": "Контейнеры"
    },
    "created": "Создан новый ключ S3: <br/><br/> <b>access</b>: %{access}<br/> <b>secret</b>: %{secret} ",
    "empty": {
      "title": "Нет действующих ключей.",
      "text": "Для доступа к хранилищу необходимо создать ключ S3.",
      "add": "Создать ключ"
    },
    "s3keys": "Ключи S3",
    "new": "Добавить контейнер",
    "tableHead": {
      "name": "Имя",
      "type": "Тип",
      "items": "Объекты",
      "volume": "Объем"
    },
    "sure": {
      "confirm": "Создать"
    },
    "newStorage": "Добавить контейнер",
    "quotaNetwork": "Вы достигли квоты по количеству приватных сетей, обратитесь в поддержку для изменения квоты",
    "activeUnder": "Действует до {date}",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.mr {
  margin-right: 1.5rem

}
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-header {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        margin-top: 1.75rem;
        margin-bottom: 1.5rem;
        flex: 1 1 auto;
      }
    }
  }

    &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        margin-bottom: 1.5 rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }
    &__create {
        display: flex;
        flex-direction row;
        align-content flex-end;
        align-items baseline;
        margin-top: 1.5rem;
        max-width: 320px;
        &-btn {
          margin-top: 20px
          margin-right: 0;
          +breakpoint(ms-and-up) {
            margin-top: 40px;
          }
        }
        &-hint {
          margin: -0.25rem 0.25rem;
          vertical-align: middle;
        }

        +breakpoint(sm-and-up) {
          margin: 0 0 0 auto;
        }
      }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
