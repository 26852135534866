<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #name>
      <div class="tariffs-table-item medium-text">
        <plain-button
          v-if="isPortDown"
          v-tooltip="{
            content: portDown,
            placement: 'right-start',
            container: false,
            autoHide: false,
            trigger: 'click hover',
          }"
          icon="error"
          size="medium"
          color="del"
          tabindex="-1"
          class="tariffs-table-item-btn"
        />
        <div class="hover">
          {{
            item.name
              ? item.name.includes('octavia-lb-vrrp-')
                ? 'Балансировщик'
                : item.name
              : networkName
          }}
        </div>
      </div>
      <div class="tariffs-table-item hover note-text">
        {{ item.description }}
      </div>
    </template>
    <template #address>
      <div v-for="i in item.fixed_ips" :key="i.ip_address" class="tariffs-table-item__sub">
        {{ i.ip_address }}
      </div>
    </template>
    <template #macaddress>
      <div class="tariffs-table-item__sub">
        {{ item.mac_address }}
      </div>
    </template>
    <template #dhcp>
      <div class="tariffs-table-item__tumbler">
        <tumbler
          :value="item.admin_state_up"
          :disabled="item.name.includes('octavia-lb-vrrp-')"
          class="theme-switcher__tumbler"
          @change="setState"
        />
      </div>
    </template>
    <template #firewall>
      <div class="tariffs-table-item__ip">
        <tumbler
          :value="item.port_security_enabled"
          :disabled="item.name.includes('octavia-lb-vrrp-')"
          class="theme-switcher__tumbler"
          @change="setFirewall"
        />
      </div>
    </template>
    <template #floatingip>
      <div v-if="!shared && !isShared">
        <plain-button
          v-if="viewFloatingIp(item) === 'link' && floatips.length === 0 && type === 'port'"
          color="primary"
          class="tariff-card__prolong"
          @click="attachFloatingIp({ port: item.id, float: 'server' })"
        >
          {{ $t('attachTo') }}
        </plain-button>
        <plain-button
          v-else-if="viewFloatingIp(item) === 'link' && floatips.length === 0 && type === 'server'"
          color="primary"
          class="tariff-card__prolong"
          @click="attachFloatingIp({ port: item.id, float: 'server' })"
        >
          {{ $t('attachTo') }}
        </plain-button>
        <router-link
          v-else-if="viewFloatingIp(item) === 'link' && floatips && type === 'port'"
          :to="{ name: 'viewStackFloatingIp' }"
          class="tariffs-table-item__link medium-text"
        >
          {{ floatips[0].floating_ip_address }}
        </router-link>
        <router-link
          v-else-if="type === 'server'"
          :to="{ name: 'viewStackFloatingIp' }"
          class="tariffs-table-item__link medium-text"
        >
          {{ viewFloatingIp(item) }}
        </router-link>
      </div>
    </template>
    <template #attach>
      <div v-if="typeLink === 'text'" class="tariffs-table-item">
        <div class="hover">
          {{ attach(item) }}
        </div>
      </div>
      <router-link
        v-if="route && route === 'Router'"
        :to="{ name: route, params: { routerid: item.device_id } }"
        class="tariffs-table-item__link medium-text"
      >
        <div class="hover">
          {{ attach(item) }}
        </div>
      </router-link>
      <router-link
        v-if="route && route === 'Balancer'"
        :to="{ name: route, params: { balancerid: item.device_id.replace('lb-', '') } }"
        class="tariffs-table-item__link medium-text"
      >
        <div class="hover">
          {{ attach(item) }}
        </div>
      </router-link>
      <router-link
        v-if="route && route === 'Server'"
        :to="{ name: route, params: { serverid: item.device_id } }"
        class="tariffs-table-item__link medium-text"
      >
        <div class="hover">
          {{ attach(item) }}
        </div>
      </router-link>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button icon="more" class="tariffs-table-item__more" @click="togglePopoverState" />
        <template #popover>
          <private-context-menu
            :tariff="item"
            :type="type"
            :float="isFloatingIp"
            :ip="floatIpAddress"
            class="tariff-table-item__context-menu"
          />
        </template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import PrivateContextMenu from './PrivateContextMenu';
import popoverFix from '@/mixins/popoverFix';
import Tumbler from '@/components/BaseTumbler/Tumbler';
import attachFloatingIp from '@/mixins/attachFloatingIp';
export default {
  name: 'TariffsTablePortRow',
  components: {
    BaseTableRow,
    Tumbler,
    PrivateContextMenu,
  },
  mixins: [popoverFix, attachFloatingIp],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'port',
    },
    shared: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeLink: '',
      route: '',
      // isShared: false,
    };
  },
  computed: {
    subnets() {
      return this.$store.state.moduleStack.subnets;
    },
    portDown() {
      return 'Ошибка включения порта. Обратитесь в техническую поддержку.';
    },
    isPortDown() {
      if (!this.item.device_id) return false;
      if (this.item.device_owner !== 'compute:nova') return false;
      if (this.item.name.startsWith('octavia-lb-vrrp-')) return false;
      const serverState = this.$store.state.moduleStack.servers.find(
        x => x.id === this.item.device_id
      );
      if (!serverState) return false;

      return (
        serverState.status.code === 1 &&
        this.item.status.code === 16 &&
        this.item.admin_state_up &&
        this.item.device_id
      );
    },
    id() {
      return this.item ? this.item.id : null;
    },
    ip() {
      return this.item.fixed_ips[0] ? this.item.fixed_ips[0].ip_address : null;
    },
    floatingIpsPort() {
      return this.$store.state.moduleStack.floatingips.filter(x => x.port_id === null);
    },
    floatingIpsServer() {
      return this.$store.state.moduleStack.floatingips.filter(x => x.port_id === this.item.id);
    },
    floatips() {
      return this.$store.state.moduleStack.floatingips.filter(x => x.fixed_ip_address === this.ip);
    },
    isFloatingIp() {
      return !!(this.floatips.length > 0 && this.floatips[0].floating_ip_address);
    },
    floatIpAddress() {
      return this.isFloatingIp ? this.floatips[0].id : null;
    },
    networkName() {
      if (this.shared) {
        return this.subnets.find(x => x.id === this.item.fixed_ips[0].subnet_id).name;
      } else
        return this.$store.state.moduleStack.networks.find(x => x.id === this.item.network_id).name;
    },
    isShared() {
      return this.item.network_id ? this.isNetworkShared(this.item.network_id) : null;
    },
    isFirewall() {
      return this.tariff.port_security_enabled === true;
    },
  },
  watch: {
    networkName: function (event) {},
  },
  mounted() {
    this.link(this.item);
  },
  methods: {
    isNetworkShared(id) {
      return this.$store.state.moduleStack.networks.find(x => x.id === id).shared;
    },
    link(item) {
      switch (item.device_owner) {
        case 'network:router_interface':
          this.route = 'Router';
          break;
        case 'compute:nova':
          this.route = 'Server';
          break;
        case 'Octavia':
          this.route = 'Balancer';
          break;
        case 'network:dhcp':
          this.typeLink = 'text';
          break;
        case 'off':
        default:
          this.typeLink = 'text';
      }
    },
    viewFloatingIp(item) {
      if (this.type === 'port') {
        return this.floatingIpsPort.find(x => x.port_id === item.id)
          ? this.floatingIpsPort.find(x => x.port_id === item.id).floating_ip_address
          : !item.device_id && !item.device_owner
          ? 'link'
          : '';
      } else {
        return this.floatingIpsServer.length !== 0
          ? this.floatingIpsServer.find(x => x.port_id === item.id).floating_ip_address
          : this.shared
          ? ''
          : 'link';
      }
    },
    attach(item) {
      if (item.device_owner === 'network:dhcp') {
        return 'DHCP';
      } else if (item.device_owner === 'network:router_interface') {
        return this.$store.state.moduleStack.routers.find(x => x.id === item.device_id).name;
      } else if (
        item.device_owner === 'compute:nova' &&
        !item.name.startsWith('octavia-lb-vrrp-')
      ) {
        return this.$store.state.moduleStack.servers.find(x => x.id === item.device_id).name;
      } else if (item.device_owner === 'Octavia' && !!item.device_id) {
        return this.$store.state.moduleStack.balancers.find(
          x => x.id === item.device_id.replace('lb-', '')
        ).name;
      } else if (item.device_owner === 'Octavia' && !item.device_id) {
        return '';
      } else if (item.device_owner === 'compute:nova' && item.name.startsWith('octavia-lb-vrrp-')) {
        return '';
      } else return item.device_owner;
    },
    setState(value) {
      const payload = {
        port: {
          admin_state_up: value,
        },
        id: this.item.id,
        item: 'admin_state_up',
      };
      this.$store.dispatch('moduleStack/updatePort', payload);
    },
    setFirewall(value) {
      const payload = {
        port: {
          port_security_enabled: value,
        },
        id: this.item.id,
        item: 'port_security_enabled',
      };
      this.$store.dispatch('moduleStack/updatePort', payload).catch(e => this.showError(e));
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "attachTo": "Подключить",
    "attach": "Подключить плавающий IP",
    "mb": "{num} МБ",
    "gb": "{num} ГБ",
    "sure": {
      "confirm": "Подключить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.theme-switcher__tumbler{
  display: flex
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hover{
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  background: var(--main-card-bg);
  z-index: 2
  &:hover {
    background: var(--main-card-bg);
    text-overflow: unset;
    overflow: visible;
    display inline
    z-index: 3;
  }
  &:hover::after {
    +breakpoint(ml-and-up) {
      content: '';
      //display: block;
      display: inline;
      size(2.5em, 100%);
      absolute(0px, 100%);
      z-index: 2
      background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
    }
  }
}
.hover-title{
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  background: var(--main-card-bg);
  z-index: 4
  &:hover {
    background: var(--main-card-bg);
    text-overflow: unset;
    overflow: visible;
    display inline
    z-index: 5;
  }
  &:hover::after {
    +breakpoint(ml-and-up) {
      content: '';
      //display: block;
      display: inline;
      size(2.5em, 100%);
      absolute(0px, 100%);
      z-index: 4
      background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
    }
  }
}
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }
  }
  &__sub{
    //margin-top: 0.5rem;
  }
  &__specs {
    flex: 1 1 auto;
    margin-top: 0.09rem;
  }
  &__title {
    flexy(flex-start, center);
  }
  &-btn {
    margin-left: -1rem
  }
  &__link {
    position: relative;
    color: $primary-color;
    //font-weight: 600;
    text-decoration: none;
    white-space: nowrap;
    background: var(--main-card-bg);
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
</style>
