<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #server>
      <div class="tariff-table-item__server">
        <router-link
          v-if="![1, 4, 5].includes(item.status.code)"
          :to="{ name: 'vpsDetail', params: { id: item.id } }"
          class="tariffs-table-item__link medium-text"
        >
          {{ item.title }}
        </router-link>
        <div v-else class="tariffs-table-item medium-text">
          {{ item.title }}
        </div>
        <div class="tariffs-table-item__specs note-text">
          {{ specs }}
        </div>
      </div>
    </template>
    <template #id>
      <div class="tariffs-table-item__ip note-color">
        {{ item.id }}
      </div>
    </template>
    <template #ip>
      <div class="tariffs-table-item__ip note-color">
        {{ item.ip }}
      </div>
    </template>
    <template #pricelist>
      <div class="tariffs-table-item__pricelist">
        {{ item.pricelist }}
      </div>
    </template>
    <template #active>
      <div class="tariffs-table-item__ip-promise">
        <plain-button
          v-if="item.promise !== 'null'"
          v-tooltip="{
            content: promiseOnItem,
            autoHide: false,
            placement: 'left-start',
            container: false,
          }"
          icon="promise-icon"
          class="allowed-user-rights__promise"
        />
        <div class="tariffs-table-item__date note-color promise">
          <!--        {{ $t('activeUnder', { date: formattedEndDate }) }}-->
          {{ $d(item.expiredate, 'short') }}
        </div>
      </div>
      <plain-button
        v-if="hasProlongTool"
        color="primary"
        class="tariffs-table-item__ip-prolong"
        @click="costAutoProlong === '0.00' ? singleProlongFree(item) : singleProlong(item)"
      >
        {{ $t('prolong.action') }}
      </plain-button>
    </template>
    <template #cpu>
      <div class="tariffs-table-item__cpu note-color">
        {{ item.ncpu }}
      </div>
    </template>
    <template #mem>
      <div class="tariffs-table-item__mem note-color">
        {{ item.mem }}
      </div>
    </template>
    <template #disc>
      <div class="tariffs-table-item__disc note-color">
        {{ item.disc }}
      </div>
    </template>
    <template #autoprolong>
      <div class="tariffs-table-item__icon">
        <vps-autoprolong
          v-tooltip="{
            content: isAutoProlong,
            autoHide: false,
            placement: 'left-start',
          }"
          :tariff="item"
          class="tariffs-table-item__autoprolong"
        />
      </div>
      <div class="tariffs-table-item__icon">{{ autoprolongLabel }}</div>
    </template>
    <template #cost>
      <div class="tariffs-table-item__cost note-color">
        {{ $n(costAutoProlong, 'currency', $i18n.locale) }}
      </div>
    </template>
    <template #status>
      <main-label :color="item.status.color" theme="plain" class="tariffs-table-item__status">
        {{ $t(`status.${item.status.key}`) }}
      </main-label>
      <main-label
        v-if="item.status.code === 2 && !!serverState"
        :color="item.serverState.color"
        theme="plain"
        class="tariffs-table-item__status"
        style="display: block"
      >
        {{ $t(`status.${serverState.key}`) }}
      </main-label>
      <!--      <main-label-->
      <!--        v-if="item.status.code === 2 && !!serverState && serverState.code === 3"-->
      <!--        :color="'error'"-->
      <!--        theme="plain"-->
      <!--        class="tariffs-table-item__status"-->
      <!--      >-->
      <!--        {{ $t(`status.${serverState.key}`) }}-->
      <!--      </main-label>-->

      <!--      <plain-button icon="warn" color="del" class="" />-->
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        :placement="placement"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button
          icon="more"
          class="tariffs-table-item__more"
          @click="togglePopoverState"
        /><template #popover>
          <vps-context-menu
            v-if="isPopoverOpen"
            :tariff="item"
            class="tariff-table-item__context-menu"
        /></template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
import VpsContextMenu from '../../../components/VpsContextMenu';
import VpsAutoprolong from '../../../components/VpsAutoprolong';
import VpsProlongSingle from '../../../components/VpsProlongSingle.vue';
import prolongSingleFree from '@/mixins/billmgr/prolongSingleFree';
import prolongSingle from '@/mixins/billmgr/prolongSingle';
import popoverFix from '@/mixins/popoverFix';
import { format } from 'date-fns';
import prolongText from '@/mixins/prolongText';
export default {
  name: 'TariffsTableRow',
  components: { BaseTableRow, MainLabel, VpsContextMenu, VpsAutoprolong },
  mixins: [prolongSingle, prolongSingleFree, popoverFix, prolongText],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    length: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      costAutoProlong: 0,
      moduleMain: 'moduleVPS',
      singleProlongComponentFirst: VpsProlongSingle,
      singleProlongComponent: VpsProlongSingle,
    };
  },
  computed: {
    autoprolongLabel() {
      const autoProlong = this.item.autoprolong_name;
      return autoProlong.length > 0 ? `${autoProlong}` : 'нет';
    },
    isAutoProlong() {
      // return this.item.autoprolong_id === 'null'
      return this.item.autoprolong_period === 'null'
        ? 'Включить автопродление'
        : 'Автопродление включено';
    },
    serverId() {
      return this.item.serverId;
    },
    licType() {
      if (
        this.item &&
        this.item.status.code === 2 &&
        this.item.addons.length > 0 &&
        this.item.addons &&
        this.item.addons.filter(x => x.type).find(x => x.type !== 'none')
      ) {
        return this.item.addons.find(x => x.type && x.type !== 'none').type || 'none';
      } else return 'none';
    },
    isPleskPanel() {
      return this.item.isPlesk;
    },
    osName() {
      return this.item.osName;
    },
    cost() {
      return this.item.cost;
    },
    placement() {
      return this.length > 2 ? 'left' : 'left-end';
    },
    formattedEndDate() {
      return format(this.item.expiredate, 'dd.MM.yyyy');
    },
    specs() {
      return `${this.item.ncpu}/${this.$t('specs.mem')}: ${this.item.mem}/${this.$t(
        'specs.disc'
      )}: ${this.item.disc}`;
    },
    tools() {
      return this.$store.state.moduleVPS.tools;
    },
    serverState() {
      return this.item.serverState;
    },
    hasProlongTool() {
      return (
        this.item.prolongAvailable &&
        !!this.tools &&
        !!this.tools.prolong &&
        this.tools.prolong.isEnable(this.item)
      );
    },
  },
  watch: {
    licType: {
      handler: function (event) {
        if (event) this.item.isPlesk = true;
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.hasProlongTool) this.getCostTable();
  },
  methods: {
    getLocalizedSize(val, units) {
      const GB = 1024;
      let num = val;
      let unitKey = units === 'МиБ' ? 'mb' : units;
      if (unitKey === 'mb' && val >= GB) {
        unitKey = 'gb';
        num = num / GB;
      }
      return this.$t(unitKey, { num });
    },
    concatString(obj) {
      let str = obj.value;
      if (obj.unit) str += ` ${obj.unit}`;
      return str;
    },
    printParam(param, payload) {
      return param.toString(payload);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "status": {
        "stopped": "Выключен",
        "suspended": "Неактивен",
        "running": "Включён"
      },
      "activeUnder": "До {date} г.",
      "mb": "{num} МБ",
      "gb": "{num} ГБ",
      "specs": {
        "cpu": "CPU",
        "mem": "RAM",
        "disc": "Disc"
      },
      "promise" : "Для услуги активирован обещанный платеж до {promise}",
      "prolong": {
        "title": "Продление тарифа",
        "error": "К сожалению, что-то пошло не так",
        "success_basket": "Продление тарифа успешно добавлено в корзину",
        "success_order": "Продление тарифа прошло успешно. Данные скоро обновятся.",
        "confirm": "Оплатить",
        "free": "Активировать",
        "cancel": "Отмена",
        "order": "В корзину",
        "action": "Продлить"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.allowed-user-rights{
  &__promise{
    margin-right: 1px;
  }
}
.promise{
  display: inline-block;
  vertical-align: middle !important;
  margin-right: 0.5rem;
}
.tariffs-table-item {
  &__ip{
    &-promise{
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }
    &-prolong{
      display: block;
    }
  }
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle !important;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__icon{
    display: inline-block;
    vertical-align: middle !important;
    margin-right: 0.25rem;
  }
    &__autoprolong {
      text-align: center;
    }
  &__link {
    text-decoration: none;
    color: $primary-color;

    &:hover {
      color: darken($primary-color, 20%);
    }
  }
  &__server {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    white-space: nowrap;
    background: inherit;

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
</style>
