<template>
  <div class="allowed-user-settings-form">
    <transition name="fade" mode="out-in" @after-enter="setFocus()">
      <base-loader v-if="isLoading" />
      <div v-else class="allowed-user-settings-form__content">
        <avatar-upload
          :value="avatar"
          :size="avatarSettings.maxsize || 0"
          :width="avatarSettings.width"
          :height="avatarSettings.height"
          class="allowed-user-settings-form__avatar-upload"
          @change="onAvatarChange"
        />
        <div class="allowed-user-settings-form__form" @keyup.enter="submit">
          <div class="l-flex-1_sm-2-gutter-20">
            <div class="l-col">
              <base-input
                v-for="(item, key) in personal"
                :key="key"
                :type="item.type"
                :show-warning="item.type === 'email'"
                :value="formData[key]"
                :name="item.name"
                :readonly="item.readonly || false"
                :disabled="item.disabled || false"
                :required="item.required || false"
                :placeholder="item.placeholder || ''"
                :mask="item.mask || null"
                :maxlength="item.maxlength"
                :custom-error-messages="
                  customValidationMsgs[item.name] ? customValidationMsgs[item.name] : null
                "
                :invalid="!!errors[item.name] || false"
                use-reactive-validation
                class="allowed-user-settings-form__field allowed-user-settings-form__field--input"
                @change="onChange(key, $event.target.value)"
              >
                {{ $t(`form.${key}.label`) }}
              </base-input>
            </div>
            <div class="l-col">
              <base-input
                v-for="(item, key) in security"
                :key="key"
                :ref="item.name"
                :type="item.type"
                :value="formData[key]"
                :name="item.name"
                :required="item.required || false"
                :placeholder="item.placeholder || ''"
                :pattern="item.pattern || false"
                :custom-error-messages="
                  customValidationMsgs[item.name] ? customValidationMsgs[item.name] : null
                "
                :invalid="!!errors[item.name] && errors[item.name].value === formData[key]"
                use-reactive-validation
                class="allowed-user-settings-form__field allowed-user-settings-form__field--input"
                @current-error="checkError(key, $event)"
                @validate-input="onChange(key, $event.target.value)"
              >
                {{ $t(`form.${key}.label`) }}
                <template v-if="item.name === 'password'" #icon>
                  <!--                    :class="generateBtnStyle ? 'generateBtn' : ''"-->
                  <plain-button icon="key" color="dark" tabindex="1" @click="generatePass(item)" />
                </template>
                <template #fieldAfter>
                  <plain-button
                    :icon="item.type === 'text' ? 'eye-closed' : 'eye-open'"
                    color="dark"
                    tabindex="-1"
                    @click="showPassword(item.name)"
                  />
                </template>
                <template v-if="item.name === 'password' && secureLevel !== 'none'" #labelEnd>
                  <span :class="`${secureColors[secureLevel]}-color`">
                    {{ $t(`security.${secureLevel}`) }}
                  </span>
                </template>
              </base-input>
              <base-checkbox
                :value="access.value"
                :true-value="access.trueValue"
                :false-value="access.falseValue"
                class="allowed-user-settings-form__field allowed-user-settings-form__field--checkbox"
                @change="onAccessChange"
              >
                {{ $t('form.access.label') }}
              </base-checkbox>
            </div>
            <!--            <div class="l-col">-->
            <!--              <base-select-->
            <!--                name="role"-->
            <!--                :options="roles"-->
            <!--                :value="role"-->
            <!--                label="v"-->
            <!--                track-by="k"-->
            <!--                :show-labels="false"-->
            <!--                :placeholder="$t('form.role.placeholder')"-->
            <!--                @select="onSetRole('role', $event.k)"-->
            <!--              >-->
            <!--                {{ $t('form.role.label') }}-->
            <!--              </base-select>-->
            <!--            </div>-->
          </div>
          <div v-if="showSubmit" class="allowed-user-settings-form__footer">
            <base-button
              class="allowed-user-settings-form__btn"
              :loading="formLoading"
              :disabled="!isValid || !hasChanges"
              @click="submit"
            >
              {{ $t('form.btn.save') }}
            </base-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AvatarUpload from '@/components/Avatar/AvatarUpload.vue';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox.vue';
import { AllowedUser } from '@/models/BillMgr/UserProfile';
import { parseBoolFromString } from '@/utils/parsers/stringParser';
import { isEqual } from 'lodash';
import setFocus from '@/mixins/setFocus';
// import BaseSelect from '@/components/Select/BaseSelect';
import mixin from '@/layouts/Profile/mixins';
// import { currentHost, IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
// import generatePassword from '@/mixins/generatePassword';
// import showSuccess from '@/layouts/LoginPage/mixins/showSuccess';
import passfather from 'passfather';
import Vue from 'vue';
export default {
  name: 'AllowedUserSettingsForm',
  components: {
    AvatarUpload,
    BaseInput,
    BaseCheckbox,
    // BaseSelect,
  },
  mixins: [setFocus, mixin],
  props: {
    user: {
      type: AllowedUser,
      default: null,
    },
    formLoading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    showSubmit: {
      type: Boolean,
      default: false,
    },
    doSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ifGenerate: false,
      secureColors: {
        low: 'error',
        short: 'error',
        normal: 'warning',
        high: 'success',
      },
      secureLevels: {
        ['1']: 'low',
        ['2']: 'normal',
        ['3']: 'high',
      },
      isPasswordFieldError: true,
      minPassLength: 8,
      avatarValue: null,
      avatarBackup: null,
      isLoading: !!this.user,
      fieldTypes: {
        realname: 'text',
        phone: 'tel',
        email: 'email',
        password: 'password',
        confirm: 'password',
        // role: 'select',
      },
      formData: {
        realname: '',
        phone: '',
        email: '',
        password: '',
        confirm: '',
        // role: '',
      },
      initialDataBackup: null,
      access: {
        name: 'default_access_allow',
        value: 'off',
        backup: '',
        trueValue: 'on',
        falseValue: 'off',
      },
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('form.confirm.patternMismatch') },
      },
    };
  },
  computed: {
    mainProfile() {
      return this.$store.state.moduleProfile.profile;
    },
    generateBtnStyle() {
      return this.formData.password.length === 0 && this.isPasswordFieldError === true;
    },
    // currentHost() {
    //   return IS_PROD ? localStorage.getItem('host') : this.$store.state.moduleApp.host;
    // },
    roles() {
      if (!this.user || !this.user.roles) return [];
      return this.user.roles;
    },
    // role() {
    //   if (!this.profile || !this.roles.length) return '';
    //   return this.roles.find(i => i.k === this.user.role);
    // },
    avatarSettings() {
      const def = { maxsize: 800 * 1024, width: 80, height: 80, name: 'avatar_file_upload' };
      return this.mainProfile.avatarSettings || def;
    },
    personal() {
      if (!this.mainProfile || !this.mainProfile.fields) return {};
      const fields = this.mainProfile.fields;
      return {
        realname: this.getField(fields.realname),
        phone: this.getField(fields.phone),
        email: this.getField(fields.email),
        // role: this.getField(fields.role),
      };
    },
    security() {
      return {
        password: {
          name: 'password',
          type: this.fieldTypes.password,
          value: this.formData.password,
          required: this.isNewUser,
        },
        confirm: {
          name: 'confirm',
          type: this.fieldTypes.confirm,
          value: this.formData.confirm,
          required: this.isNewUser || !!this.formData.password,
          pattern: this.formData.password,
        },
      };
    },
    avatar() {
      return this.isNewUser ? '' : this.user.avatar;
    },
    isNewUser() {
      return !this.user;
    },
    isValid() {
      const fields = [...Object.values(this.personal), ...Object.values(this.security)];
      return (
        fields.filter(i => i.required).every(i => !!this.formData[i.name]) &&
        this.formData.password === this.formData.confirm &&
        this.secureLevel === 'high' &&
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.formData.email)
      );
    },
    hasChanges() {
      return (
        !isEqual(this.formData, this.initialDataBackup) ||
        this.avatarBackup !== this.avatarValue ||
        this.access.backup !== this.access.value
      );
    },
    isReady() {
      return this.hasChanges && this.isValid;
    },
    secureLevel() {
      const password = this.security['password'].value;
      let level = 0;
      if (!password) return 'none';
      if (password.length < this.minPassLength) {
        return 'short';
      }
      if (password.match(/[A-Z]/g)) {
        level++;
      }
      if (password.match(/[0-9]/g)) {
        level++;
      }
      if (password.match(/[a-z]/g)) {
        level++;
      }
      return this.secureLevels[level];
    },
  },
  watch: {
    isReady(val) {
      this.$emit('ready', val);
    },
    hasChanges(val) {
      this.$emit('change', val);
    },
    doSubmit(val) {
      if (val) this.submit();
    },
  },
  mounted() {
    if (this.user) {
      if (!this.user.avatar) {
        this.isLoading = true;
        this.fetchUserInfo(this.user.id).then(data => {
          this.user.update(data.model);
          // this.user.setRole(data.slist.role);
          this.setInitialValues();
          this.setBackup();
          this.isLoading = false;
        });
      } else {
        this.setInitialValues();
        this.setBackup();
        this.isLoading = false;
      }
    } else {
      this.setBackup();
      this.isLoading = false;
    }
  },
  methods: {
    showPassword(item) {
      if (item === 'password' && this.fieldTypes.password === 'password') {
        this.fieldTypes.password = 'text';
      } else if (item === 'password' && this.fieldTypes.password === 'text') {
        this.fieldTypes.password = 'password';
      } else if (item === 'confirm' && this.fieldTypes.confirm === 'password') {
        this.fieldTypes.confirm = 'text';
      } else if (item === 'confirm' && this.fieldTypes.confirm === 'text') {
        this.fieldTypes.confirm = 'password';
      }
    },
    checkError(key, event) {
      // console.log(key, event);
      this.isPasswordFieldError = event.check;
    },
    generatePass(field) {
      this.ifGenerate = true;
      this.isPasswordFieldError = true;
      const pass = passfather({
        numbers: false,
        uppercase: false,
        lowercase: false,
        symbols: false, // Disable symbols
        ranges: [
          [
            [65, 72],
            [74, 78],
            [80, 90],
            [97, 107],
            [109, 122],
          ],
          [[50, 57]],
        ],
        length: 16,
      });
      Vue.set(field, 'value', pass);
      Vue.set(field, 'type', 'text');
      const input = this.$refs.password[0].$el.querySelector('input[name=password]');
      this.formData.password = this.security.password.value;
      this.formData.confirm = this.security.password.value;
      const confirm = this.$refs.confirm[0].$el.querySelector('input[name=confirm]');
      this.onChange(this.formData.password, this.security.password.value);
      this.onChange(this.formData.confirm, this.security.password.value);
      if (input) {
        input.value = pass;
        confirm.value = pass;
        // input.focus();
      }
    },
    // onSetRole(key, value) {
    //   this.formData[key] = value;
    //   const params = {};
    //   params.id = this.user.elid;
    //   // params.role = this.formData.role;
    //   params.referer = `${this.user.host}/billmgr?elid=${this.user.elid}&elname=${this.user.name}&startform=user.edit`;
    //
    //   this.setAllowedUserRole(params)
    //     .then(() => (this.initialDataBackup = { ...this.formData }))
    //     .catch(e => this.showError(e))
    //     .finally(() =>
    //       setTimeout(() => {
    //         // this.formLoading = false;
    //       }, 500)
    //     );
    // },
    setInitialValues() {
      const list = Object.values(this.personal);
      for (let i in list) {
        const item = list[i];
        if (this.user[item.name]) this.formData[item.name] = this.user[item.name];
      }
      // if ()
      if (this.user.access) this.access.value = this.user.access;
    },
    setBackup() {
      this.initialDataBackup = { ...this.formData };
      this.avatarBackup = this.avatarValue;
      this.access.backup = this.access.value;
    },
    getBoolValue(str) {
      return str ? parseBoolFromString(str) : false;
    },
    getField(field) {
      return field
        ? {
            ...field,
            type: this.fieldTypes[field.name],
            required: this.getBoolValue(field.required),
            readonly: this.isNewUser ? false : this.getBoolValue(field.readonly),
            disabled: this.isNewUser ? false : this.getBoolValue(field.disabled),
          }
        : {};
    },
    onAvatarChange(value) {
      this.avatarValue = value || null;
    },
    onAccessChange(val) {
      this.access.value = val;
    },
    onChange(key, val) {
      // console.log(key, val);
      this.formData[key] = val;
    },
    fetchUserInfo(id) {
      return this.$store.dispatch('moduleProfile/fetchUser', { id });
    },
    submit() {
      if (this.isValid && this.hasChanges) {
        const data = this.getFormData();
        if (data.phone === '+') data.phone = '';
        // if (data.password) {
        //   data.passwd = data.password;
        //   delete data.password;
        // }
        // console.log(data);
        this.$emit('submit', data);
      }
    },
    // onFocus(field) {
    //   if (field.name === 'password') field.type = 'text';
    // },
    // onBlur(field) {
    //   console.log(field);
    //   if (field.name === 'password') field.type = 'password';
    // },
    getFormData() {
      const { password, ...rest } = this.formData;
      let renamedObject = { passwd: password, ...rest };

      const data = {
        ...renamedObject,
        [this.access.name]: this.access.value,
      };
      if (this.avatarValue) data[this.avatarSettings.name] = this.avatarValue;
      if (this.isNewUser) data.newuser = '';
      return data;
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "title": "Настройки пользователя",
      "form": {
        "realname": {
          "label": "Имя"
        },
        "phone": {
          "label": "Телефон (только мобильные номера РФ)"
        },
        "role": {
          "label": "Роль",
          "placeholder": "Выберите роль пользователя"
        },
        "email": {
          "label": "Email"
        },
        "password": {
          "label": "Пароль"
        },
        "confirm": {
          "label": "Подтверждение пароля",
          "patternMismatch": "Пароли не совпадают"
        },
        "access": {
          "label": "Полный доступ"
        },
        "btn": {
          "save": "Подтвердить"
        }
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.generateBtn {
  position: absolute;
  width: 2.5rem;
  height: 1.5rem;
  bottom: 0;
  right: 16px;
}
.allowed-user-settings-form {
  &__avatar-upload {
    margin: 0 auto 1.5rem;
  }
  &__field {
    & + & {
      margin-top: 1.25rem;
    }

    & + &--checkbox {
      +breakpoint(sm-and-up) {
        margin-top: 4rem;
      }
    }
  }
  &__footer {
    +breakpoint(sm-and-up) {
      margin: 1.5rem auto 0;
      flexy(flex-end, center);
    }
  }
  &__btn {
    width: 100%;
    +breakpoint(sm-and-up) {
      width: auto;
    }
  }
}
</style>
