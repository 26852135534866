<template>
  <div class="port">
    <div class="l-col">
      <!--        :field-label="$t('label.type')"-->
      <label class="typo__label standart-text">{{ $t('label.type') }}</label>
      <base-select
        v-model="formData.port.fixed_ips[0].subnet_id"
        :hide-selected="true"
        :searchable="false"
        select-label=""
        :custom-label="subnetName"
        track-by="id"
        placeholder="Выберите подсеть"
        :preselect-first="true"
        :options="options"
        size="medium"
        :allow-empty="false"
        class="up"
        @input="onChange"
      >
      </base-select>
    </div>
    <div v-if="formData.port.fixed_ips[0].subnet_id && value.length > 0" class="l-col">
      <base-input
        v-model="buferIp"
        :label="$t('port.label')"
        class="form__field--input"
        :custom-error-messages="customValidationMsgs ? customValidationMsgs : null"
        use-reactive-validation
        :pattern="value"
        @input="onChange"
      >
      </base-input>
    </div>
    <div v-if="formData.port.fixed_ips[0].subnet_id && value.length === 0" class="l-col">
      <label class="typo__label standart-text">{{ $t('label.error') }}</label>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
export default {
  name: 'NewPort',
  components: { BaseInput, BaseSelect },
  mixins: [setFocus],
  props: {
    instance: {
      type: Object,
      default: () => {},
    },
    net: {
      type: String,
      default: '',
    },
    shared: {
      type: Boolean,
      default: false,
    },
    device: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('error') },
      },
      id: '',
      reserveIp: [],
      buferIp: '',
      useIp: [],
      ipUsed: [],
      all: [],
      mask: '',
      listIp: [],
      pullIp: [],
      subnetCurrent: {},
      newIp: [],
      value: '',
      formData: {
        port: {
          network_id: '',
          fixed_ips: [
            {
              subnet_id: '',
              ip_address: '',
              device_id: '',
            },
          ],
        },
      },
    };
  },
  computed: {
    subnet() {
      if (this.shared) {
        return this.instance.id;
      } else {
        return this.instance.subnets;
      }
    },
    options() {
      if (this.shared) {
        const arr = [];
        const subnets = this.instance.id;
        arr.push(this.getAvailablePorts(subnets));
        return arr;
      } else {
        const arr = [];
        const subnets = this.instance.subnets;
        subnets.forEach(net => {
          arr.push(this.getAvailablePorts(net));
        });
        return arr;
      }
    },
    availablePorts() {
      return this.newIp.length;
    },
    isCorrectCurrentIp() {
      return this.newIpAddress.includes(this.buferIp);
    },
    ports() {
      if (this.shared) {
        return this.$store.state.moduleStack.ports
          .map(x => x.fixed_ips)
          .flat()
          .filter(x => x.subnet_id === this.instance.id)
          .map(x => x.ip_address);
      } else {
        return this.$store.state.moduleStack.ports
          .filter(x => x.network_id === this.net)
          .map(x => x.fixed_ips)
          .flat()
          .filter(x => x.subnet_id === this.id)
          .map(x => x.ip_address);
      }
    },
    allocation_pools() {
      return this.id
        ? this.$store.state.moduleStack.subnets.find(x => x.id === this.id).allocation_pools
        : null;
    },
    newIpAddress() {
      return this.newIp.length ? this.newIp.map(x => `${this.mask}${x}`) : [];
    },
  },
  watch: {
    listIp: function (event) {
      this.mask = '';
      this.value = '';
      this.newIp = [];
      if (event.length) {
        this.all = [];
        this.allocation_pools.forEach(x => {
          this.all.push(
            this.getAllPorts(this.getLastIpSection(x.start), this.getLastIpSection(x.end))
          );
        });
        this.all = this.all.flat();

        this.useIp = this.ports.map(x => this.getLastIpSection(x));
        this.newIp = this.all.filter(x => !this.useIp.includes(x)).flat();
        this.reserveIp = this.getLastIpSection(this.allocation_pools[0].end) + 1;
        const ip = this.newIp.length > 0 ? this.newIp[0] : null;
        this.mask = this.getMask(this.allocation_pools[0].end);
        if (ip && this.newIp[0]) this.value = this.mask + this.newIp[0];
        this.buferIp = this.value.length > 0 ? this.value : this.$emit('notready');
      } else if (!event.length && this.instance.name === 'Anti-DDOS') {
        this.pullIp = [];
        let bufer = [];
        this.allocation_pools.forEach(x => {
          bufer.push([this.getLastIpSection(x.start), this.getLastIpSection(x.end)]);
        });
        bufer = bufer.flat();
        this.pullIp = Array.from(new Set(bufer));
        this.newIp = this.pullIp.filter(x => !this.useIp.includes(x));
        const ip = this.allocation_pools[0].start;
        this.mask = this.getMask(ip);
        if (ip && this.newIp[0]) this.value = this.mask + this.newIp[0];
        this.buferIp = this.value.length > 0 ? this.value : this.$emit('notready');
      } else {
        this.pullIp = [];
        this.allocation_pools.forEach(x => {
          this.pullIp.push([this.getLastIpSection(x.start), this.getLastIpSection(x.end)]);
        });
        this.all = [];
        this.allocation_pools.forEach(x => {
          this.all.push(
            this.getAllPorts(this.getLastIpSection(x.start), this.getLastIpSection(x.end))
          );
        });
        this.all = this.all.flat();
        this.newIp = Array.from(new Set(this.all.flat()));
        this.pullIp = this.pullIp.flat();
        this.pullIp.forEach((x, i) => {
          if (this.pullIp[i + 1] - this.pullIp[i] > 1 && i - 1 !== this.pullIp.length) {
            this.newIp.push(this.pullIp[i]);
          }
        });
        const ip = this.allocation_pools[0].start;
        this.mask = this.getMask(ip);
        if (ip && this.newIp[0]) this.value = this.mask + this.newIp[0];
        this.buferIp = this.value.length > 0 ? this.value : this.$emit('notready');
        return; //console.log('список пуст!');
      }
    },
    value: function (event) {
      const ip = this.getLastIpSection(event);
      if (this.newIp.includes(ip)) {
        this.formData.port.fixed_ips[0].ip_address = event;
        return this.$emit('change', {
          port: {
            network_id: this.shared ? this.instance.network_id : this.formData.port.network_id,
            fixed_ips: [
              {
                subnet_id: this.formData.port.fixed_ips[0].subnet_id.id,
                ip_address: this.formData.port.fixed_ips[0].ip_address,
                device_id: this.formData.port.fixed_ips[0].device_id,
              },
            ],
          },
        });
      } else {
        return this.$emit('notready');
      }
    },
    isCorrectCurrentIp(event) {
      if (!event) this.$emit('notready');
    },
  },
  //TODO: работает - но надо подчистить на досуге:
  mounted() {
    this.isReadyNow();
    this.formData.port.network_id = this.shared ? this.instance.network_id : this.net;
    this.formData.port.fixed_ips[0].device_id = this.device;
    this.allocation_pools;
  },

  beforeDestroy() {
    this.id = '';
  },
  methods: {
    getMask(ip) {
      return ip.slice(0, ip.lastIndexOf('.') + 1);
    },
    isCorrectIp(ip) {
      if (this.newIpAddress.includes(ip)) {
        this.formData.port.fixed_ips[0].ip_address = ip;
        this.value = ip;
        this.$emit('ready', {
          port: this.formData.port,
        });
      } else return this.$emit('notready');
    },
    getLastIpSection(ip) {
      return +ip.split('.').at(-1);
    },
    getAllPorts(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
    subnetName({ name, ports }) {
      return `${name}     (доступных портов к выбору: ${ports})`;
    },
    isReadyNow() {
      if (
        this.formData.port.fixed_ips[0].subnet_id &&
        this.formData.port.fixed_ips[0].ip_address &&
        this.formData.port.network_id
      ) {
        return this.$emit('ready', {
          port: this.formData.port,
        });
      } else {
        return this.$emit('notready');
      }
    },
    isIPv4(addr) {
      return /^(([01]?\d{1,2}|2[0-4]\d|25[0-5])(\.|$)){3}$/.test(addr);
    },
    getAvailablePorts(subnet) {
      const range = this.$store.state.moduleStack.subnets.find(
        x => x.id === subnet
      ).allocation_pools;
      let all = [];
      range.forEach(x => {
        all.push(this.getAllPorts(this.getLastIpSection(x.start), this.getLastIpSection(x.end)));
      });
      all = all.flat();
      const ports = this.$store.state.moduleStack.ports
        .map(x => x.fixed_ips)
        .flat()
        .filter(x => x.subnet_id === subnet)
        .map(x => x.ip_address)
        .map(x => this.getLastIpSection(x));
      const availablePorts = all.filter(x => !ports.includes(x)).length;
      return {
        id: this.$store.state.moduleStack.subnets.find(x => x.id === subnet).id,
        name: this.$store.state.moduleStack.subnets.find(x => x.id === subnet).cidr,
        ports: availablePorts,
      };
    },
    onChange(event) {
      if (this.subnet.includes(event.id)) {
        this.id = event.id;
        const port = this.$store.state.moduleStack.ports;
        const updatePort = port.map(x => x.fixed_ips.filter(a => a.subnet_id === event.id));
        const formatPort = updatePort.reduce((acc, item) => {
          if (item !== []) acc.push(item);
          return acc;
        }, []);
        this.listIp = formatPort
          .filter(x => x[0])
          .flat()
          .map(x => x.ip_address)
          .sort((a, b) => a.split('.').at(-1) - b.split('.').at(-1));
      } else {
        Object.assign(this.formData, event);
        this.isCorrectIp(event);
        if (
          this.formData.port.fixed_ips[0].subnet_id &&
          this.formData.port.fixed_ips[0].ip_address &&
          this.formData.port.network_id &&
          this.isCorrectCurrentIp
        ) {
          return this.$emit('change', {
            port: {
              network_id: this.shared ? this.instance.network_id : this.formData.port.network_id,
              fixed_ips: [
                {
                  subnet_id: this.formData.port.fixed_ips[0].subnet_id.id,
                  ip_address: this.formData.port.fixed_ips[0].ip_address,
                  device_id: this.formData.port.fixed_ips[0].device_id,
                },
              ],
            },
          });
        } else return this.$emit('notready');
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": {
      "disk": "Источник диска",
      "error": "Свободные порты закончились.",
      "type": "Подсеть: ",
      "description": "Описание"
    },
    "error": "Такого адреса не существует, либо он уже занят.",
    "port": {
      "label": "IP-адрес"
    },
    "sure": {
      "confirm": "Увеличить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.up {
  margin-top: 0.5rem;
}
.port {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
