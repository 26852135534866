<template>
  <div class="clouds-stats">
    <div class="clouds-stats__header">
      <base-datepicker-filter
        :periods="periods"
        :period="period"
        :value="dateFilter"
        class="clouds-stats__filter"
        @init="onFilterInit"
        @change="onFilterChange"
      />
      <base-button
        :tooltip="{
          content: forPrint,
          autoHide: false,
          placement: 'auto',
          container: false,
        }"
        :disabled="nodata || isLoading"
        size="big"
        theme="outlined"
        tabindex="-1"
        class="clouds-stats__print"
        @click="printStats()"
        ><svg-icon size="icons" :name="icon" class="base-btn__icon" />
      </base-button>
    </div>
    <div v-if="!nodata" class="l-col">
      {{ $t('title') }}
      <strong>{{ $n(summaryExpense, 'currency', this.$i18n.locale) }}</strong>
    </div>
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" />
      <div v-else class="clouds-stats__content">
        <!--        <div class="clouds-stats__grid l-flex-1">-->
        <div class="clouds-stats__grid l-flex-1_md-2">
          <!--          <div v-for="(item, key) in datasets" :key="key" class="l-col">-->
          <div v-for="(item, key) in filtredDataset" :key="key" class="l-col">
            <stack-stats-item
              :title="$t(`titles.${key}`)"
              :labels="labels"
              :datasets="item"
              :deprecated="nodata"
              class="clouds-stats__item"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseDatepickerFilter from '@/components/BaseDatepicker/BaseDatepickerFilter';
import StackStatsItem from '../components/StackStatsItem.vue';
import { StackTariff } from '@/models/BillMgr/StackTariff';
import { format, parseISO } from 'date-fns';
import { excelParser } from '@/mixins/excelParser';
export default {
  name: 'StackStats',
  components: {
    BaseDatepickerFilter,
    StackStatsItem,
  },
  props: {
    icon: {
      type: String,
      default: 'print',
    },
    tariff: {
      type: Object,
      // required: true,
      default: () => {},
      validator: obj => obj instanceof StackTariff,
      // default: StackTariff,
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      arrayOfColumns: [],
      dateFilter: { start: new Date(), end: new Date() },
      // periods: ['day', 'week', 'month', 'quarter', 'year'],
      periods: ['week', 'month', 'quarter', 'year'],
      period: 'week',
      dataRaw: [],
      nodata: false,
    };
  },
  computed: {
    tools() {
      return this.$store.state.moduleStack.tools;
    },
    forPrint() {
      return 'Выгрузить статистику за выбранный период';
    },
    routerId() {
      return this.$router.currentRoute.params.id;
    },
    currentId() {
      return this.tariff && this.tariff.id ? this.tariff.id : this.id;
    },
    getPrice() {
      return this.$store.state.moduleStack.price.length
        ? this.$store.state.moduleStack.price
        : null;
    },
    getItemName() {
      return this.getPrice && this.getPrice.length > 0
        ? this.getPrice.map(x => x.intname).filter(x => x !== 'swift_size')
        : null;
    },
    expenses() {
      // return this.$store.state.moduleStack.expense.filter(x => x.main_item === this.tariff.id);
      return this.$store.state.moduleStack.expense.filter(
        // x => this.labels.includes(x.formatDate) && x.id === this.currentId
        x => this.labels.includes(x.formatDate) && x.id === this.routerId
      );
    },
    summaryExpense() {
      return this.expenses && this.expenses.length > 0
        ? this.expenses
            .map(x => x.sum)
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
        : null;
    },
    datasets() {
      return this.dataRaw.reduce((acc, item) => {
        if (this.getItemName && this.getItemName.length > 0)
          this.getItemName.forEach(itemName => {
            if (!acc[itemName]) {
              let reactoringMeasure = '';
              let measure = item[itemName] ? item[itemName].split(' ')[1] : null;
              if (measure) reactoringMeasure = measure.replace('Гб/час', 'Гб');
              acc[itemName] = [
                {
                  label: this.$t(`labels.${itemName}`),
                  measure: reactoringMeasure,
                  data: [],
                  date: [],
                  expense: [],
                },
              ];
            }
            let date = format(new Date(item.statdate), 'dd.MM.yyyy');
            if (
              this.expenses.filter(x => x.itemName === itemName).find(x => x.formatDate === date)
            ) {
              // console.log(
              //   this.expenses
              //     .filter(x => x.itemName === itemName)
              //     .filter(x => x.formatDate === date)
              //     .reduce((acc, x) => {
              //       return acc + x.sum;
              //     }, 0)
              // );
              acc[itemName][0].expense.push(
                this.expenses
                  .filter(x => x.itemName === itemName)
                  .filter(x => x.formatDate === date)
                  .reduce((acc, x) => {
                    return acc + x.sum;
                  }, 0)
                // this.expenses.filter(x => x.itemName === itemName).find(x => x.formatDate === date)
                //   .sum
              );
            } else acc[itemName][0].expense.push(0);
            acc[itemName][0].date.push(date);
            acc[itemName][0].data.push(parseFloat(item[itemName] ? item[itemName] : 0));
          });
        return acc;
      }, {});
    },
    filtredDataset() {
      // console.log(this.filtredDataset.filter(x => this.arrayOfColumns.includes(x)));
      // O;
      // const filtredField = ['passwd', 'confirm'];
      // не следим за полем старый пароль, который может браузером обновляться
      const filtredFormData = Object.fromEntries(
        this.arrayOfColumns
          .filter(x => !x.startsWith('loadbalancer')) // TODO исправить
          .map(x => [x, this.datasets[x]])
      );
      return filtredFormData;
      // return this.datasets.filter(x => this.arrayOfColumns.includes(x));
    },
    labels() {
      return this.dataRaw.reduce((acc, item) => {
        acc.push(format(parseISO(item.statdate), 'dd.MM.yyyy')); // ???
        return acc;
      }, []);
    },
  },
  watch: {
    dataRaw(value) {
      this.arrayOfColumns = Array.from(new Set(value.map(x => Object.keys(x)).flat())).filter(
        x => !x.includes('statdate')
      );
    },
  },
  methods: {
    init() {
      this.isLoading = true;
      this.fetchStats().finally(() => {
        this.fetchList();
        this.getStackPrice();
        this.isLoading = false;
      });
    },
    getStackPrice() {
      return this.$store.dispatch('moduleStack/getPrice', this.routerId).then(() => {});
    },
    formatData(data, fixed = false) {
      return fixed ? (parseFloat(data) / 24).toFixed(3) : parseFloat(data) / 24;
    },
    onFilterInit(value) {
      this.dateFilter = value;
      this.init();
    },
    onFilterChange(value) {
      this.dateFilter = value;
      this.fetchStats();
      this.fetchList();
    },

    fetchList() {
      return this.$store
        .dispatch('moduleStack/fetchExpenseList', this.routerId)
        .catch(e => this.showError(e));
    },
    getStatisticForPrint() {
      const stats = [];
      const itemsName = Object.values(this.filtredDataset).map(x => x[0].label);
      const itemsType = Object.keys(this.filtredDataset);
      const preparedStats = [];

      itemsType.forEach(item => {
        this.labels.forEach((label, i) => {
          const count = this.filtredDataset[item][0].label;
          const sum = `${this.filtredDataset[item][0].label.split(',')[0]} - сумма`;
          const exp1 = `=("${this.filtredDataset[item][0].data[i]}")`.replace('.', ',');
          const exp2 = `=("${this.filtredDataset[item][0].expense[i]}")`.replace('.', ',');

          preparedStats.push({
            date: label,
            [count]: exp1,
            [sum]: exp2,
          });
        });
      });

      const filteredArr = preparedStats.reduce((acc, current) => {
        const x = acc.find(item => item.date === current.date);
        if (!x) {
          const newCurr = {
            ...current,
          };
          return acc.concat([newCurr]);
        } else {
          const keys = Object.keys(current).filter(x => x !== 'date');
          const currData = Object.assign(x, current);

          return acc;
        }
      }, []);

      excelParser().exportDataFromJSON(filteredArr, null, null);
    },
    printStats() {
      this.getStatisticForPrint();
      // console.log(this.getListServersForPrint());
      // return alert('тут как бы печать');
    },
    fetchStats() {
      const params = {
        // func: this.tools.stat.func + '.simple',
        func: 'service.stat.simple',
        // elid: this.tariff.id,
        // elid: this.currentId,
        elid: this.routerId,
        period: 'other',
        periodstart: format(this.dateFilter.start, 'yyyy-MM-dd'),
        periodend: format(this.dateFilter.end, 'yyyy-MM-dd'),
      };
      return this.$store.dispatch('moduleStack/fetchBillMgrToolAction', params).then(data => {
        if (data && data.model && data.model.reportdata && data.model.reportdata.itemstat) {
          this.nodata = false;
          this.dataRaw = data.model.reportdata.itemstat;
        } else {
          this.nodata = true;
        }
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Суммарный расход за указанный период:",
    "titles": {
      "floating_ip": "Использование плавающего IP",
      "image": "Хранение образов",
      "vcpus": "Процессоры",
      "network_29": "Публичная сеть/29",
      "network_28": "Публичная сеть/28",
      "network_27": "Публичная сеть/27",
      "network_32": "Публичная сеть/32",
      "volume_HDD": "Сетевой диск HDD",
      "snapshot_HDD": "Снапшот HDD",
      "snapshot_SSD": "Снапшот SSD",
      "loadbalancer_ACTIVE_STANDBY": "Балансировщик расширенный",
      "loadbalancer_SINGLE": "Балансировщик базовый",
      "snapshot_SSD-Lite": "Снапшот SSD-Лайт",
      "traffik_antiddos": "Траффик антиДДос",
      "swift_size": "Размер swift",
      "swift_STANDARD_size": "Объектное хранилище",
      "win_instance": "Windows-сервер",
      "volume_SSD": "Сетевой диск SSD",
      "volume_SSD-Lite": "Сетевой диск SSD Lite",
      "root_gb": "Локальный диск",
      "memory": "Оперативная память"
    },
    "labels": {
      "floating_ip": "Плавающий IP, шт * час",
      "image": "Хранение образов, Гб * час",
      "vcpus": "Процессоры, vCPU * час",
      "network_29": "Публичная сеть/29, шт * час",
      "network_28": "Публичная сеть/28, шт * час",
      "network_27": "Публичная сеть/27, шт * час",
      "network_32": "Публичная сеть/32, шт * час",
      "traffik_antiddos": "Траффик антиДДос, Mб * час",
      "loadbalancer_ACTIVE_STANDBY": "Балансировщик расширенный, шт * час",
      "loadbalancer_SINGLE": "Балансировщик базовый, шт * час",
      "swift_size": "Размер swift",
      "swift_STANDARD_size": "Объектное хранилище, Гб * час",
      "win_instance": "Windows-сервер, шт * час",
      "volume_HDD": "Сетевой диск HDD, Гб * час",
      "snapshot_HDD": "Снапшот HDD, Гб * час",
      "snapshot_SSD-Lite": "Снапшот SSD-Лайт, Гб * час",
      "snapshot_SSD": "Снапшот SSD, Гб * час",
      "volume_SSD": "Сетевой диск SSD, Гб * час",
      "volume_SSD-Lite":"Сетевой диск SSD Lite, Гб * час",
      "root_gb": "Локальный диск, Гб * час",
      "memory": "Оперативная память, Гб * час"

    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.clouds-stats {
  &__print {
    padding: 0.25em 1.25rem 0.25em 1.5rem;
    margin: 0 0 1rem;
  +breakpoint(ms-and-down) {
    padding: 0.15em 0.75rem 0.15em 1rem;
  }
  }
  &__header {
    display: flex;
    justify-content: space-between;

  +breakpoint(ms-and-down) {
    flex-direction: row;
    align-items: flex-start;
  }
  }


  &__filter {
    margin-bottom: 1rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
