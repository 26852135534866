<template>
  <div class="loader">
    <div v-if="sizePrefix">
      {{ sizePrefix }}
    </div>
    <Button
      icon="pi pi-sync"
      :disabled="enabled"
      class="p-button-rounded p-button-text"
      :loading="loading"
      @click="onChange(prefix)"
    ></Button>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import Button from 'primevue/button';
import { objectSizeParser } from '@/utils/parsers/objectSizeParser';
export default {
  name: 'LoaderPrefixSize',
  components: { Button },
  mixins: [],
  props: {
    prefix: {
      type: String,
      default: '',
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    storage: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    id() {
      return this.$store.getters['moduleStack/GET_CURRENT']
        ? this.$store.getters['moduleStack/GET_CURRENT']
        : null;
    },
    sizePrefix() {
      const bucket = this.$store.state.moduleStack.sizePrefix.find(
        x => x.bucket === this.storage.name && x.prefix === this.prefix
      );
      return bucket?.size >= 0 ? objectSizeParser(bucket.size) : '';
    },
    size() {
      if (this.storage?.objects.find(x => x.prefix === this.prefix))
        return objectSizeParser(this.storage?.objects.find(x => x.prefix === this.prefix).size);
      else return '';
    },
  },
  watch: {},
  created() {},
  // async mounted() {
  //   await this.updateStorageInfo(this.prefix);
  // },
  beforeUpdate() {},
  beforeMount() {},
  methods: {
    getS3BucketList() {
      return this.$store.dispatch('moduleStack/getS3BucketList', {
        id: this.id,
      });
    },
    async updateStorageInfo(prefix) {
      this.$emit('disable-loader', true);
      this.loading = true;

      const params = {
        storage: this.storage.name,
        currentPrefix: true,
      };
      if (prefix) params.prefix = prefix;
      await this.$store
        .dispatch('moduleStack/fetchCurrentStorages', params)
        // .then(data => console.log(data))
        .then(async () => await this.getS3BucketList())
        .finally(() => {
          this.loading = false;
          this.$emit('disable-loader', false);
        });
    },
    async onChange(event) {
      // console.log(event);
      await this.updateStorageInfo(event);
      // this.loading = !this.loading;
      this.$emit('loader-prefix', event);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "delete": "Удалить IP-адрес:",
    "sure": {
      "confirm": "Подключить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.loader {
  display: flex
  flex-direction row;
  flex-wrap: nowrap;
  align-items: center;
}
</style>
