<template>
  <div class="main-layout" :class="classes">
    <toast-questionnaire v-if="!modals.length" />
    <main-sidebar
      :open="isSidebarOpen"
      hide-footer-on-close
      class="main-layout__sidebar"
      @change="setSidebarState"
    >
      <div class="main-layout__sidebar-navs">
        <transition name="fade">
          <sidebar-nav
            v-if="servicesNav.length"
            :title="$t('sidebar.services')"
            :list="servicesNav"
            class="main-layout__sidebar-nav"
            @exact-click="onExactClick"
          />
        </transition>
        <transition name="fade">
          <sidebar-nav
            v-if="accountNav.length"
            :title="$t('sidebar.account')"
            :list="accountNav"
            class="main-layout__sidebar-nav"
          />
        </transition>
      </div>
      <template #bottom>
        <theme-switcher with-text />
      </template>
      <template #footer>
        <sidebar-select
          v-if="providers.length"
          :options="providers"
          :value="provider"
          :title="$t('sidebar.provider')"
          track-by="id"
          label="name"
          class="main-layout__providers"
          :class="{ hidden: !isSidebarOpen }"
          @change="onProviderChange"
        />
      </template>
    </main-sidebar>
    <div class="main-layout__main">
      <main-header fixed class="main-layout__header main-layout__header--fixed">
        <!--        <nav-simple :list="asideNav[0]" direction="horizontal" :hide-text="isMidScreen" />-->
        <nav-simple-base :list="asideNav[0]" direction="horizontal" :hide-text="isMidScreen" />
      </main-header>
      <div class="main-layout__page">
        <bread-crumbs />
        <transition name="page-slide-fade" mode="out-in">
          <div :key="$route.name" class="main-layout__content">
            <router-view></router-view>
          </div>
        </transition>
      </div>
    </div>
    <ScrollTop class="btn-scrolltop" :threshold="300" />
  </div>
</template>

<script>
import MainHeader from '@/components/Header/MainHeader.vue';
import MainSidebar from '@/components/Sidebar/MainSidebar.vue';
import NavSimpleBase from '@/components/NavSimple/NavSimpleBase.vue';
import SidebarNav from '@/components/Sidebar/SidebarNav.vue';
import SidebarSelect from '@/components/Sidebar/SidebarSelect.vue';
import ThemeSwitcher from '@/components/ThemeSwitcher/ThemeSwitcher';
import breakpoints from '@/utils/breakpoints';
import BaseAlertNotify from '@/components/BaseAlert/BaseAlertNotify';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue';
import viewNotificationDetail from '@/layouts/Profile/mixins/viewNotificationDetail';
import provider from '@/mixins/provider';
import ScrollTop from 'primevue/scrolltop';
import ToastQuestionnaire from '@/components/Toast/ToastQuestionnaire.vue';

export default {
  name: 'MainLayout',
  components: {
    ToastQuestionnaire,
    BreadCrumbs,
    MainHeader,
    MainSidebar,
    NavSimpleBase,
    SidebarNav,
    SidebarSelect,
    ThemeSwitcher,
    ScrollTop,
  },
  mixins: [viewNotificationDetail, provider],

  data() {
    return {
      modals: [],
      timers: null,
      isMidScreen: false,
      isShow: true,
    };
  },
  computed: {
    asideNav() {
      const arr = [];
      if (this.isAstra && this.isReseller && this.linkTo) {
        arr.push([
          {
            key: 'partners',
            icon: 'partners',
            target: '_blank',
            href: this.linkTo,
          },
          {
            key: 'tickets',
            icon: 'support',
            to: { name: 'OpenTicket' },
            access: ['support.clientticket'],
          },
          {
            key: 'all',
            icon: 'empty',
            to: { name: 'all' },
          },
        ]);
      } else if (this.isAstra) {
        arr.push([
          {
            key: 'partners',
            icon: 'partners',
            to: this.isAstra ? { name: 'partnersAstra' } : { name: 'Partners' },
          },
          {
            key: 'tickets',
            icon: 'support',
            to: { name: 'OpenTicket' },
            access: ['support.clientticket'],
          },
          {
            key: 'all',
            icon: 'empty',
            to: { name: 'all' },
          },
        ]);
      } else {
        arr.push([
          {
            key: 'partners',
            icon: 'partners',
            to: this.isAstra ? { name: 'partnersAstra' } : { name: 'Partners' },
          },
          {
            key: 'faq',
            icon: 'faq',
            target: '_blank',
            href: 'https://support.rusonyx.ru/index.php?/Knowledgebase/List',
          },
          {
            key: 'tickets',
            icon: 'support',
            to: { name: 'OpenTicket' },
            access: ['support.clientticket'],
          },
          {
            key: 'all',
            icon: 'empty',
            to: { name: 'all' },
          },
        ]);
      }
      return arr;
    },
    isReseller() {
      return !!(
        this.$store.state.moduleProfile.partners_group &&
        this.$store.state.moduleProfile.partners_group.affiliate &&
        this.partnersGroup.find(x => x.v.includes('Партнерская программа'))
      );
    },
    partnersGroup() {
      return this.$store.state.moduleProfile.partners_group.affiliate || 'null';
    },
    linkTo() {
      return this.$store.state.moduleProfile.referals && this.$store.state.moduleProfile.referals.v
        ? this.$store.state.moduleProfile.referals.v
        : '';
    },
    isSidebarOpen() {
      return this.$store.state.moduleApp.isSidebarOpened;
    },
    classes() {
      const base = 'main-layout';
      return {
        [`${base}--sidebar-open`]: this.isSidebarOpen,
      };
    },

    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
    access() {
      return this.$store.state.moduleProfile.access;
    },
    // не показываем раздел бэкапов каравану
    servicesNav() {
      if (this.providers[0] === this.provider) {
        return this.$store.getters['moduleRoutes/serviceNav'].filter(x => x.key !== 'backups');
      } else {
        return this.$store.getters['moduleRoutes/serviceNav'];
      }
    },
    isAuth() {
      return localStorage.getItem('token') && localStorage.getItem('token').length > 0;
    },
    accountNav() {
      return this.isAstra
        ? this.$store.getters['moduleRoutes/accountNav'].filter(x => x.key !== 'billing')
        : this.$store.getters['moduleRoutes/accountNav'];
    },
    providers() {
      return this.$store.state.moduleProviders.list;
    },
    providersLoading() {
      return this.$store.state.moduleProviders.isLoading;
    },
    isUserHaveAccess() {
      return (
        Object.keys(this.$store.state?.moduleProfile?.access).filter(key =>
          key.includes('mainmenuservice.')
        ).length > 0
      );
    },
    offerIsRead() {
      return sessionStorage.getItem('OfferIsRead');
    },
    typeOrg() {
      if (this.$store.state.moduleBilling.modulePayers.list.length) {
        return this.$store.state.moduleBilling.modulePayers.list[0].profiletype_orig !== '1';
      } else return false;
    },
    isUserHaveProviderRusonyx() {
      return this.$store.state.moduleProviders.list.filter(x => x.id === '3').length === 1;
    },
    isOpenNotificationModal() {
      return !this.isUserHaveAccess && this.isUserHaveProviderRusonyx && this.provider?.id !== '3';
    },
    isNeedShowModal() {
      return this.typeOrg && !this.offerIsRead && this.provider?.id === '3';
    },
    provider() {
      return this.$store.getters['moduleProviders/current'];
    },
    notificationsUnreaded() {
      return this.$store.getters['moduleNotifications/unreaded'];
    },
    listNotifications() {
      return this.notificationsUnreaded &&
        this.notificationsUnreaded.length > 0 &&
        this.notificationsUnreaded.find(i => i.subject.includes('popup_'))
        ? this.notificationsUnreaded.find(i => i.subject.includes('popup_'))
        : 'null';
    },

    // findParentRoute() {
    //   let found = null;
    //   console.log(this.$router.getRoutes());
    //   this.$router.getRoutes().forEach(r => {
    //     (r.children || []).forEach(ch => {
    //       if (r.path + '/' + ch.path == this.$router.path) {
    //         found = r;
    //       }
    //     });
    //   });
    //   return found;
    // },
    text() {
      if (this.provider.id === '3') {
        return `<p>С 1 февраля 2025 — новая оферта</p>
            <p>Теперь Rusonyx будет предоставлять юридическим лицам и ИП односторонние акты выполненных работ.</p>
            <p>Что это значит:</p>
            <ul style="list-style-type: disc;">
            <li style="margin: 10px 0;">Все акты и счета-фактуры будут доступны в вашем личном кабинете в разделе «Финансы → Документы → Закрывающие документы».</li>
            <li style="margin: 10px 0;"> Мы больше не будем отправлять бумажные версии актов и счетов-фактур.</li>
           <li style="margin: 10px 0;">Вы можете дополнительно получать акты и счета-фактуры через систему электронного документооборота.</li>
      </ul>`;
      } else
        return `Уважаемый клиент! <br/><br />Ваша учетная запись перенесена в личный кабинет провайдера "Русоникс" в связи с
<a href="${
          this.provider === '4'
            ? 'https://www.rusonyx.ru/blog/post/reorganizaciya-kompaniy-rusoniks/'
            : 'https://www.rusonyx.ru/blog/post/reorganizaciya-kompaniy-rusoniks/'
        }" target="_blank">реорганизацией</a>, однако Вы зашли в личный кабинет по старой ссылке и попали в ЛК недействующего провайдера.<br />
       Переключить провайдера можно в левом боковом меню или по кнопке ниже. <br/><br />
       Не забудьте обновить Ваши закладки, если входите через "Избранное". <br/>
Новая ссылка для входа в личный кабинет: https://my.rusonyx.ru
`;
    },
  },
  watch: {
    screenWidth: {
      handler(event) {
        this.isMidScreen = event < breakpoints.lg;
        const shouldOpenSidebar = event >= breakpoints.xl;
        if (this.isSidebarOpen !== shouldOpenSidebar) {
          this.setSidebarState(shouldOpenSidebar);
        }
      },
      immediate: true,
    },
    isOpenNotificationModal: {
      handler: function (event) {
        if (event) this.showNotificationModal(this.text);
      },
      immediate: true,
    },
    isNeedShowModal(value) {
      if (value) this.showNotificationModal(this.text);
    },
    notificationsUnreaded: {
      handler: function (event) {
        if (event && this.listNotifications !== 'null') this.viewDetail(this.listNotifications);
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch('moduleNotifications/fetchList');
    this.$store.dispatch('moduleProfile/setPartnersLink').catch(() => {});
    this.$store.dispatch('moduleProfile/getReferalsLink').catch(() => {});
    this.setCookies();
    this.$store.dispatch('moduleBilling/modulePayers/fetchList');
    if (!this.access) {
      this.$store.dispatch('moduleProfile/setProfileInfo').catch(e => {
        localStorage.removeItem('token');
        this.stopCycleFetching();
        this.$router.push({ name: 'Login' }).catch(() => {});
      });
    } else {
      this.$store.dispatch('moduleProviders/fetchList');
      if (this.access['support.notification']) {
        this.runCycleFetching('notifications', 'moduleNotifications/fetchList');
      }
      if (this.access['support.clientticket']) {
        this.runCycleFetching('tickets', 'moduleSupport/fetchAllTickets');
      }
      if (this.access['customer.basket']) {
        this.runCycleFetching('basket', 'moduleBasket/fetchBasket');
      }
    }
    this.$nextTick(() => {
      this.initNavs();
      this.initSidebarState();
      // if (localStorage.getItem('alert') !== 'ok') this.showRes('hi');
    });
  },
  beforeRouteLeave(_to, _from, next) {
    this.setSidebarState(false);
    this.$store.dispatch('moduleStart/reset');
    if (this.timers) this.stopCycleFetching();
    if (!this.isAuth) this.stopCycleFetching();
    next();
  },
  beforeRouteUpdate(_to, _from, next) {
    if (this.isSidebarOpen && this.screenWidth < breakpoints.lg) {
      this.setSidebarState(false);
    }
    next();
  },
  methods: {
    setSidebarState(value) {
      this.$store.commit('moduleApp/SET_IS_SIDEBAR_OPENED', value);
    },
    initSidebarState() {
      if (!this.isSidebarOpen) {
        this.setSidebarState(this.screenWidth >= breakpoints.xl);
      } else if (this.isSidebarOpen && this.screenWidth < breakpoints.lg) {
        this.setSidebarState(false);
      }
    },
    initNavs() {
      const route = this.$router.options.routes.find(i => i.name === 'Main-Layout');
      if (route !== -1) this.$store.dispatch('moduleRoutes/generateNavs', route.children);
    },
    onExactClick() {
      if (this.isSidebarOpen && this.screenWidth < breakpoints.ml) {
        this.setSidebarState(false);
      }
    },
    onProviderChange(value) {
      // console.log(value);
      this.$store.dispatch('moduleProviders/setProject', { elid: value.id }).then(() => {
        this.$store.dispatch('moduleStart/fetchNavHeaderMenu');
        // localStorage.setItem('authToken', localStorage.getItem('token'));
      });
    },
    runCycleFetching(name, module, interval = 1000 * 60) {
      // console.log('cycle', this.isAuth);
      if (!name || !module || !localStorage.getItem('token')) return;
      if (!this.timers) this.timers = {};
      this.$store.dispatch(module);
      // console.log(this.$store.dispatch(module));
      // console.log('fetch');
      this.timers[name] = setTimeout(() => {
        this.runCycleFetching(name, module, interval);
      }, interval);
    },
    showNotificationModal(text) {
      if (this.isOpenNotificationModal) {
        this.$modals.open({
          name: 'Notification',
          closeOnBackdrop: false,
          headers: true,
          html: true,
          size: 'medium',
          text,
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            centered: false,
            confirm: {
              props: { title: this.$t('goto'), color: 'error' },
              on: {
                click: () => {
                  this.$modals.close();
                  this.onProviderChange({ id: '3' });
                  // this.$store.dispatch('moduleStack/fetchOpenStackRequestFirewalls', 'firewalls');
                  // this.$router.push({ name: 'viewStackFirewall' }).catch(() => {});
                },
              },
            },
            cancel: {
              props: { title: this.$t('close') },
              on: { click: () => this.$modals.close() },
            },
          },
        });
      }
      if (this.isNeedShowModal) {
        this.$modals.open({
          name: 'Notification',
          closeOnBackdrop: false,
          headers: true,
          html: true,
          size: 'big',
          text,
          onOpen: inst => {
            this.modals.push(inst.name);
            this.modal = inst;
          },
          onClose: () => (this.modal = null),
          onDismiss: () => {
            this.modals = [];
            sessionStorage.setItem('OfferIsRead', 'true');
            this.modal = null;
          },
          footer: {
            centered: false,
            confirm: {
              props: { title: this.$t('offer'), color: 'success' },
              on: {
                click: () => {
                  window.open(
                    'https://www.rusonyx.ru/pdf/Оферта Rusonyx с 01.02.2025.pdf ',
                    '_blank'
                  );
                  this.modals = [];
                  sessionStorage.setItem('OfferIsRead', 'true');
                  this.$modals.close();
                },
              },
            },
            goto: {
              props: { title: this.$t('edo') },
              on: {
                click: () => {
                  this.modals = [];
                  sessionStorage.setItem('OfferIsRead', 'true');
                  this.$router.push({
                    name: 'BillingDocs',
                  });
                  this.$modals.close();
                },
              },
            },
            cancel: {
              props: { title: this.$t('close') },
              on: {
                click: () => {
                  this.modals = [];
                  sessionStorage.setItem('OfferIsRead', 'true');
                  this.$modals.close();
                },
              },
            },
          },
        });
      } else {
        this.$modals.open({
          name: 'Notification',
          headers: true,
          html: true,
          closeOnBackdrop: false,
          size: 'medium',
          text,
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            centered: false,
            cancel: {
              props: { title: this.$t('close') },
              on: { click: () => this.$modals.close() },
            },
          },
        });
      }
    },
    stopCycleFetching(name = null) {
      if (name) {
        if (this.timers[name]) clearTimeout(this.timers[name]);
      } else if (this.timer) {
        Object.keys(this.timer).forEach(name => clearTimeout(this.timers[name]));
      }
    },
    setCookies() {
      const dateCookies = new Date(2024, 3, 17); //.toUTCString();
      const currentDate = new Date(); //.toUTCString();
      const currentCookies = this.$cookies.get('showAlert');
      const provider = IS_PROD ? window.location.href.includes('rusonyx') : true;
      if (currentDate < dateCookies && !currentCookies && provider) {
        this.$cookies.config(dateCookies);
        this.$cookies.set('showAlert', true);
        // this.$alert('cookies');
        this.$modals.open({
          name: BaseAlertNotify,
          component: BaseAlertNotify,

          props: {
            title: 'Уважаемый Клиент!',
            // text: 'sdsdsdsdsdsd',
          },
          onOpen: inst => (this.modal = inst),
          footer: {
            confirm: {
              props: {
                title: this.$t('modal.sure.confirm'),
              },
              on: {
                click: () => {
                  this.$modals.close();
                  this.$router.push({ name: 'ProfilePersonal' });
                },
              },
            },
            cancel: {
              props: {
                title: this.$t('modal.sure.close'),
              },
              on: {
                click: () => {
                  this.$modals.close();
                },
              },
            },
          },
        });
      }
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "alarmText": "Уважаемый клиент! Ваша учетная запись переехала к провайдеру \"Русоникс\" в связи с %{link}, однако Вы зашли в личный кабинет по старой ссылке и попали в недействующего провайдера.",
      "sidebar": {
        "projects": "Проекты",
        "services": "Услуги",
        "account": "Аккаунт",
        "all": "Все услуги",
        "provider": "Провайдер"
      },
      "modal": {
        "sure": {
          "text": "Вы собираетесь %{msg} сервер. Вы уверены?",
          "confirm": "Актуализировать",
          "close": "Закрыть",
          "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",
          "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
        }
        },
      "labels": {
        "Main-Layout" : "Главная ",
        "start" : "Главная ",
        "Stack" : "Публичное облако ",
        "Disk" : "Публичное облако ",
        "vpsMain" : "Публичное облако ",
        "vpsView" : "Публичное облако ",
        "VPS" : "VPS Серверы ",
        "acronis" : "Облачный бэкап ",
        "SSL" : "SSL Серификаты ",
        "zmail" : "Почты Zmail ",
        "bitrix" : "Лицензии Bitrix ",
        "Domains" : "Домены и DNS ",
        "virtual" : "Виртуальный хостинг Plesk ",
        "plesk" : "Лицензии Plesk ",
        "soft" : "Лицензии ispmanager ",
        "devSanbox" : "Песочница ",
        "rent" : "Аренда физических серверов ",
        "dedic" : "Выделенный сервер ",
        "antiddos" : "Защита AntiDDoS ",
        "idprotect" : "Защита Whois",
        "cdn" : "CDN ",
        "Billing" : "Финансы ",
        "Profile" : "Профиль ",
        "Support" : "Поддержка ",
        "domainsList" : "Список доменов и поддоменов ",
        "domainsOrder" : "Зарегистрировать домен ",
        "all": "Все услуги",

        "disk" : "Публичное облако",
        "snapshots" : "Публичное облако",
        "viewStack" : "Публичное облако",
        "viewStackDisk" : "Публичное облако",
        "viewSsh" : "Публичное облако",
        "quotas" : "Публичное облако",
        "stat" : "Публичное облако",
        "viewStackImage" : "Публичное облако",
        "viewStackSnapshot" : "Публичное облако",
        "private" : "Публичное облако",
        "sub" : "Публичное облако",
        "config" : "Публичное облако",
        "backup" : "Публичное облако",
        "history" : "Публичное облако",
        "ip" : "Публичное облако",
        "port" : "Публичное облако",
        "router" : "Публичное облако",
        "firewall" : "Публичное облако",
        "floatingips" : "Публичное облако",
        "public" : "Публичное облако",
        "info" : "Публичное облако"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$padding-left = 282px;
$padding-left = 36px;
.main-layout {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: var(--main-theme);
  color: var(--text);
  transition: background $anim-base-duration ease, color $anim-base-duration ease;

  &__header {
    padding-left: $sidebar-header-ms;

    +breakpoint(sm-and-up) {
      padding-left: $sidebar-header-sm;
    }
    +breakpoint(md-and-up) {
      padding-left: $sidebar-header-md;
    }
    +breakpoint(lg-and-up) {
      padding-left: $sidebar-width;
    }
  }
  &__page {
    display: flex;
    min-height: 100vh;
    flex-direction: column

    ^[0]__header--fixed + & {
      padding-top: $header-height-ms;

      +breakpoint(sm-and-up) {
        padding-top: $header-height;
      }
    }

    +breakpoint(lg-and-up) {
      padding-left: $sidebar-width-min;
      transition: padding-left 0.8s ease-out;
    }

    ^[0]--sidebar-open & {
      +breakpoint(lg-and-up) {
        padding-left: $sidebar-width;
      }
    }
  }
  &__content {
    width: 100%;
    min-width: 100%;
    padding: 1.5rem 1rem;

    +breakpoint(sm-and-up) {
      //padding: 2.5rem 1.25rem 1.5rem;
      padding: 1.5rem 1.25rem 1.5rem;
    }
    +breakpoint(md-and-up) {
      padding: 1.5rem $gutter-outside 5rem;
      //padding: 2.5rem $gutter-outside 5rem;
    }
  }

  &__sidebar {
    &-navs {
      flexy(space-between, stretch, nowrap, column);
    }
    &-nav {
      margin-bottom: 1.5rem;
    }
  }

  &__providers {
    transition: opacity $anim-base-duration ease;

    &.hidden {
      pointer-events: none;
      opacity: 0;
    }
  }
  .btn-scrolltop {
    z-index: 998 !important;
    background-color: rgba(15, 104, 228, 0.8);

    &:hover {
      background-color: rgba(0, 53, 128, 0.9);
    }
    box-shadow: none;
    transition: background $anim-base-duration ease, color $anim-base-duration ease, border-color $anim-base-duration ease, opacity $anim-base-duration ease;
    +breakpoint(ms-down) {
      width: 30px;
      height: 30px;
    }
    +breakpoint(ms-and-up) {
      width: 35px;
      height: 35px;
    }
    +breakpoint(sm-and-up) {
      width: 40px;
      height: 40px;
    }
    +breakpoint(md-and-up) {
      width: 45px;
      height: 45px;
    }
    +breakpoint(ml-and-up) {
      width: 48px;
      height: 48px;
    }
    +breakpoint(lg-and-up) {
      width: 50px;
      height: 50px;
    }
  }
}
</style>
