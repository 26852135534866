<template>
  <div class="slider-block">
    <div class="slider-block__header">
      <div class="slider-block__header-title standart-title">
        {{ label || config.title }}
        <plain-button
          v-if="hint"
          v-tooltip="{ content: hint, placement: 'top-start' }"
          icon="help"
          size="small"
          color="dim"
          tabindex="-1"
          class="slider-block__hint"
        />
      </div>
      <InputNumber
        v-model="internalValue"
        :max="config.max"
        mode="decimal"
        :min="config.min"
        locale="ru-RU"
        :input-style="{
          'text-align': 'center',
          'max-width': '6rem',
          'min-width': '4rem',
          'margin-left': '1rem',
        }"
        class="p-inputtext-sm"
        @input="onChange('change', $event)"
      />
      <cost-label
        v-if="hasCost && !hideCostLabel"
        class="slider-block__header-label"
        :cost="sum"
        :stack="true"
        :period="isNumber(shownPeriod) ? shownPeriod : period"
      />
    </div>
    <div class="slider-block__slider-wrapper">
      <span class="slider-block__slider-text standart-text l-hidden-ms-down">
        {{ minText || config.label }}
      </span>
      <!--        tooltip="focus"-->
      <base-slider
        v-tooltip="tooltipSettings"
        :value="config.value"
        :min="config.min"
        :max="config.max"
        :step="config.step"
        :readonly="false"
        :slider-data="config.sliderData"
        :measure="config.measure"
        :disabled="config.disabled"
        class="slider-block__slider"
        @change="onChange('slider', $event)"
      />
      <span class="slider-block__slider-text standart-text l-hidden-ms-down">
        {{ maxText || config.label }}
      </span>
    </div>
  </div>
</template>

<script>
import BaseSlider from '@/components/BaseSlider/BaseSlider.vue';
import CostLabel from '@/components/Labels/CostLabel.vue';
import SliderComponent from '@/models/base/components/slider';
import { throttle, isNumber } from 'lodash';
import { BillMgrPeriods } from '@/models/BillMgr/periods';
import InputNumber from 'primevue/inputnumber';
export default {
  name: 'SliderStackBlock',
  components: { BaseSlider, CostLabel, InputNumber },
  inheritAttrs: false,
  props: {
    changable: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      required: true,
      validator: obj => obj instanceof SliderComponent || typeof obj.value !== 'undefined',
    },
    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    period: {
      type: [Number, String],
      default: 1,
      validator: val => BillMgrPeriods[val],
    },
    periodKey: {
      type: String,
      default: null,
      validator: val => Object.values(BillMgrPeriods).includes(val),
    },
    shownPeriod: {
      type: [String, Number],
      default: null,
      validator: val => isNumber(val) || BillMgrPeriods[val],
    },
    cost: {
      type: Number,
      default: null,
    },
    old: {
      type: Number,
      default: null,
    },
    measure: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      internalValue: this.config.value,
      error: '',
      customValidationMsgs: {
        confirm: { patternMismatch: 'test' },
      },
    };
  },
  computed: {
    tooltip() {
      return 'Введенное число не должно превышать максимально допустимое<br /> и быть меньше минимально допустимого';
    },
    tooltipSettings() {
      const config = this.config;
      const disabled =
        !this.config.sliderData && config.min === config.max ? this.$t('disabled') : null;
      const restrict =
        config.restrictclientchange === 'off'
          ? null
          : this.$t(`restrictclientchange.${config.restrictclientchange}`);
      return disabled ? disabled : restrict ? restrict : null;
    },
    hasCost() {
      return isNumber(this.cost);
    },
    hideCostLabel() {
      // console.log(this.cost, this.shownPeriod);
      return this.cost === 0 && this.shownPeriod === 0;
    },
    pattern() {
      return this.config.value; // > this.config.max
      //   ? this.config.max
      //   : this.config.value < this.config.min
      //   ? this.config.min
      //   : this.config.value;
    },
    sum() {
      if (!this.hasCost) return null;
      // let value = ((this.internalValue - this.config.limit) / this.config.step) * this.cost;
      let value = (this.internalValue - this.old) * this.cost;
      if (this.shownPeriod) return (value / this.period) * this.shownPeriod;
      else return value;
    },
    maxText() {
      let max = this.config.sliderData
        ? this.config.sliderData[this.config.sliderData.length - 1]
        : this.config.max;
      let formatted = new Intl.NumberFormat().format(max);
      return this.config.measure ? `${formatted} ${this.config.measure}` : formatted;
    },
    minText() {
      let min = this.config.sliderData
        ? this.config.sliderData[this.config.sliderData.length - 1]
        : this.config.min;
      let formatted = new Intl.NumberFormat().format(min);
      return this.config.measure ? `${formatted} ${this.config.measure}` : formatted;
    },
    periodKeyFinal() {
      return this.periodKey || BillMgrPeriods[this.period];
    },
  },
  watch: {
    'config.value': function (val) {
      if (this.internalValue !== val) {
        this.internalValue = val;
        // console.log(this.internalValue + '___' + newVal);
      }
      // console.log(newVal, oldVal);
    },
  },
  methods: {
    isNumber(n) {
      return isNumber(n);
    },
    onChange(item, value) {
      // console.log(item, value, event.target.value);
      if (event.target.value > this.config.max) {
        this.error = 'Введенное значение не может превышать максимальное значение квоты';
      } else if (event.target.value < this.config.min) {
        this.error = 'Введенное значение не может быть меньше минимального размера диска';
      }
      if (item === 'inputEnter') {
        event.target.value = this.config.value;
        this.internalValue = this.config.value;
        this.error = null;
      }
      this.internalValue =
        +value > this.config.max
          ? this.config.max
          : +value < this.config.min
          ? this.config.min
          : +value;
      this.config.value =
        +value > this.config.max
          ? this.config.max
          : +value < this.config.min
          ? this.config.min
          : +value;
      // this.internalValue = +value;
      // this.config.value = +value;
      throttle(
        () => {
          let res = { value };
          if (this.hasCost) res.sum = this.sum;
          this.$emit('change', res);
        },
        100,
        { trailing: false }
      )();
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "disabled": "Значение нельзя изменить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vue-slider-dot-tooltip-text{
  z-index: 9999
}
.slider-block {
  &__header {
    margin-bottom: 2.5rem;

    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    &-title,
    &-text {
      transition: color $anim-base-duration ease;
    }
    &-title {
      flexy(flex-start, baseline);
    }

    &-label {
      margin-top: 0.5rem;
      display: flex;
      transition: all $anim-base-duration ease;

      +breakpoint(sm-and-up) {
        margin-left: 2.5rem;
        margin-top: 0;
      }
    }
  }
  &__hint {
    margin-left: 0.25rem!important;
    align-self: center;
  }
  &__slider {
    flex: 1 1 auto;

    &-wrapper {
      +breakpoint(sm-and-up) {
        //flexy(flex-start, center);
        flexy(flex-start, flex-end);
      }
    }

    &-text {
      flex: 0 0 3.25rem;
      margin-left: 0.25rem;
    }
  }
}
</style>
