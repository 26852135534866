<template>
  <div class="vps-detail">
    <transition name="fade" mode="out-in">
      <div v-if="isCurrentLoading" class="vps-detail__loader">
        <base-loader class="vps-detail__loader-item" />
      </div>
      <base-alert
        v-else-if="!isCurrentLoading && isServerError"
        :title="$t('errors.serverError.title')"
        icon="warn"
        class="vps-info__alert"
      >
        {{ $t('errors.serverError.text') }}
      </base-alert>
      <div v-else-if="tariff && tariff.status" class="vps-detail__inner">
        <div class="vps-detail__content">
          <layout-stack-title-menu
            :title="tariff.name"
            :address="tariff.id + '  MTU: ' + tariff.mtu"
            :status="tariff.status"
            :description="tariff.description"
            :state="state(tariff)"
            class="vps-detail__head"
          >
            <template #btn>
              <v-popover placement="bottom" popover-class="vps-detail__popover">
                <sidebar-button />
                <template #popover>
                  <private-network-context-menu
                    :tariff="tariff"
                    :is-loading="isCurrentLoading"
                    class="vps-detail__context-menu"
                  />
                </template>
              </v-popover>
            </template>
            <template #button>
              <base-button
                :icon="isIcon"
                :color="isColor"
                :tooltip="{
                  content: text,
                  autoHide: false,
                  placement: 'left-start',
                  container: false,
                  trigger: 'click hover',
                }"
                class="subnet-info__create-btn"
                @click="clickActionButton(tariff)"
                >{{ $t(`${button?.type}`) }}</base-button
              >
            </template>
          </layout-stack-title-menu>
          <tabs :list="navPrivate" button="private" class="medium-title">
            <template v-slot:item="{ item }">
              <router-link exact-active-class="active" :to="item.to">
                {{ item.title }}
              </router-link>
            </template>
          </tabs>
          <transition name="slide-fade">
            <router-view
              v-if="tariff"
              :tariff="tariff"
              @change-view="changeView($event)"
            ></router-view>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs.vue';
import LayoutStackTitleMenu from '@/components/LayoutTitle/LayoutStackTitleMenu.vue';
import SidebarButton from '@/components/Buttons/SidebarButton.vue';
import PrivateNetworkContextMenu from '../pages/Main/components/PrivateNetworkContextMenu';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import storeMixin from '../mixins/index';
import breakpoint from '@/utils/breakpoints';
import providerChange from '@/mixins/providerChange';
import newSubnet from '@/mixins/newSubnet';
import newPort from '@/mixins/newPort';
export default {
  name: 'Private',
  components: {
    LayoutStackTitleMenu,
    tabs,
    SidebarButton,
    PrivateNetworkContextMenu,
    BaseAlert,
  },
  mixins: [storeMixin, providerChange, newPort, newSubnet],
  props: {
    privateid: {
      type: String,
      default: '',
    },
    publicid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      button: {
        type: 'subnet',
        quota: false,
      },
      isCurrentLoading: false,
      isServerError: false,
      navPrivate: [
        {
          title: this.$t('nav.subnet'),
          to: { name: 'SubnetView' },
        },
        {
          title: this.$t('nav.ports'),
          to: { name: 'PortView' },
        },
      ],
      isMobile: true,
    };
  },
  computed: {
    tariff() {
      return this.privateid
        ? this.$store.state.moduleStack.networks.find(x => x.id === this.privateid)
        : this.$store.state.moduleStack.networks.find(x => x.id === this.publicid);
    },
    isIcon() {
      return this.button?.quota ? 'error' : '';
    },
    isColor() {
      return this.button?.quota ? 'warn' : 'primary';
    },
    text() {
      if (this.button?.quota) {
        return `Вы достигли квоты по количеству ${
          this.button?.type === 'subnet' ? 'подсетей' : 'портов'
        }.<br /><br />
Перейдите в раздел <strong><a href="${localStorage.getItem('urlHash')}stack/${
          this.bmId
        }/quotas">Квоты</a></strong> для увеличения. `;
      } else {
        return `Создать ${
          this.button?.type === 'subnet' ? 'новую приватную подсеть' : 'новый порт'
        }`;
      }
    },
    // currentRoute() {
    //   return this.$route.name;
    // },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
  },
  watch: {
    screenWidth: function () {
      this.setIsMobile();
    },
  },
  mounted() {
    this.$nextTick(() => this.setIsMobile());
    this.newFetch();
  },
  methods: {
    clickActionButton(tariff) {
      return this.button?.quota
        ? ''
        : this.button.type === 'subnet'
        ? this.newSubnet(tariff)
        : this.newPort(tariff);
    },
    changeView(event) {
      this.button = Object.assign(this.button, event);
    },
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
    state(item) {
      const state = item.admin_state_up;
      return {
        color: state === true ? 'success' : 'error',
        key: state === true ? 'running' : 'stopped',
      };
    },
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "subnet": "Новая подсеть",
      "port": "Создать порт",
      "nav": {
        "subnet": "Подсети",
        "ports": "Порты"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;
.vps-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $vd-aside-size;
  }

  &__aside {
    display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $vd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 1.5rem;
    }
  }

  &__context-menu {
    max-width: 100%;
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-detail {
  &__popover {
    .popover-inner {
      transform: translateX(-0.75rem)
    }
  }
}
</style>
