<template>
  <div class="vps-detail">
    <Toast position="top-right" />
    <transition name="slide-fade">
      <page-block-stack :title="' '" class="vps-detail-backup__block">
        <base-loader v-if="isRequest" class="open-ticket__loader"> </base-loader>
        <page-block v-else-if="!isRequest" style="margin-top: 0">
          <template #pagination>
            <breadcrumds-storage
              v-if="prefix.length > 1"
              :storage="storageid"
              :disabled="isDisabledButtons"
              :prefix="prefix"
              @folder-click="onFolderClick($event)"
              @storage-click="onStorageClick()"
            />
            <!--            <plain-button color="del" nowrap>-->
            <!--              {{ prefix }}-->
            <!--            </plain-button>-->
          </template>
          <!--            state-storage="local"-->
          <!--            :state-key="stateKey"-->
          <!--            @value-change="onValueChange($event)"-->
          <DataTable
            :always-show-paginator="true"
            :value="objects"
            ref="objects"
            :paginator="true"
            :page-link-size="10"
            :loading="loader"
            :expanded-rows.sync="expandedRows"
            @row-expand="onRowExpand"
            @row-collapse="onRowCollapse"
            data-key="key"
            @page="onPage"
            :first="firstPage"
            @sort="onSort"
            :show-gridlines="false"
            :show-clear-button="true"
            :rows-per-page-options="[10, 25, 50, 100]"
            :row-hover="true"
            removable-sort
            :filters.sync="filters"
            :global-filter-fields="['uniqueName', 'size', 'date']"
            class="p-datatable p-datatable-sm"
            responsive-layout="scroll"
            current-page-report-template=" из {totalPages}"
            paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageInput CurrentPageReport RowsPerPageDropdown "
            :rows="rowPage"
          >
            <!--            :selection.sync="selectedProducts"-->
            <!--            current-page-report-template="({currentPage} из {totalPages})"-->
            <!--            :global-filter-fields="['uniqueName', 'path', 'size', 'date']"-->
            <template #header>
              <div class="filter">
                <div class="filter-upload">
                  <FileUpload
                    ref="upload"
                    mode="basic"
                    :disabled="isDisabledButtons"
                    :name="file && file.name ? file.name : null"
                    :multiple="false"
                    :auto="false"
                    :max-file-size="10 * 1024 ** 3"
                    choose-label="Добавить"
                    :custom-upload="true"
                    :show-cancel-button="true"
                    :invalid-file-size-message="
                      'Вы можете загрузить файл размером до 10ГБ.\n' +
                      'Файлы более 10ГБ можно загрузить через CLI или приложение для работы с S3.'
                    "
                    button-class="p-button-sm"
                    button-style="upload-button"
                    @progress="progressLoad($event)"
                    @select="select($event)"
                    @clear="clearFile($event)"
                    @before-upload="view($event)"
                    @uploader="myUploader($event)"
                  />
                  <Button
                    v-if="fileName && !loader"
                    type="button"
                    :disabled="isDisabledButtons"
                    icon="pi pi-times"
                    class="p-button-danger p-button-sm filter-upload__btn"
                    @click="$refs.upload.clear()"
                  ></Button>
                  <plain-button
                    v-if="!fileName"
                    color="primary"
                    nowrap
                    class="filter-upload__btn"
                    @click="
                      !isDisabledButtons
                        ? newFolder({ storage: tariff, storageid: storageid, prefix: prefix })
                        : ''
                    "
                  >
                    <!--                    @click="newFolder({ storage: tariff, storageid: storageid, folders: folders })"-->
                    {{ $t('addFolder') }}
                  </plain-button>
                </div>
                <div v-if="progress" class="filter-progress">
                  <base-progress
                    v-if="progress"
                    :value="progress"
                    :text="' '"
                    type="indeterminate"
                  ></base-progress>
                </div>
                <div class="filter-search">
                  <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Поиск..." />
                    <Button
                      v-if="filters['global']?.value?.length > 0"
                      type="button"
                      :disabled="isDisabledButtons"
                      icon="pi pi-times"
                      class="p-button-danger p-button"
                      style="margin-left: 0.25rem"
                      @click="filters['global'].value = ''"
                    ></Button>
                  </span>
                </div>
              </div>
            </template>
            <template #empty> Нет объектов. </template>
            <template #loading> Идет загрузка информации... </template>
            <!--            <Column-->
            <!--              selection-mode="multiple"-->
            <!--              :styles="{ 'min-width': '2rem', 'text-align': 'center' }"-->
            <!--              :header-styles="{ 'min-width': '2rem', 'text-align': 'center' }"-->
            <!--            ></Column>-->
            <Column
              filter-field="uniqueName"
              :exclude-global-filter="true"
              field="uniqueName"
              header="Имя"
              :styles="{ width: '65%' /*, 'padding-left': '80px'*/ }"
              :sortable="true"
            >
              <template #body="slotProps">
                <!--                  :disabled="isDisabledButtons"-->
                <router-link
                  v-if="slotProps.data.isFolder && !isDisabledButtons"
                  :to="{ name: 'containerObjects', query: { prefix: slotProps.data.prefix } }"
                  class="folder-link medium-text"
                >
                  <i class="pi pi-folder" />
                  {{ slotProps.data.uniqueName }}
                </router-link>
                <!--                    :disabled="isDisabledButtons"-->
                <!--                    :to="{ name: 'containerObjects', query: { prefix: slotProps.data.prefix } }"-->
                <div
                  v-else-if="slotProps.data.isFolder && isDisabledButtons"
                  class="folder-link medium-text"
                >
                  <i class="pi pi-folder" />
                  {{ slotProps.data.uniqueName }}
                </div>
                <label v-else class="standart-text">
                  <i class="pi pi-file" />
                  {{ slotProps.data.uniqueName }}
                </label>
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Поиск по имени"
                />
              </template>
            </Column>
            <Column field="size" header="Размер" :styles="{ width: '10%' }" :sortable="true">
              <template #body="slotProps">
                <div v-if="slotProps.data.isFolder">
                  <loader-prefix-size
                    :prefix="slotProps.data.prefix"
                    :storage="tariff"
                    ref="prefixSize"
                    :enabled="enabledPrefixLoader"
                    @disable-loader="disablePrefixLoader($event)"
                    @loader-prefix="loadPrefixSize($event)"
                  />
                </div>
                <div v-else>
                  {{ size(slotProps.data) }}
                </div>
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Поиск по размеру"
                />
              </template>
            </Column>
            <Column field="date" header="Дата" :styles="{ width: '15%' }" :sortable="true">
              <template #body="slotProps">
                {{ created(slotProps.data) }}
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Поиск по дате"
                />
              </template>
            </Column>
            <Column :expander="true" :header-style="{ width: '4%' }" />
            <template #expansion="slotProps">
              <div :class="slotProps.data.isFolder ? 'container-folder' : 'container-file'">
                <div v-if="!slotProps.data.isFolder" class="container-file-info">
                  <show-link
                    :shared="slotProps.data.isShared"
                    :name="slotProps.data.key"
                    :icons="iconForCopy[slotProps.data.key]"
                    :link="`https://s3.rusonyxcloud.ru/${storageid}/${slotProps.data.key}`"
                    @set-icon="setIcon($event)"
                  />
                  <div class="container-file-info__view__el">
                    <div class="container-file-info__el">
                      <label class="standart-title">Класс хранилища:</label>
                      <label class="standart-text">{{ slotProps.data.class }}</label>
                    </div>
                    <div class="container-file-info__el">
                      <label class="standart-title">Content type:</label>
                      <label class="standart-text">{{ slotProps.data.type }}</label>
                    </div>
                    <div class="container-file-info__el">
                      <label class="standart-title">ETag:</label>
                      <label class="standart-text">{{ slotProps.data.eTag }}</label>
                    </div>
                  </div>
                </div>

                <div class="buttons-context">
                  <Button
                    v-if="!slotProps.data.isFolder"
                    type="linc"
                    label="Скачать"
                    icon-pos="right"
                    :disabled="isDisabledButtons"
                    icon="pi pi-download"
                    class="p-button-rounded p-button-text"
                    @click="viewInfo(slotProps.data)"
                  ></Button>
                  <!--                    v-if="isSharedLinced(slotProps.data.key) && !slotProps.data.isFolder"-->
                  <!--                                    <Button-->
                  <!--                                      v-if="slotProps.data.isShared && !slotProps.data.isFolder"-->
                  <!--                                      type="linc"-->
                  <!--                                      label="Скопировать ссылку"-->
                  <!--                                      icon="pi pi-external-link"-->
                  <!--                                      icon-pos="right"-->
                  <!--                                      class="p-button-rounded p-button-text"-->
                  <!--                                      @click="copyLink(slotProps.data.key)"-->
                  <!--                                    />-->
                  <!--                                    <div v-if="!isSharedLinced(slotProps.data.key) && !slotProps.data.isFolder">-->
                  <div v-if="!slotProps.data.isShared">
                    <transition name="fade" mode="out-in">
                      <!--                      <div v-if="isLinkCreatedObj[slotProps.data.key]">-->
                      <!--                      <div v-if="!slotProps.data.isFolder">-->
                      <!--                        <create-link :link-expired="linkExpired[slotProps.data.key]" />-->
                      <!--                      </div>-->
                      <!--                                              v-else-->
                      <Button
                        v-if="!slotProps.data.isFolder"
                        type="linc"
                        :disabled="isDisabledButtons"
                        label="Поделиться"
                        icon="pi pi-share-alt"
                        icon-pos="right"
                        class="p-button-rounded p-button-text"
                        @click="createS3Link(slotProps.data.key)"
                      />
                    </transition>
                  </div>
                  <!--                  <Button-->
                  <!--                    v-if="-->
                  <!--                      slotProps.data.isFolder &&-->
                  <!--                      !slotProps.data.isPublic &&-->
                  <!--                      !slotProps.data.isPrefix &&-->
                  <!--                      slotProps.data.policy === 'nopolicy'-->
                  <!--                    "-->
                  <!--                    type="linc"-->
                  <!--                    label="Расшарить"-->
                  <!--                    icon="pi pi-user-edit"-->
                  <!--                    icon-pos="right"-->
                  <!--                    class="p-button-rounded p-button-text p-button-еуче"-->
                  <!--                    @click="editAclFolder(storageid, slotProps.data.key)"-->
                  <!--                  ></Button>-->
                  <!--                  <Button-->
                  <!--                    v-if="-->
                  <!--                      slotProps.data.isFolder &&-->
                  <!--                      slotProps.data.isPublic &&-->
                  <!--                      !slotProps.data.isPrefix &&-->
                  <!--                      slotProps.data.policy !== 'nopolicy'-->
                  <!--                    "-->
                  <!--                    type="linc"-->
                  <!--                    label="Зашарить"-->
                  <!--                    icon="pi pi-user-edit"-->
                  <!--                    icon-pos="right"-->
                  <!--                    class="p-button-rounded p-button-text p-button-еуче"-->
                  <!--                    @click="editAclFolder(storageid, slotProps.data.key)"-->
                  <!--                  ></Button>-->
                  <Button
                    v-if="slotProps.data.isFolder"
                    type="linc"
                    label="Загрузить файл"
                    :disabled="isDisabledButtons"
                    icon="pi pi-upload"
                    icon-pos="right"
                    class="p-button-rounded p-button-text p-button-еуче"
                    @click="putFileInFolder(slotProps.data.key)"
                  ></Button>
                  <Button
                    type="linc"
                    label="Удалить"
                    icon="pi pi-trash"
                    :disabled="isDisabledButtons"
                    icon-pos="right"
                    class="p-button-rounded p-button-text p-button-danger"
                    @click="deleteObject(slotProps.data.key)"
                  ></Button>
                </div>
              </div>
            </template>
            <template #paginatorend>
              <!--              <Button-->
              <!--                type="button"-->
              <!--                label="Свернуть"-->
              <!--                icon="pi pi-minus"-->
              <!--                icon-pos="right"-->
              <!--                class="p-button-text"-->
              <!--                @click="collapseAll"-->
              <!--              />-->
            </template>
            <template #paginatorstart>
              <!--              <Button-->
              <!--                type="button"-->
              <!--                label="Развернуть"-->
              <!--                icon="pi pi-plus"-->
              <!--                class="p-button-text"-->
              <!--                @click="expandAll"-->
              <!--              />-->
            </template>
            <!--            <template #footer>-->
            <!--              <div class="datatable-footer">-->
            <!--                <Button-->
            <!--                  icon="pi pi-sync"-->
            <!--                  v-tooltip.top="'Обновить'"-->
            <!--                  class="p-button-sm"-->
            <!--                  @click="updateStorageInfo()"-->
            <!--                />-->
            <!--                <Button-->
            <!--                  icon="pi pi-eraser"-->
            <!--                  v-tooltip.top="'Очистить настройки'"-->
            <!--                  class="p-button-sm"-->
            <!--                  @click="clearLocalSettings()"-->
            <!--                />-->
            <!--              </div>-->
            <!--            </template>-->
          </DataTable>
        </page-block>
        <router-view></router-view>
      </page-block-stack>
    </transition>
  </div>
</template>

<script>
// import Chips from '@/components/Chips/Chips';
// import CreateLink from '@/layouts/Stack/components/CreateLink';
// import { saveAs } from 'file-saver';
// import ChangeDescription from '@/layouts/Stack/components/ChangeDescription.vue';
import DataTable from 'primevue/datatable';
import breakpoint from '@/utils/breakpoints';
import providerChange from '@/mixins/providerChange';
import { OPStorage } from '@/models/OP/OPStorage';
import PageBlockStack from '@/components/PageBlock/PageBlockStack';
import newFolder from '@/mixins/newFolder';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import popoverFix from '@/mixins/popoverFix';
import Column from 'primevue/column';
import { objectSizeParser } from '@/utils/parsers/objectSizeParser';
import { format } from 'date-fns';
import Toast from 'primevue/toast';
import deleteObjects from '@/mixins/deleteObjects';
import Vue from 'vue';
import viewObject from '@/mixins/viewObject';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import modals from '@/mixins/modals';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { GetObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import Tooltip from 'primevue/tooltip';
import createS3Link from '@/layouts/Stack/mixins/createS3Link';
import FileUpload from 'primevue/fileupload/FileUpload';
import BaseProgress from '@/components/BaseLoader/BaseProgress';
import BreadcrumdsStorage from '@/layouts/Stack/components/BreadcrumdsStorage.vue';
import editAclFolder from '@/layouts/Stack/mixins/editAclFolder';
import showErrorModal from '@/mixins/showErrorModal';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import ShowLink from '@/layouts/Stack/components/ShowLink.vue';
import LoaderPrefixSize from '@/layouts/Stack/components/LoaderPrefixSize.vue';

export default {
  name: 'Objects',
  components: {
    ShowLink,
    LoaderPrefixSize,
    // BaseInput,
    BreadcrumdsStorage,
    // CreateLink,
    BaseProgress,
    DataTable,
    Column,
    // Chips,
    PageBlockStack,
    InputText,
    // InputNumber,
    // MaskedInput,
    // Calendar,
    // InputMask,
    Toast,
    FileUpload,
    Button,
  },
  mixins: [
    providerChange,
    newFolder,
    viewObject,
    popoverFix,
    createS3Link,
    editAclFolder,
    showErrorModal,
    modals,
    deleteObjects,
  ],
  beforeRouteLeave(_to, _from, next) {
    this.collapseAll();
    next();
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    storageid: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: '',
    },
    tariff: {
      type: OPStorage,
      required: true,
      validator: obj => obj instanceof OPStorage,
    },
  },
  data() {
    return {
      acl: ['FULL_CONTROL', 'READ', 'WRITE', 'WRITE_ACP', 'READ_ACP'],
      checkedACL: {},
      currentObject: '',
      enabledPrefixLoader: false,
      isDisabledButtons: false,
      // testPrefix: {
      //   loading: true,
      // },
      defaultLinkExpired: {
        d: 0,
        h: 1,
        m: 0,
        s: 0,
      },
      download: false,
      expandedRows: [],
      file: {},
      fileName: '',
      filters: {
        global: { value: null },
        uniqueName: { value: null },
        path: { value: null },
      },
      firstPage: 0,
      folders: [],
      invalidDates: null,
      isCurrentLoading: false,
      isFileUploadInFolder: false,
      isLinkCopy: {},
      isLinkCreatedObj: {},
      isMobile: true,
      isRequest: false,
      isLoadSizePrefix: false,
      linkExpired: {},
      linkTime: '01:00:00',
      loader: false,
      maxDate: null,
      minDate: null,
      mode: '',
      objectsACL: {},
      prefixFolderForUploadInFolder: null,
      prefixForPutFileInFolder: '',
      progress: 0,
      rowPage: 10,
      selectedObjectsACL: {},
      selectedProducts: [],
      totalSize: 100,
      type: 'determinate',
    };
  },
  computed: {
    s3key() {
      return this.$store.state.moduleStack.s3Keys[0];
    },
    // isEnabledPrefixLoader() {
    //   console.log(Object.keys(this.enabledPrefixLoader));
    //   return Object.keys(this.enabledPrefixLoader).length === 0;
    // },
    // currentPrefix
    prefix() {
      return this.$route.query.prefix || '/';
    },
    iconForCopy() {
      return this.isLinkCopy;
    },
    objects() {
      return this.tariff.objects.filter(x => x.uniqueName);
    },
    // stateKey() {
    //   return `dt-state-local-${this.storageid}`;
    // },
    isPublic() {
      return this.tariff.policy.IsPublic;
    },

    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
    bucketPolicyStatus() {
      const bucketPolicy = this.tariff.policyList;
      console.log(bucketPolicy);
      return bucketPolicy;
    },
    // isSelected() {
    //   return !!Object.keys(this.selectedItems) && Object.keys(this.selectedItems).length > 0;
    // },
    // onlySelectedItems() {
    //   // this.actuallySelectedFiles
    //   const keys = Object.keys(this.selectedItems);
    //   if (!keys) return null;
    //   else {
    //     const names = keys.filter(key => this.selectedItems[key].checked);
    //     return names.map(x => {
    //       const type = this.objects.find(item => x === item.name);
    //       const obj = {
    //         name: x,
    //         type: !type ? 'folder' : 'file',
    //       };
    //       // console.log('onlySelectedItems', obj);
    //       return obj;
    //     });
    //   }
    // },
    // actuallySelectedFiles() {
    //   // console.log(
    //   //   'objects',
    //   //   this.objects.map(x => x.name)
    //   // );
    //   // console.log(
    //   //   'actuallySelectedFiles',
    //   //   this.onlySelectedItems.map(x => x.name)
    //   // );
    //
    //   return this.objects
    //     .map(x => x.name)
    //     .filter(value => this.onlySelectedItems.map(x => x.name).includes(value)).length;
    // },

    // isBufferUse() {
    //   if (!this.s3bufferStorage) return null;
    //   else
    //     return !!Object.keys(this.s3bufferStorage) && Object.keys(this.s3bufferStorage).length > 0;
    // },
    // lengthBufferStorage() {
    //   return this.isBufferUse ? Object.keys(this.s3bufferStorage.items).length : null;
    // },
    // authOp() {
    //   return localStorage.getItem('stack');
    // },
  },
  watch: {
    // enabledPrefixLoader: {
    //   handler: function (event) {
    //     console.log('enabledPrefixLoader', event);
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    prefix(event) {
      // console.log(event);
      if (event) this.updateStorageInfo(event);
      else this.updateStorageInfo();
    },
    // rowPage(event) {
    //   console.log('rowPage', event);
    // },
    isSuccessDeleted: {
      handler: async function (event) {
        if (event) {
          this.$toast.add({
            severity: 'success',
            summary: 'Объект удалён',
            detail: this.currentObject,
            life: 5000,
          });
          this.currentObject = '';
          this.isSuccessDeleted = false;
          await this.updateStorageInfo(this.prefix);
        }
      },
    },
    // nodeSelect: {
    //   handler: function (event) {
    //     // console.log('-----nodeSelect-----', event.key, event.parent_id);
    //     let newKey;
    //     if (event.parent_id && event.key) {
    //       newKey = event.key.split('/');
    //       newKey.pop();
    //       newKey.join('/');
    //     }
    //     // const key = event.parent_id ? event.parent_id.replaceAll('-', '/') : null;
    //     const key = event.parent_id ? newKey.join('/') : null;
    //
    //     if (key) {
    //       // console.log(key);
    //       const resultArray = [];
    //       key
    //         .split('/')
    //         .forEach((number, index) =>
    //           resultArray.push((resultArray[index - 1] || '') + '/' + number)
    //         );
    //       const result = resultArray.map(x => x.slice(1));
    //       // console.log(this.selectedItems);
    //       // console.log(result);
    //
    //       if (result)
    //         result.forEach(item => {
    //           // console.log(this.folders.filter(x => x.type === 'object').map(x => x.name));
    //           if (
    //             this.folders
    //               .filter(x => x.type === 'object')
    //               .map(x => x.name)
    //               .includes(item)
    //           ) {
    //             // console.log(item);
    //             this.selectedItems[item].checked = false;
    //             this.selectedItems[item].partialChecked = true;
    //           }
    //         });
    //     }
    //   },
    //   deep: true,
    // },
    // download: {
    //   handler: function (event) {
    //     console.log('this.download', event);
    //   },
    //   immediate: true,
    // },
    screenWidth: function () {
      this.setIsMobile();
    },
    // rowPage: {
    //   handler: function (event) {
    //   },
    //   immediate: true,
    // },
    progress(event) {
      if (event) {
        this.$store.state.moduleStack.progressBar = event;
      }
      if (event === this.$store.state.moduleStack.totalSize) {
        setTimeout(() => {
          this.$store.state.moduleStack.progressBar = 0;
          this.$store.state.moduleStack.totalSize = 0;
        }, 500);
      }
    },
    iconForCopy: {
      handler: function (event) {
        // console.log('iconForCopy', event);
      },
      deep: true,
      immediate: true,
    },
    objects: {
      handler: function (event) {
        if (event) {
          // console.log(event);
          this.folders = [];
          this.folders.push({ name: '/', type: 'object' });
          event.forEach(node => {
            const name = node.prefix;
            const isFolder = node.isFolder;
            if (isFolder) {
              this.folders.push({ name: name, type: 'object' });
            } // else this.isLinkCopy[node.key] = 'noclick';
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => this.setIsMobile());
    this.collapseAll();
    this.enabledPrefixLoader = false;
    // console.log('префикс', this.prefix);
    // console.log('route query', this.$route?.query?.prefix);
    // this.updateStorageInfo(this.prefix);
    this.updateStorageInfo(this.$route?.query?.prefix);
    this.firstPage = this.$route?.query?.count ? +this.$route.query.count : this.firstPage;
    this.rowPage = this.$route?.query?.object ? +this.$route.query.object : this.rowPage;
    const event = {
      first: this.firstPage,
      rows: this.rowPage,
    };
    const list = this.$refs.objects;
    list.d_rows = this.rowPage;
    list.d_first = this.firstPage;
    this.onPage(event);
  },

  beforeDestroy() {
    this.collapseAll();
  },
  methods: {
    getS3BucketList() {
      return this.$store.dispatch('moduleStack/getS3BucketList', {
        id: this.id,
      });
    },
    async sendUrl(item) {
      const config = {
        region: 'ru-msk',
        endpoint: 'https://s3.rusonyxcloud.ru',
        credentials: {
          accessKeyId: this.s3key.access,
          secretAccessKey: this.s3key.secret,
        },
      };
      const client = await new S3Client(config);
      const input = {
        Bucket: this.storageid,
        Key: item,
      };
      return await this.getSignedDownloadUrl(client, input);
    },
    async getSignedDownloadUrl(client, input) {
      let command = new PutObjectCommand(input);
      return await getSignedUrl(client, command, { expiresIn: 3600 });
    },
    async updateStorageInfo(prefix) {
      // console.log('updateStorageInfo', prefix);
      this.loader = true;
      const params = {
        storage: this.storageid,
        delimiter: '/',
      };
      if (prefix) params.prefix = prefix;
      // console.log('params', params);
      await this.$store
        .dispatch('moduleStack/fetchCurrentStorages', params)
        .then(async () => await this.getS3BucketList())
        .finally(() => {
          this.loader = false;
        });
    },
    view(event) {
      // console.log('before---------------------------------upload', event);
    },
    onPage(event) {
      this.rowPage = event.rows;
      this.firstPage = event.first;
      this.$router
        .push({
          name: 'containerObjects',
          query: {
            object: event.rows,
            count: event.first,
            prefix: this.prefix ? this.prefix : '',
          },
        })
        .catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            this.showError(err);
          }
        });
    },
    onSort() {
      this.$router
        .push({
          name: 'containerObjects',
          query: {
            object: this.rowPage,
            count: 0,
            prefix: this.prefix ? this.prefix : '',
          },
        })
        .catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            this.showError(err);
          }
        });
    },
    clearFile() {
      this.fileName = '';
    },
    async select(event) {
      this.file = event.files[0];
      this.totalSize = this.file.size;
      this.fileName = event && event.files && event.files.length ? event.files[0].name : '';
      if (this.prefixFolderForUploadInFolder) {
        this.fileName = this.prefixFolderForUploadInFolder + this.fileName;
        await this.myUploader();
      }
      let findNameInArray = !!this.objects.find(x => x.name === this.fileName);
      if (findNameInArray) {
        this.showResModal(
          'Объект с таким именем уже существует. <br> При загрузке файл обновит текущий объект.'
        );
      }
    },
    progressLoad(event) {},
    size(item) {
      if (item && item.size) return objectSizeParser(item.size);
    },
    showResModal(res) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'html', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        // centered: true,
        confirm: {
          props: { title: this.$t('confirm') },
          on: {
            click: async () => {
              this.$modals.close();
              if (this.prefixFolderForUploadInFolder) await this.myUploader();
            },
          },
        },
        cancel: {
          props: {
            title: 'Отменить',
            loading: false,
          },
          on: {
            click: () => {
              this.$modals.close();
              this.$refs.upload.clear();
            },
          },
        },
      });
    },
    async myUploader(event) {
      this.loader = true;
      this.$store.state.moduleStack.totalSize = 0;
      this.$store.state.moduleStack.progressBar = 0;
      const prefix = event
        ? this.prefix.startsWith('/')
          ? this.prefix.slice(1)
          : this.prefix
        : this.prefixFolderForUploadInFolder;

      let formData = new FormData();
      this.$store.state.moduleStack.totalSize = this.totalSize;
      const nameWithPrefix = event
        ? prefix
          ? prefix + this.fileName
          : this.fileName
        : this.fileName;
      formData.append(nameWithPrefix, this.file);
      const url = await this.sendUrl(nameWithPrefix);
      const params = {
        url: url,
        storage: this.storageid,
        file: this.file,
        // name: nameWithPrefix,
        name: this.fileName,
      };
      // console.log(params);
      await this.onUpload(params)
        .then(async data => {
          if (data === 200) {
            if (this.prefixFolderForUploadInFolder)
              await this.$refs.prefixSize.onChange(this.prefixFolderForUploadInFolder);
            this.$toast.add({
              severity: 'success',
              summary: 'Объект загружен',
              detail: this.file.name,
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Ошибка',
              detail: this.file.name,
              life: 3000,
            });
          }
        })
        .finally(() => {
          // console.log('finally');
          this.isFileUploadInFolder = false;
          this.progress = 0;
          this.fileName = '';
          // await this.$refs.prefixSize.

          this.prefixFolderForUploadInFolder = null;
          this.file = [];
          if (this.$refs?.upload) this.$refs.upload.clear();
          this.collapseAll();
          this.loader = false;
        });
    },
    viewSelected(item) {
      if (item) return Object.keys(item).length;
      else return 0;
    },
    created(i) {
      if (i && i.date) return format(i.date, 'dd.MM.yyyy, HH:mm');
    },
    async deleteObject(item) {
      const params = {
        storage: this.tariff,
        name: item,
        id: this.id,
      };
      this.currentObject = item;
      return await this.deleteObjects(params)
        // return await this.deleteObjects(this.tariff, item)
        .catch(e => {
          const err = {
            statusCode: e.statusCode || 400,
            body: e.message || JSON.stringify(e.message),
          };
          const msgError =
            err.body === 'AccessDenied'
              ? 'Недостаточно прав доступа для выполнения операции.'
              : err.body;
          if (!this.modal) this.makeModal();
          Vue.set(this.modal, 'component', null);
          Vue.set(this.modal, 'closable', true);
          Vue.set(this.modal, 'text', msgError);
          Vue.set(this.modal, 'footer', {
            centered: true,
            confirm: {
              props: { title: this.$t('confirm') },
              on: {
                click: async () => {
                  this.$modals.close();
                },
              },
            },
          });
          if (this.modal.footer.confirm) {
            Vue.set(this.modal.footer, 'cancel', false);
          }
        })
        .finally(async () => await this.updateStorageInfo(this.prefix));
    },
    // copyLink(item) {
    //   const url = `https://s3.rusonyxcloud.ru/${this.storageid}/${item}`;
    //   this.$clipboard(url);
    //   this.showSuccessModal('Ссылка для скачивания успешно скопирована в буфер обмена.');
    // },
    // copyCurrentLink(item) {
    //   const url = `https://s3.rusonyxcloud.ru/${this.storageid}/${item}`;
    //   this.$nextTick(() => {
    //     setTimeout(() => (this.isLinkCopy[item] = 'loading'), 100);
    //     this.$clipboard(url);
    //     setTimeout(() => (this.isLinkCopy[item] = 'checked'), 300);
    //   });
    //   // this.$nextTick(() => (this.isLinkCopy[item] = 'checked'));
    //   // this.isLinkCopy[item] = true;
    // },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'medium',
        text,
      });
    },
    async onRowExpand(event) {
      // console.log(event);
      const id = event?.data?.key ? event?.data?.key : event;
      this.isLinkCopy = {};
      this.isLinkCopy[id] = 'copy';
      if (event?.data?.isFolder) {
        this.prefixForPutFileInFolder = event.data.prefix;
      }
      // console.log('onRowExpand', this.isLinkCopy[id]);
      await this.getObjectHead(id);
      if (!event?.data?.isPrefix) {
        const acl = await this.getObjectACL(id); //.catch(err => 'err');
      }
    },
    onRowCollapse(event) {},
    expandAll() {
      const list = this.$refs.objects;
      list.d_rows = 25;
      list.d_first = 75;
    },
    collapseAll() {
      this.expandedRows = null;
      this.enabledPrefixLoader = false;
    },
    async getObjectACL(item) {
      const params = {
        bucket: this.storageid,
        name: item,
        key: this.s3key,
      };
      return await this.$store.dispatch('moduleStack/getObjectACL', params);
    },
    async getObjectHead(item) {
      const params = {
        bucket: this.storageid,
        name: item,
        key: this.s3key,
      };
      // console.log(params);
      return await this.$store.dispatch('moduleStack/getObjectHead', params);
    },
    async putFileInFolder(item) {
      // console.log(item);
      this.isFileUploadInFolder = true;
      this.prefixFolderForUploadInFolder = item;
      this.$refs.upload.onBasicUploaderClick();
      // this.$refs.upload.onFileSelect();
      // console.log(this.$refs.upload);
      // console.log(this.$refs.upload.isFileSelected());
    },
    async viewInfo(item) {
      let url;
      if (item.isShared) {
        url = `https://s3.rusonyxcloud.ru/${this.storageid}/${item.key}`;
      } else {
        const config = {
          region: 'ru-msk',
          endpoint: 'https://s3.rusonyxcloud.ru',
          credentials: {
            accessKeyId: this.s3key.access,
            secretAccessKey: this.s3key.secret,
          },
        };
        const client = await new S3Client(config);

        const input = {
          Bucket: this.storageid,
          Key: item.key,
        };

        async function getSignedDownloadUrl() {
          let command = new GetObjectCommand(input);
          return await getSignedUrl(client, command, { expiresIn: 3600 });
        }

        url = await getSignedDownloadUrl();
      }
      window.open(url, '_blanc');
      // const name = item.key.includes('/') ? item.key.split('/').at(-1) : item.key;
    },
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
    showLoadingModal(props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'text', props);
      Vue.set(this.modal, 'title', props);
      Vue.set(this.modal, 'component', BaseLoader);
      Vue.set(this.modal, 'closable', false);
      Vue.set(this.modal, 'footer', false);
    },
    onStorageClick() {
      if (this.prefix.length > 1) {
        this.collapseAll();
        this.$router.push({
          name: 'containerObjects',
          query: {
            object: this.rowPage,
            count: 0,
          },
        });
      }
    },
    onFolderClick: function (folder) {
      // console.log('onFolderClick', folder);
      this.collapseAll();
      this.$router.push({
        name: 'containerObjects',
        query: {
          prefix: folder,
          object: this.rowPage,
          count: 0,
        },
      });
    },
    setIcon(event) {
      // console.log(event);
      this.isLinkCopy = {};
      this.isLinkCopy[event.name] = event.icon;
      // console.log(this.isLinkCopy[event.name]);
      // console.log(this.isLinkCopy);
    },
    disablePrefixLoader(event) {
      // console.log('disablePrefixLoader', event);

      if (!event) {
        this.enabledPrefixLoader = false;
        this.isDisabledButtons = false;
        this.loader = false;
      } else {
        this.enabledPrefixLoader = true;
        this.isDisabledButtons = true;
        this.loader = true;
      }
    },
    loadPrefixSize(event) {
      // console.log('click ', event);
    },
    // showIconCopy(item) {
    //   console.log(item);
    //   console.log(this.isLinkCopy[item] !== 'noclick' ? 'accept' : 'copy-gray');
    //   return this.isLinkCopy[item] !== 'noclick' ? 'accept' : 'copy-gray';
    // },
    //
    async onUpload(payload) {
      const params = {
        onUploadProgress: progressEvent => {
          this.progress = progressEvent.loaded;
        },
        ...payload,
      };
      return this.$store
        .dispatch('moduleStack/sendFile', params)
        .then(async data => {
          // console.log(data);
          await this.updateStorageInfo(this.prefix);
          // await this.getS3BucketList();
          return data;
        })
        .catch(e => {
          console.log(e);
          let error;
          error =
            e.response.data.Code === 'QuotaExceeded'
              ? 'Превышена квота по количеству объектов. Пожалуйста увеличьте квоту или обратитесь в техподдержку.'
              : 'Произошла ошибка, попробуйте повторить позднее, либо обратитесь в техподдержку';
          this.showError(error);
          return 'error';
        });
    },
    async updateStorage() {
      await this.getCurrentStorage();
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "addFolder": "Добавить префикс",
      "add_file": "Загрузить файлы",
      "object": " Выделено 0 объектов | Выделен {n} объект | Выделены {n} объекта | Выделено {n} объектов",
      "buffer": "0 объектов | {n} объект | {n} объекта | {n} объектов",
      "new": "Создать папку",
      "select": "Выбрать",
      "FULL_CONTROL": "Полный доступ ",
      "READ": "Чтение ",
      "READ_ACP": "Чтение ACL ",
      "sure": {
        "create": "Подтвердить",
        "successLink": "Ссылка сгенерирована и скопирована в буфер обмена."
      },
      "WRITE": "Запись ",
      "WRITE_ACP": "Запись ACL ",
      "delete": "Удалить объекты",
      "deselect": "Очистить",
      "tableHead": {
        "name": "Файл",
        "size": "Размер",
        "date": "Дата создания",
        "shared": "Публичная",
        "config": "Конфигурация",
        "conditions": "",
        "status": "Статус",
        "state": "Состояние",
        "port": "Порты",
        "menu": "Меню"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;

.action-buttons {
  display: flex;
  //flex-direction: column
}

.container-file {
    display flex
    justify-content: space-between
    align-items: baseline
    flex-direction: row;

    +breakpoint(xs-only) {
      flex-direction: column
    }

  &-info{
    width: 80%
    display: flex;
    align-items: baseline
    justify-content: space-between
    flex-direction: column;
    gap: 0.75rem;

  +breakpoint(ml-and-down) {
    flex-direction: column
  }
    &__header {

    }
  &__view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 1rem;
    width: 100%;
    &__el {
      display: flex;
      width: 100%
      flex-direction: row;
      align-content: flex-start;
      justify-content: space-between;
      align-items: baseline;


      & + & {
        margin-bottom: 0.5rem
        margin-top: 0.5rem
      }

    }
  }
    &__el {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: space-evenly;
      align-items: baseline;
      gap: 0.25rem;

      & + & {
        margin-bottom: 0.5rem
        margin-top: 0.5rem
      }
    }
  &__permission {
      //margin: auto 0.5rem
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: space-evenly;
      align-items: baseline;
  }
  }
}

.container-folder {
  display: flex;
  justify-content: flex-end;
  align-items: baseline
  flex-direction: row;

  +breakpoint(xs-only) {
    flex-direction: column
  }

  &-info{
    width: 80%
    display: flex;
    align-items: baseline
    justify-content: space-between
    flex-direction: row;

    +breakpoint(ml-and-down) {
      flex-direction column
    }

    &__el {
      //margin: auto 1rem
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: space-evenly;
      align-items: baseline;

      & + & {
        margin-bottom: 0.5rem
        margin-top: 0.5rem
      }

    }
    &__permission {
      //margin: auto 0.5rem
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: space-evenly;
      align-items: baseline;
    }
  }
}

.time {
  width: 10rem;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem

  &__select {
    width: 100%;
    margin: 1rem 0.65rem 0.5rem 0.65rem;

    //margin: auto 1rem auto 0.65rem;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    &-selected {
      margin-left: 1rem;
    }

    &-buttons {

      &__btn {
        margin: auto 0.5rem;
      }
    }

  }
}
.field-checkbox{
  display flex
}
.folder-link {
  position: relative;
  width: 100%
  color: $primary-color;
  text-decoration: none;
  white-space: nowrap;
  //background: var(--main-card-bg);
}
.time-buttons {
  width: 3rem
  min-width: 3rem;
}
.header-bottom{
  display flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: flex-start;


  &__start {
    margin auto 1rem
    min-width: 10rem
  }
  &__second {
    margin: auto 1rem
    min-width: 10rem
    max-width: 15rem
    display flex
    flex-direction: row;

  }
  &__progress {
    margin: auto 1rem
    width: 100%
    text-align: end;
  }
}
.buttons-context{
    display flex;
    flex-direction column;
    justify-content flex-end;
  align-items: flex-end;
  }
.p-datatable-header-up{
  background: #fff;
  border: 0;
}
.datatable-footer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 1rem;
}
.filter{
  display: flex;
  justify-content: space-between;
  //justify-content: flex-end;
  margin-bottom: 0.75rem;
      flex-wrap:wrap;
  align-items: center;
    +breakpoint(ms-and-down) {
    }
  &-upload{
    margin-right: 2rem
    display: flex;
    flex-direction: row;
    //width: 10%
    //order: 1;
    +breakpoint(ml-and-down) {
    order: 1;
    }
    &__btn{
      margin-left: 1rem;

    }
    & + & {
      margin-left: 2rem
    }

  }
  &-progress {
    margin-right: 2rem;
    flex-grow: 2
    //width: 50%
    max-width: 700px
    //order: 2;
    //+breakpoint(ml-only) {
    //  margin-top: 0.5rem;
    //  width: 100%
    //  order: 3;
    //}
    +breakpoint(ml-and-down) {
      margin-top: 0.5rem;
      width: 100%
      order: 3;
    }
  }

  &-search{
    +breakpoint(ml-and-down) {
      margin-top: 0.5rem;
      order: 2;
    }
    //+breakpoint(ms-and-down) {
    //  order: 3;
      //margin-top: 0.5rem;
      //order: 2;
      //width: 45%
    //}
  }
  & + & {
    margin-left: 2rem
  }
  }
.p-inputnumber-input{
  min-width: 4rem
  text-align center
}
</style>
