<template>
  <main-card class="tariff-card">
    <template #header>
      <router-link
        v-if="![1, 4, 5].includes(tariff.status.code)"
        :to="{ name: 'vpsDetail', params: { id: tariff.id } }"
        class="tariff-card__title-high big-title"
      >
        {{ `${$t('text')} #${tariff.id}` }}
      </router-link>
      <div v-else class="tariff-card__title big-title">
        {{ `${$t('text')} #${tariff.id}` }}
      </div>
      <div class="tariff-card__ip-block">
        <div class="tariff-card__ip">
          {{ tariff.title }}
        </div>
        <div class="tariff-card__ip note-text">
          {{ tariff.ip }}
        </div>
      </div>
    </template>
    <template #headerAction>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        v-tooltip="$t('menu')"
        placement="bottom-end"
        :popper-options="popperOptions"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-card__popover"
      >
        <plain-button icon="more" class="tariff-card__more" @click="togglePopoverState" />
        <template #popover>
          <vps-context-menu
            v-if="isPopoverOpen"
            :tariff="tariff"
            class="tariff-card__context-menu"
          />
        </template>
      </v-popover>
    </template>
    <template #headerEnd>
      <main-label :color="tariff.status.color" class="tariff-card__label">
        {{ $t(`status.${tariff.status.key}`) }}
      </main-label>
      <main-label
        v-if="tariff.status.code === 2 && !!serverState"
        :color="tariff.serverState.color"
        class="tariff-card__label"
        style="margin-left: 0.5rem"
      >
        {{ $t(`status.${serverState.key}`) }}
      </main-label>
    </template>
    <div class="tariff-card__content">
      <div class="tariff-card__title medium-title">
        {{ `Тариф: ${tariff.pricelist}` }}
      </div>
      <div class="tariff-card__activity">
        <plain-button
          v-if="tariff.promise !== 'null'"
          v-tooltip="{
            content: promiseOn,
            autoHide: false,
            placement: 'top-start',
            container: false,
          }"
          icon="promise-icon"
          class="allowed-user-rights__promise"
        />
        <div class="tariff-card__note note-text">
          {{ tariff.autoprolong_period !== 'null' ? textProlongOn : textProlongOff }}
        </div>
        <div class="tariff-card__activity-icon">
          <plain-button
            v-if="isPast && tariff.status.code === 1 && tariff.status.key !== 'ordered'"
            v-tooltip="{
              content: $t('wasActiveUnderStatusOff'),
            }"
            color="del"
            icon="warn"
            class="allowed-user-rights__warn"
          />
          <plain-button
            v-if="isPastAndNeedBalance && tariff.status.code === 2"
            v-tooltip="{
              content: $t('needUpBalance'),
            }"
            color="primary"
            icon="warn"
            class="allowed-user-rights__warn"
          />
        </div>
        <div class="tariff-card__note">
          {{ $t('autoprolong') }}
        </div>
        <div class="tariff-card__activity-actions">
          <vps-autoprolong
            v-tooltip="{
              content: isAutoProlong,
              autoHide: false,
              placement: 'top-end',
            }"
            :tariff="tariff"
            class="tariff-card__autoprolong"
          />
        </div>
      </div>
      <div class="tariff-card__content">
        <div class="tariff-card__activity">
          <plain-button
            v-if="hasProlongTool"
            color="primary"
            class="tariff-card__prolong"
            @click="costAutoProlong === '0.00' ? singleProlongFree(tariff) : singleProlong(tariff)"
          >
            {{ $t('prolong.action') }}
          </plain-button>
          <plain-button
            v-if="licType !== 'none'"
            color="primary"
            :loading="hidePanelButton"
            class="tariff-card__prolong"
            @click="goToPleskPanel"
          >
            {{
              $t('panel', {
                name: licType === 'ISP' ? 'ispmanager' : licType,
              })
            }}
          </plain-button>
        </div>
      </div>
    </div>
    <template #footer>
      <tariff-spec :os="cachedOsName.name ? cachedOsName : osName" v-bind="tariff.specs" />
    </template>
  </main-card>
</template>

<script>
import prolongText from '@/mixins/prolongText';
import MainCard from '@/components/MainCard/MainCard.vue';
import MainLabel from '@/components/Labels/MainLabel.vue';
import TariffSpec from './TariffSpec';
import VpsProlongSingle from '../../../components/VpsProlongSingle.vue';
import VpsContextMenu from '../../../components/VpsContextMenu';
import VpsAutoprolong from '../../../components/VpsAutoprolong';
import prolongSingle from '@/mixins/billmgr/prolongSingle';
import prolongSingleFree from '@/mixins/billmgr/prolongSingleFree';
import popoverFix from '@/mixins/popoverFix';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import storeMixin from '@/layouts/VPS/mixins';
import Vue from 'vue';
import Link from '@/components/Configurator/components/Link';
import updateStatus from '@/layouts/VPS/mixins/updateStatus';
import redirectLink from '@/mixins/redirectLink';
import updateServerInfo from '@/layouts/VPS/mixins/updateServerInfo';
export default {
  name: 'TariffCard',
  components: { MainCard, MainLabel, TariffSpec, VpsContextMenu, VpsAutoprolong },
  mixins: [
    prolongSingle,
    prolongSingleFree,
    popoverFix,
    prolongText,
    updateStatus,
    updateServerInfo,
    redirectLink,
  ],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      timerId: '',
      cachedOsName: {},
      isStartUpdateTariff: false,
      timerCnt: 0,
      popperOptions: {
        modifiers: {
          flip: {
            padding: { top: 60 },
          },
        },
      },
      disabledPlesk: false,
      hidePanelButton: false,
      state: '',
      period: [],
      costAutoProlong: 0,
      moduleMain: 'moduleVPS',
      singleProlongComponentFirst: VpsProlongSingle,
      singleProlongComponent: VpsProlongSingle,
      pleskBase: 'https://89.253.238.44:8443/smb/web/setup',
    };
  },
  computed: {
    tariff() {
      return this.$store.state.moduleVPS.list.find(x => x.id === this.id);
    },
    provider() {
      return (
        this.$store.getters['moduleProviders/current'] ||
        this.$store.getters['moduleProviders/provider']
      );
    },
    hasPanel() {
      return this.tariff.hasPanel;
    },
    licType() {
      if (
        this.tariff &&
        this.tariff.status.code === 2 &&
        this.tariff.addons.length > 0 &&
        this.tariff.addons &&
        this.tariff.addons.filter(x => x.type).find(x => x.type !== 'none')
      ) {
        return this.tariff.addons.find(x => x.type && x.type !== 'none').type || 'none';
      } else return 'none';
    },
    cost() {
      return this.tariff.cost;
    },
    isPast() {
      return this.tariff.expiredate < Date.now();
    },
    tools() {
      return this.$store.state.moduleVPS.tools;
    },
    hasProlongTool() {
      return (
        this.tariff.prolongAvailable &&
        !!this.tools &&
        !!this.tools.prolong &&
        this.tools.prolong.isEnable(this.tariff)
      );
    },
    isAutoProlong() {
      return this.tariff.autoprolong_period !== 'null'
        ? 'Автопродление включено'
        : 'Включить автопродление';
    },
    isPleskPanel() {
      return this.tariff.isPlesk;
    },
    serverId() {
      return this.tariff.serverId;
    },
    serverState() {
      return this.tariff.serverState;
    },
    vmKey() {
      return this.$store.state.moduleVPS.key;
    },
    osName() {
      return this.tariff.osName;
    },
  },
  watch: {
    osName(event) {
      if (event.name) this.cachedOsName = Object.assign(this.cachedOsName, event);
    },
    serverId(event) {
      if (event && this.vmKey) {
        this.fetchServerInfo().then(data => {
          this.state = data['State'];
          if (data) this.setServerInfo();
        });
      } else this.$store.dispatch('moduleVPS/fetchDetail', { id: this.tariff.id });
    },
    'serverState.key': function (event) {
      if (event === 'running') {
        this.hidePanelButton = false;
        this.disabledPlesk = false;

        this.setServerInfo();
      } else if (event === 'processing') {
        this.disabledPlesk = false;
        this.hidePanelButton = true;
      } else if (event === 'stopped') {
        this.disabledPlesk = true;
        this.hidePanelButton = false;
      }
    },
    'tariff.in_pay': {
      handler: function (event) {},
    },
    'tariff.status': {
      handler: async function (event) {
        if ([1, 4, 5].includes(event.code)) {
          await this.updateVpsStatus(this.tariff.id);
        }
      },
      deep: true,
      immediate: true,
    },
    vmKey: {
      handler: function (event) {
        if (event && this.serverId) {
          this.updateServerInfo();
          // this.updateServerInfo().then(data => {
          //   console.log(data);
          //   console.log(this.tariff.serverInfo);
          //   console.log(this.tariff.osName);
          // });
        }
      },
      immediate: true,
    },
    isPleskPanel: {
      handler: function (event) {
        if (event && this.serverId && this.vmKey) this.updateServerInfo();
      },
      immediate: true,
    },
    licType: {
      handler: function (event) {
        if (event) this.tariff.isPlesk = true;
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch('moduleVPS/fetchDetail', { id: this.tariff.id });
    this.$nextTick(() => {
      this.popperOptions.modifiers.flip.boundary = this.$parent.$el;
    });
    if (this.$parent.$children.findIndex(i => i === this) < 2) {
      this.popperOptions.modifiers.flip.enabled = false;
    }
    if (this.hasProlongTool && this.tariff.status.code !== 3) this.getCost();
    if (this.serverId && this.vmKey) this.updateServerInfo();
  },
  beforeDestroy() {
    if (this.isStartUpdateTariff) {
      this.$store.dispatch('moduleVPS/fetchDetail', { id: this.tariff.id }).then(() => {
        clearTimeout(this.timerId);
        this.isStartUpdateTariff = false;
        this.timerCnt = 0;
      });
    }
  },
  methods: {
    goToPleskPanel() {
      let redirectLink = window.open();
      let modal = null;
      this.$modals.open({
        name: 'ToPleskPanel',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      this.$store
        .dispatch('moduleVPS/fetchBillMgrToolAction', {
          func: 'panel_button',
          id: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            this.$modals.close();
            if (redirectLink) redirectLink.location.href = data.ok.v;
            else this.showModal(data.ok.v);
          } else {
            this.$modals.close();
            throw new Error('Произошла ошибка. Пожалуйста, обратитесь в поддержку.');
          }
        })
        .catch(e => {
          this.$modals.close();
          this.showError(e);
        });
    },
    fetchServerInfo() {
      return this.$store.dispatch('moduleVPS/fetchServerInfo', { serverId: this.serverId });
    },
    setServerInfo() {
      const params = {
        serverId: this.serverId,
        id: this.tariff.id,
      };
      this.$store.dispatch('moduleVPS/setServerInfo', params);
    },
  },
};
</script>
<i18n>
  {
    "ru": {
      "status": {
        "stopped": "Выключен",
        "suspended": "Неактивен",
        "running": "Включён"
      },
      "menu": "Меню",
      "text": "Подписка номер",
      "promise" : "Для услуги активирован обещанный платеж до {promise}",
      "isActiveUnder": "Действует до {date}",
      "isActiveUnderOn": "{date} продлится на {prolong} за {cost} ₽",
      "isActiveUnderOff": "Истекает {date}, {cost}  ₽ / месяц",
      "isActiveUnderProlongOff": "Истекает {date}, {cost}  ₽ / месяц",
      "wasActiveUnder": "Действовал до {date}",
      "wasActiveUnderStatusOff": "В расчетную дату на Вашем балансе было не достаточно средств, для автопродления услуги на выбранный период автопродления",
      "wasActiveUnderOff": "Истекает {date}",
      "needUpBalance": "Текущего баланса недостаточно для автоматического продления в расчётную дату, пополните баланс или настройте автоматическое списание с карты.",
      "panel": " Панель {name}",
      "autoprolong": "автопродление",
      "prolong": {
        "title": "Продление тарифа",
        "free": "Активировать",
        "cancel": "Отмена",
        "error": "К сожалению, что-то пошло не так",
        "success_basket": "Продление тарифа успешно добавлено в корзину",
        "success_order": "Продление тарифа прошло успешно. Данные скоро обновятся.",
        "confirm": "Оплатить",
        "order": "В корзину",
        "action": "Продлить",
        "on": "Включить автопродление",
        "off": "Автопродление включено"
      }
    }
  }
</i18n>
<!--// "error": "Не удалось перейти в плеск панель. Возможно у Вас выключен сервер. Попробуйте-->
<!--обратиться в техподдержку",-->

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.allowed-user-rights{
  &__promise{
    margin-right: 1px;
  }
}
.tariff-card {
  min-height: 100%;

  &__title {
    text-decoration: none;
    color: inherit;

    &-high {
      text-decoration: none;
      color: $primary-color;

      &:hover {
        color: darken($primary-color, 20%);
      }
    }
  }
  &__ip-block {
    //flexy(space-between);
    flexy(flex-start);
    flex-wrap: wrap;
  }

  &__ip {
    margin-top: 2px;
    margin-right: 10px;
    +breakpoint(sm-and-up) {
      margin-top: 10px;
    }
  }
  &__label {
    +breakpoint(sm-and-up) {
      margin-top: 0.25rem;

      @media screen and (min-width: 1190px) {
        flex: 0 5 auto;
      }
    }
  }
  &__note {
    margin-right: 0.75rem;
  }
  &__more {
    display: block;
    margin-right: -0.5rem;

    +breakpoint(sm-and-up) {
      margin-right: -1rem;
      margin-top: -2px;
    }
  }
  &__activity {
    margin-top: 1.5rem;
    +breakpoint(sm-and-up) {
      //flexy(flex-start, baseline);
      flexy(flex-start, center);
    }
    &-icon{
      margin-top: 0.5rem !important;
      margin-right: 0.75rem;
    }
    &-actions {
      flexy(flex-start, baseline);
      //margin-top: 0.75rem;

      +breakpoint(sm-and-up) {
        //margin-left: 1.5rem;
      }
    }
  }
  &__autoprolong {
    align-self: center;

    //& + ^[0]__prolong {
    //  margin-left: 1.5rem;
    //}
  }
  &__prolong {
    //margin-top : 0.5rem;
    margin-right: 1.5rem !important;
  }

  &__context-menu {
    max-width: 100%;
    width: calc(100vw - 3.25rem);

    +breakpoint(sm-and-up) {
      width: 260px;
    }
  }
}
</style>
