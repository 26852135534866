<template>
  <div class="tariffs-table">
    <base-table
      :columns="tableHead"
      :list="paginList"
      :sort="sort"
      class="tariffs-table__table"
      @sort-change="onSortChange"
    >
      <tariffs-table-firewall-row
        v-for="item in paginList"
        :key="item.id"
        :columns="tableHead"
        :item="item"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
    <NewPagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="base"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTableFirewallRow from './TariffsTableFirewallRow';
import NewPagination from '@/components/Pagination/NewPagination';
import pagination from '@/mixins/pagination';

export default {
  name: 'TariffsTableFirewall',
  components: {
    BaseTable,
    TariffsTableFirewallRow,
    NewPagination,
  },
  mixins: [pagination],
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            minWidth: '80px',
            maxWidth: '300px',
          },
          sort: {
            prop: 'name',
            order: 'asc',
          },
        },
        {
          key: 'id',
          label: this.$t('tableHead.id'),
          style: {
            minWidth: '80px',
            maxWidth: '200px',
          },
          sort: {
            prop: 'id',
            order: 'asc',
          },
        },
        {
          key: 'shared',
          label: this.$t('tableHead.shared'),
          style: {
            minWidth: '34px',
            maxWidth: '134px',
          },
        },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            minWidth: '44px',
            maxWidth: '50px',
          },
        },
      ],
    };
  },
  computed: {
    list() {
      return this.$store.getters['moduleStack/GET_SORTED_LIST_FIREWALLS'];
    },
    sort() {
      return this.$store.state.moduleStack.sort.firewall;
    },
  },
  watch: {
    sort: {
      handler(val) {
        this.updateSort(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSort(this.sort);
  },
  methods: {
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit('moduleStack/SET_FIREWALL_SORT', { prop, order });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "name": "Имя",
      "id": " id",
      "description": "Описание",
      "shared": "Доступ",
      "config": "Конфигурация",
      "status": "Статус",
      "ip": "Внешний IP",
      "subnet": "Подсеть",
      "menu": "Меню"
    },
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
    "prolong": "Продлить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
