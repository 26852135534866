<template>
  <base-loader v-if="loader" />
  <div v-else class="time-expired">
    <!--    <label class="standart-text time-expired__row">{{ $t('title') }}</label>-->
    <!--    <div class="addons__label medium-title">-->
    <!--      {{ $t('type') }}-->
    <!--    </div>-->
    <div class="addons">
      <div class="addons__item">
        <div class="addons__label standart-text">{{ $t('container') }}</div>
        <!--        <div  class="addons__value standart-text">-->
        <!--          {{ isSharedLinced ? 'Публичный' : 'Приватный' }}-->
        <!--        </div>-->
        <!--        <div v-else class="addons__value standart-text">-->
        <!--          <div class=""></div>-->
        <div v-for="category of categories" :key="category.key" class="field-radiobutton">
          <RadioButton
            :id="category.key"
            v-model="selectedCategory"
            name="category"
            :value="category"
            class="addons__value-radio"
          />
          <label :for="category.key">{{ $t(category.name) }}</label>
        </div>
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <br />
    <!--    <div v-if="mode === 'edit'" class="addons">-->
    <!--      <div class="addons__item">-->
    <!--        <checkbox-block-->
    <!--          :hint="$t('descr')"-->
    <!--          :config="config"-->
    <!--          :label="$t('all')"-->
    <!--          @change="onChange('check', $event)"-->
    <!--        >-->
    <!--        </checkbox-block>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <label class="standart-text time-expired__row">{{ $t('text') }}</label>-->
  </div>
</template>

<script>
import InputNumber from 'primevue/inputnumber/InputNumber';
import Tooltip from 'primevue/tooltip';
import CheckboxBlock from '@/components/Configurator/components/CheckboxBlock.vue';
import RadioButton from 'primevue/radiobutton';

export default {
  name: 'EditAclFolder',
  components: {
    RadioButton,
    // CheckboxBlock,
  },
  // components: { InputNumber },
  directives: {
    tooltip: Tooltip,
  },
  // directives: {
  //   tooltip: Tooltip,
  // },
  props: {
    storage: {
      type: String,
      default: '',
    },
    folder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mode: 'edit',
      selectedCategory: null,
      config: {
        values: {
          on: true,
          off: false,
        },
        value: 'off',
        trueValue: 'on',
        falseValue: 'off',
      },
      acl: ['FULL_CONTROL', 'READ', 'WRITE', 'WRITE_ACP', 'READ_ACP'],
      formData: {},
      checkedACL: {},
      categories: [
        { name: 'private', key: 'private' },
        { name: 'public', key: 'public-read' },
      ],
      loader: true,
    };
  },
  computed: {
    s3key() {
      return this.$store.state.moduleStack.s3Keys[0];
    },
    bucketPolicyStatus() {
      const bucketPolicy = this.tariff.policyList;
      console.log(bucketPolicy);
      return bucketPolicy;
    },
    tariff() {
      return this.$store.state.moduleStack.storages.find(x => x.name === this.storage);
    },
    isSharedLinced() {
      return !!(
        this.checkedACL[this.folder] && this.checkedACL[this.folder]['READ']?.includes('all')
      );
    },
  },
  watch: {
    loader(event) {
      if (event) this.$emit('notready');
      else
        this.$emit('change', {
          category: this.selectedCategory.key,
          allKey: this.config.value === 'on',
        });
    },
    selectedCategory: {
      handler: function (event) {
        // console.log(event);
        if (event && !this.loader)
          this.$emit('change', {
            category: event.key,
            allKey: this.config.value === 'on',
          });
      },
      deep: true,
    },
    config: {
      handler: function (event) {
        // this.selectedCategory;
        // console.log(event);
        if (event && !this.loader)
          this.$emit('change', {
            category: this.selectedCategory.key,
            allKey: event.value === 'on',
          });
      },
      deep: true,
    },
  },
  beforeDestroy() {},
  mounted() {
    this.$emit('notready');
    this.getObjectACL(this.folder).then(data => {
      this.updateStorageInfo(this.folder);
      console.log(data);
    });
  },
  methods: {
    onChange(name, event) {
      this.formData[name] = event;
    },
    async getObjectACL(item) {
      const params = {
        bucket: this.storage,
        name: item,
        key: this.s3key,
      };
      await this.getObjectHead(item);
      const acl = await this.$store.dispatch('moduleStack/getObjectACL', params).catch(err => err);
    },
    async getObjectHead(item) {
      const params = {
        bucket: this.storage,
        // ExpectedBucketOwner: 'vdc368550',
        name: item,
        key: this.s3key,
      };
      // console.log(params);
      return await this.$store.dispatch('moduleStack/getObjectHead', params);
      // return await this.$store.dispatch('moduleStack/getObject', params);
    },
    async updateStorageInfo(prefix) {
      this.loader = true;
      const params = {
        storage: this.storage,
        objectForAcl: true,
      };
      if (prefix) params.prefix = prefix;
      await this.$store
        .dispatch('moduleStack/fetchCurrentStorages', params)
        .finally(() => (this.loader = false));
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "type": "Настройки конфиденциальности",
    "all": "Применить к содержимому папки",
    "container": "Доступность папки",
    "descr": "Применить ко всем вложенным объектам папки",
    "private": "Приватный",
    "public": "Публичный"

  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.field-radiobutton {
  margin-right: 1rem
}
.addons {
  &__input{
    height: 36px;
    width: 100%
  }
  &__value{
    min-width: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-radio {
      margin-right: 0.5rem
    }
  }

  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
      height: 45px;
    }

    &-btn {
      width: 130px;
      margin-top: 1rem

      & + & {
        margin-left: 2rem
      }
    }

    & + & {

      +breakpoint(sm-and-up) {
        margin-top: -0.8rem;
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 12rem;
      margin-right: 0.5rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}
</style>
