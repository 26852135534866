<template>
  <div class="vps-detail">
    <transition name="fade" mode="out-in">
      <div v-if="isCurrentLoading" class="vps-detail__loader">
        <base-loader class="vps-detail__loader-item" />
      </div>
      <base-alert
        v-else-if="!isCurrentLoading && isServerError"
        :title="$t('errors.serverError.title')"
        icon="warn"
        class="vps-info__alert"
      >
        {{ $t('errors.serverError.text') }}
      </base-alert>
      <div v-else-if="tariff && tariff.provisioning_status" class="vps-detail__inner">
        <div class="vps-detail__content">
          <div v-if="isViewHeadBalancer">
            <layout-stack-title
              :title="tariff.name"
              :specs="specsObj"
              type="balancer"
              :address="tariff.id"
              :description="tariff.description"
              :status="tariff.operating_status"
              :state="tariff.provisioning_status"
              class="vps-detail__head"
              ><template #btn>
                <v-popover
                  v-if="balancerid.length > 0"
                  placement="bottom"
                  popover-class="vps-detail__popover"
                >
                  <sidebar-button /><template #popover>
                    <balancer-context-menu
                      :tariff="tariff"
                      :floating-ip="floatingIpsServer ? floatingIpsServer.floating_ip_address : ''"
                      :is-loading="isCurrentLoading"
                      class="vps-detail__context-menu"
                  /></template> </v-popover
              ></template>
            </layout-stack-title>
            <tabs :list="navRule" class="medium-title">
              <template v-slot:item="{ item }">
                <router-link active-class="active" :to="item.to">
                  {{ item.title }}
                </router-link>
              </template>
            </tabs>
          </div>
          <page-block-stack v-if="isViewHeadBalancer" :title="' '" class="vps-detail-backup__block">
            <template #action>
              <plain-button
                v-if="newRules.length === 0 && isViewHeadBalancer"
                color="primary"
                nowrap
                @click="addRule"
              >
                {{ $t('addRule') }}
              </plain-button>
            </template>
            <transition name="slide-fade">
              <router-view
                v-if="tariff"
                :tariff="tariff"
                :subnet-id="tariff.vip_subnet_id"
                :float="floatingIpsServer.floating_ip_address || null"
              ></router-view>
            </transition>
          </page-block-stack>
          <transition v-if="!isViewHeadBalancer" name="slide-fade">
            <router-view
              v-if="tariff"
              :tariff="tariff"
              :subnet-id="tariff.vip_subnet_id"
              :float="floatingIpsServer.floating_ip_address || null"
            ></router-view>
          </transition>
          <page-block-stack>
            <div v-for="item in newRules" :key="item.id">
              <add-new-rule
                :id="currentId"
                :key="currentId"
                :subnet-id="tariff.vip_subnet_id"
                :protocol="currentListeners"
                :rule="item"
                :created="isRuleAdded"
                @click-more="$emit('click-more', item)"
                @update-rule="updateRule"
                @add-new-rule="addNewRule"
                @remove-rule="deleteRule(currentId)"
              />
            </div>
          </page-block-stack>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import LayoutStackTitle from '@/components/LayoutTitle/LayoutStackTitle.vue';
import SidebarButton from '@/components/Buttons/SidebarButton.vue';
import showErrorModal from '@/mixins/showErrorModal';
import BalancerContextMenu from '../pages/Main/components/BalancerContextMenu';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import storeMixin from '../mixins/index';
import breakpoint from '@/utils/breakpoints';
import providerChange from '@/mixins/providerChange';
import RuleHeaders from '@/layouts/Stack/pages/Main/components/RuleHeaders';
import VueScrollTo from 'vue-scrollto';
import AddNewRule from '@/layouts/Stack/pages/Main/components/AddNewRule';
import tabs from '@/components/Tabs/Tabs';
import PageBlockStack from '@/components/PageBlock/PageBlockStack';
import update from '@/layouts/Stack/mixins/update';
Vue.use(VueScrollTo);
export default {
  name: 'Balancer',
  components: {
    LayoutStackTitle,
    SidebarButton,
    PageBlockStack,
    BalancerContextMenu,
    AddNewRule,
    BaseAlert,
    tabs,
  },
  mixins: [storeMixin, providerChange, showErrorModal, update],
  props: {
    balancerid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newRules: [],
      navRule: [
        {
          title: this.$t('navRule.rule'),
          to: { name: 'containerRuleStart' },
        },
        {
          title: this.$t('navRule.view'),
          to: { name: 'BalancerView' },
        },
      ],
      isRuleAdded: false,
      isCurrentLoading: false,
      firstStep: true,
      secondStep: false,
      thirdStep: false,
      fourthStep: false,
      description: '',
      name: '',
      isUpdateBalancer: false,
      isUpdatePool: false,
      timerCnt: 0,
      poolId: '',
      listenerId: '',
      membersId: [],
      healthMonitorId: '',
      createdPool: '',
      active: 0,
      selected: 'RuleServers',
      isServerError: false,
      isMobile: true,
      protocolRules: ['TCP', 'UDP', 'HTTP', 'TERMINATED_HTTPS'],
      rule: {
        server: {
          port: 80,
        },
        balancer: {
          port: 80,
        },
      },
      tabs: [
        {
          title: this.$t('tab.servers'),
          component: 'RuleServersCreate',
          show: true,
        },
        {
          title: this.$t('tab.algorithm'),
          component: 'RuleAlgorithm',
          show: true,
        },
        {
          title: this.$t('tab.check'),
          component: 'RuleCheck',
          show: true,
        },
        {
          title: this.$t('tab.connect'),
          component: 'RuleConnect',
          show: true,
        },
        {
          title: this.$t('tab.headers'),
          component: 'RuleHeaders',
          show: false,
        },
      ],
    };
  },
  computed: {
    filtredTabs() {
      return this.tabs.filter(x => x.show);
    },
    isViewHeadBalancer() {
      // console.log('---router---', this.$router.currentRoute.name);
      // ['containerRuleStart', 'BalancerView'].includes(this.$router.currentRoute.name);
      return ['containerRuleStart', 'BalancerView'].includes(this.$router.currentRoute.name);
    },
    addMembersMonitor() {
      // console.log(
      //   !!this.listenerId,
      //   'listnener',
      //   !!this.poolId,
      //   'poolid',
      //   !!this.healthMonitorId,
      //   'health',
      //   !!this.listenerId && !!this.poolId && !!this.healthMonitorId
      // );
      return !!this.listenerId && !!this.poolId && !!this.healthMonitorId;
    },
    currentListeners() {
      const listener = [];
      this.tariff.listeners.forEach(x => {
        listener.push(this.$store.state.moduleStack.listeners.find(id => id.id === x.id));
      });
      return listener.map(x => {
        return {
          port: x.protocol_port,
          protocol: x.protocol === 'TERMINATED_HTTPS' ? 'HTTPS' : x.protocol,
        };
      });
    },

    tariff() {
      return this.$store.state.moduleStack.balancers.find(x => x.id === this.balancerid);
    },
    currentId() {
      if (
        this.tariff &&
        Object.keys(this.tariff).length > 0 &&
        this.tariff.pools &&
        this.tariff.pools.length > 0
      ) {
        return this.tariff.pools.length + 1;
      } else return 1;
    },
    ssl() {
      return this.$store.state.moduleStack.containers;
    },
    floatingIpsServer() {
      return this.$store.state.moduleStack.floatingips.find(
        x => x.port_id === this.tariff.vip_port_id
      );
    },
    specsObj() {
      const specs = this.tariff;

      let list = [];
      // if (specs.vip_address) list.push(specs.vip_address);
      // if (!!this.floatingIpsServer && Object.keys(this.floatingIpsServer))
      //   list.push(this.floatingIpsServer.floating_ip_address);
      return {
        list,
      };
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
  },
  watch: {
    screenWidth: function () {
      this.setIsMobile();
    },
    currentListeners(event) {
      // console.log('currentListeners', event);
      if (event && this.newRules[0]) {
        this.newRules[0].id = event.length + 1;
        // this.newRules[0].name = `Rule#${event.length + 1}`;
      }
    },
    listenerId: {
      handler(event) {
        console.log(event);
      },
    },
    addMembersMonitor: {
      handler(event) {
        // console.log('addMembersMonitor', event);
        if (event) {
          const pool = this.poolId;
          this.newRules[0].members.forEach((member, i) => {
            // this.isUpdatePool
            //   ? this.updatePoolStatus()
            setTimeout(() => {
              this.addNewMember(member, pool);
            }, (i + 1) * 5000);
            // this.isUpdatePool ? this.addNewMember(member, this.poolId) : this.updatePoolStatus()
            // setTimeout(() => this.addNewMember(member, this.poolId), i * 4000);

            // this.updatePoolStatus();
          });
          // this.isCurrentLoading = false;
          // console.log('complete');
          this.listenerId = '';
          this.poolId = '';
          this.healthMonitorId = '';
          this.newRules = [];
          this.newFetch();
          const length = this.membersId.length;
          setTimeout(() => {
            this.membersId = [];
            this.isCurrentLoading = false;
            this.$router
              .push({ name: 'Balancer', params: { balancerid: this.balancerid } })
              .catch(() => {});
          }, length * 5000);
        }
      },
    },
    // poolId: {
    //   handler(event) {
    //     console.log('poolId', event);
    //   },
    // },
    healthMonitorId: {
      handler(event) {
        // console.log('монитор', event);
      },
    },
    fourthStep: {
      handler(event) {
        // console.log('4этап', event);
        // if (event) {
        //   this.newRules[0].members.forEach(member => {
        //     setTimeout(() => this.addNewMember(member, this.poolId), 2000);
        //     // this.updatePoolStatus();
        //   });
        // }
      },
    },
    // isUpdatePool: {
    //   handler(event) {
    //     if (event) {
    //       console.log(event);
    //     }
    //   },
    // },
    isUpdateBalancer: {
      async handler(event) {
        // console.log('isUpdateBalancer', event);
        if (event) {
          // console.log('update');
          await this.updateBalancerStatus(_, this.balancerid);
          // await this.updateBalancerStatus(this.balancerid).then(async data => {
          //   console.log('updateBalancerStatus-----add', data);
          // });
        } else if (!event && this.listenerId && !this.poolId) {
          // console.log('addPool');
          await this.addNewPool(this.listenerId);
        } else if (
          !event &&
          this.listenerId &&
          this.poolId &&
          this.healthMonitorId === '' &&
          !this.thirdStep
        ) {
          // console.log('addHealthMonitor');
          // console.log(this.healthMonitorId);
          await this.addNewHealthMonitor(this.poolId);
        } else if (!event && this.listenerId && this.poolId && this.healthMonitorId) {
          // console.log('checkPoolStatus');
          if (this.poolId) this.updatePoolStatus();
          this.fourthStep = true;
          // this.newRules[0].members.forEach((member, i) => {
          //   setTimeout(() => this.addNewMember(member, this.poolId), i * 2000);
          // this.updatePoolStatus();
          // });
        } else if (!event && this.listenerId && this.poolId && !this.healthMonitorId) {
          // console.log('checkPoolStatus');
          if (this.poolId) await this.updatePoolStatus();
          // this.fourthStep = true;
          // this.newRules[0].members.forEach((member, i) => {
          //   setTimeout(() => this.addNewMember(member, this.poolId), i * 2000);
          // this.updatePoolStatus();
          // });
        } else if (
          !event &&
          this.listenerId &&
          this.poolId &&
          this.healthMonitorId &&
          this.membersId
        ) {
        } else {
          // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
        }
      },
      immediate: false,
    },
    filtredTabs: function (event) {
      // console.log(event.length);
    },
  },
  mounted() {
    this.$nextTick(() => this.setIsMobile());
    // console.log(localStorage.getItem('addRule'));
    this.newFetch();
    if (localStorage.getItem('addRule')) {
      localStorage.removeItem('addRule');
      this.addRule();
    }
    window.addEventListener('scroll', this.onScroll, true);
    // console.log(this.$route.name);
  },
  beforeDestroy() {
    if (this.newRules.length > 0) this.newRules = [];
    window.removeEventListener('scroll', this.onScroll, true);
  },
  methods: {
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
    protocolServers(ar) {},
    setView(value) {
      this.tabs.find(el => el.component === 'RuleHeaders').show = value;
    },
    onScroll(e) {
      this.windowTop = window.document.body.clientHeight;
    },
    addRule() {
      if (this.$route.name !== 'containerRuleStart') {
        this.$router
          .push({ name: 'containerRuleStart' })
          .then(() => localStorage.setItem('addRule', 'true'))
          .then(() => this.$nextTick(() => this.addRule()));
      } else {
        // const numberOfRules = this.newRules.length + 1;
        // console.log('addRule', numberOfRules);

        // id: numberOfRules,
        this.newRules.push({
          id: this.currentId,
          ssl: {},
          description: '',
          members: [],
          // } else
          // algorithm: {},
          pool: {},
          healthmonitor: {},
          listener: {},
          headers: {},
          isSsl: false,
          isHeaders: false,
          server: {
            port: 80,
            protocol: 'TCP',
          },
          tabs: this.tabs,
          protocol: this.protocolRules,
          protocolServers: ['TCP', 'PROXY'],
          // name: 'Rule#' + this.currentId,
          name: '',
          balancer: {
            port: 80,
            protocol: 'TCP',
          },
          selected: 'RuleServers',
        });
        // name: 'Rule#' + numberOfRules,
        // console.log(this.newRules);
        setTimeout(() => {
          // const scrollDiv = document.getElementById(`rule${numberOfRules}`).offsetTop;
          const scrollDiv = document.getElementById(`rule${this.currentId}`).offsetTop;
          window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
        }, 500);
      }
    },
    deleteRule(id) {
      // console.log('deleteRule', id);
      return (this.newRules = this.newRules.filter(x => x.id !== id));
    },
    // clickTab(item, event) {
    //   console.log(item.id, event.originalEvent);
    // },
    state(item) {
      const state = item.admin_state_up;
      return {
        color: state === true ? 'success' : 'error',
        key: state === true ? 'running' : 'stopped',
      };
    },
    sslNames({ name }) {
      return name;
    },
    updateRule(event) {
      // console.log('updateRule', event);
      this.newRules[0] = Object.assign(this.newRules[0], event);
    },
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
    createCheck(payload) {
      // console.log('createHealthMonitor', payload);
      this.newRules.find(rule => rule.id === payload.id).healthmonitor = Object.assign(
        {},
        payload.healthmonitor
      );
    },
    createAlgorithm(payload) {
      // console.log('createAlgorithm', payload);
      this.newRules.find(rule => rule.id === payload.id).pool = Object.assign({}, payload.pool);
    },
    createMember(payload) {
      if (payload.members) {
        // console.log('createMember', payload);
        this.newRules.find(rule => rule.id === payload.id).members = payload.members;
      } else {
        // console.log(this.newRules);
        this.newRules.find(rule => rule.id === payload.id).members = [];
      }
    },
    createHeaders(payload) {
      // console.log('createHeaders', payload);
      this.newRules.find(rule => rule.id === payload.id).headers = Object.assign(
        {},
        payload.headers
      );
    },
    createConnect(payload) {
      this.newRules.find(rule => rule.id === payload.id).listener = Object.assign(
        {},
        payload.listener
      );
    },
    updateBalancerStatus(callback) {
      if (this.timerCnt < 5) this.timerCnt += 1;
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/checkBalancerStatus', {
            id: this.balancerid,
          })
          .then(data => {
            if (data.data.provisioning_status === 'PENDING_UPDATE') {
              this.isUpdateBalancer = true;
              this.updateBalancerStatus(callback);
            } else {
              this.timerCnt = 0;
              if (callback && typeof callback === 'function') callback();
              this.isUpdateBalancer = false;
              // this.isCurrentLoading = false;
              clearTimeout(this.timerId);
            }
          });
      }, 1000 * this.timerCnt);
    },
    updatePoolStatus(callback) {
      if (this.timerCnt < 5) this.timerCnt += 1;
      // console.log(this.poolId);
      if (this.poolId)
        this.timerId = setTimeout(() => {
          // console.log('updatePoolStatus', this.poolId);
          this.$store
            .dispatch('moduleStack/checkPoolStatus', {
              id: this.poolId,
            })
            .then(data => {
              // console.log(data);
              // if (data.data.provisioning_status === 'PENDING_UPDATE') {
              if (data.data.provisioning_status.includes('PENDING_')) {
                this.isUpdatePool = true;
                this.updatePoolStatus(callback);
              } else {
                this.timerCnt = 0;
                if (callback && typeof callback === 'function') callback();
                this.isUpdatePool = false;
                clearTimeout(this.timerId);
              }
            });
        }, 1000 * this.timerCnt);
    },
    addNewHealthMonitor(pool) {
      this.isUpdateBalancer = true;
      this.thirdStep = true;
      // console.log('addMonitor');
      const mon = this.createdPool || pool;
      const rule = this.newRules[0];
      const payload = {
        pool_id: mon,
        ...rule.healthmonitor,
      };
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('moduleStack/addHealthmonitor', payload)
          .then(data => {
            this.healthMonitorId = data.id;
            // this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'pools');
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            this.showError(err);
            reject(err);
          });
        // .finally(() => {
        // });
      });
    },
    addNewMember(member, pool) {
      this.isUpdatePool = true;
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('moduleStack/addMember', {
            member: member,
            id: pool,
          })
          .then(async data => {
            await this.updatePoolStatus();
            // console.log(data);
            this.membersId.push(data.id);
            await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'pools');
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            this.showError(err.response.data.badRequest.message);
            reject(err);
          });
      });
    },
    addNewPool(listener) {
      this.isUpdateBalancer = true;
      this.secondStep = true;
      const rule = this.newRules[0];
      const payload = {
        listener_id: listener,
        description: rule.description,
        name: rule.name,
        // protocol: rule.balancer.protocol === 'HTTPS' ? 'HTTP' : rule.balancer.protocol,
        protocol: rule.balancer.protocol === 'HTTPS' ? 'HTTP' : rule.server.protocol,
        // protocol: rule.balancer.protocol,
        ...rule.pool,
      };
      if (Object.keys(payload.session_persistence).length === 0) payload.session_persistence = null;
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('moduleStack/addPool', payload)
          .then(data => {
            this.poolId = data.id;
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            this.showError(err);
            reject(err);
          });
      });
    },
    addNewListener() {
      this.firstStep = true;
      const rule = this.newRules[0];
      // console.log(rule);
      const payload = {
        loadbalancer_id: this.balancerid,
        name: rule.name,
        protocol: rule.balancer.protocol === 'HTTPS' ? 'TERMINATED_HTTPS' : rule.balancer.protocol,
        protocol_port: rule.balancer.port,
        insert_headers: rule.isHeaders ? rule.headers : {},
        ...rule.listener,
      };
      if (rule.balancer.protocol === 'HTTPS')
        payload.default_tls_container_ref = rule.ssl.container_ref;
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('moduleStack/addListener', payload)
          .then(data => {
            this.isUpdateBalancer = true;
            this.listenerId = data.id;
            resolve(data);
          })
          .catch(err => {
            console.log(err);
            this.showError(err.response.data.faultstring);
            reject(err);
          });
      });
    },

    async addNewRule() {
      this.isCurrentLoading = true;
      const rule = this.newRules[0];
      // console.log(rule);
      const listener = await this.addNewListener();
      // this.membersId = rule.members.map(member => member.name);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "addRule": "Добавить правило",
      "port": "Порт",
      "navRule": {
        "view": "Обзор",
        "rule": "Правила"
      },
      "protocol": "Протокол",
      "tableHead": {
        "name": "Правило",
        "type": "Тип",
        "network": "Сеть",
        "shared": "Публичная",
        "config": "Конфигурация",
        "conditions": "",
        "status": "Статус",
        "state": "Состояние",
        "port": "Порты",
        "menu": "Меню"
      },
      "nav": {
        "servers": "Серверы",
        "connect": "Соединения",
        "check": "Проверка",
        "headers": "Заголовки",
        "algorithm": "Алгоритм"
      },
      "rule": {
        "title": "Новое правило",
        "hint": "Подсказка",
        "deschint": "Описание",
        "description": "Описание",
        "name": "Правило"
      },
      "tab": {
        "servers": "Серверы",
        "connect": "Соединения",
        "check": "Проверка",
        "headers": "Заголовки",
        "algorithm": "Алгоритм"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {

  //background: #fff;
  //border-color: #dee2e6 #dee2e6 #0b5ed7 #dee2e6;
  //color: #0b5ed7;
}
//.p-tabview-selected{
  //background: #0b5ed7;
  //border-bottom-color: #0b5ed7;
//}
//.p-tabview-nav-link {
  //background: #0b5ed7;
  //border-bottom-color: #0b5ed7;
//}
//  .p-highlight {

  //border-bottom-color: #0b5ed7;
  //}
.base-input__field {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: flex-start;
}
  .rule {
    &__form {
      +breakpoint(sm-and-up) {
        flexy(flex-start, flex-end, wrap);
      }
      +breakpoint(md-and-up) {
        flex-wrap: nowrap;
        padding-bottom: 1rem;
      }
    }

    &__field {
      +breakpoint(sm-and-up) {
        flex: 1 1 auto;
      }

      & + & {
        margin-top: 0.25rem;

        +breakpoint(sm-and-up) {
          margin-top: 0;
          margin-left: 1.5rem;
        }
      }
    }
    &__card {
      margin-bottom: 1rem;
    }
  }
.vps-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $vd-aside-size;
  }

  &__aside {
    //display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $vd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }

  &__context-menu {
    max-width: 100%;
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-detail {
  &__popover {
    .popover-inner {
      transform: translateX(-0.75rem)
    }
  }
}
</style>
