var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-table-row',_vm._b({staticClass:"billing-docs-table-row",attrs:{"columns":_vm.columns,"col-class":"billing-docs-table-row__col"},scopedSlots:_vm._u([{key:"number",fn:function(){return [(_vm.itemModule == 'modulePayment')?_c('plain-button',{attrs:{"href":_vm.docLink,"target":"_blanc","color":"primary"}},[_vm._v(" "+_vm._s(_vm.item.number)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.item.number)+" ")])]},proxy:true},{key:"date",fn:function(){return [_vm._v(" "+_vm._s(_vm.$d(_vm.item.date, 'short'))+" ")]},proxy:true},{key:"phone",fn:function(){return [_vm._v(" "+_vm._s(_vm.item.phone || '')+" ")]},proxy:true},{key:"customer",fn:function(){return [_vm._v(" "+_vm._s(_vm.item.sender_name || _vm.item.client_name)+" ")]},proxy:true},{key:"company",fn:function(){return [_vm._v(" "+_vm._s(_vm.item.company_name || '')+" ")]},proxy:true},{key:"contract_name",fn:function(){return [_vm._v(" "+_vm._s(_vm.item.contract_name || '')+" ")]},proxy:true},{key:"dtype",fn:function(){return [_vm._v(" "+_vm._s(_vm.item.dtype === 'Счёт' ? 'Счёт-фактура' : _vm.item.dtype || '')+" ")]},proxy:true},{key:"cost",fn:function(){return [_vm._v(" "+_vm._s(_vm.$n(_vm.item.cost, 'currency') || '')+" ")]},proxy:true},{key:"status",fn:function(){return [_c('span',{class:`${_vm.item.statusColor}-color`},[_vm._v(" "+_vm._s(_vm.item.status)+" ")])]},proxy:true},(_vm.item.link)?{key:"download",fn:function(){return [_c('plain-button',{attrs:{"href":_vm.item.link.href,"target":_vm.item.link.target,"title":_vm.item.link.title || _vm.item.number,"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('download'))+" ")])]},proxy:true}:null,(_vm.hasPayTool)?{key:"pay",fn:function(){return [_c('div',{staticClass:"doc-info"},[(_vm.hasPayTool && !_vm.isBank && _vm.item.status_orig === '1')?_c('plain-button',{staticClass:"doc-info__button",attrs:{"disabled":!_vm.tools.pay.isEnable(_vm.current) ||
          _vm.item.paymethod_module === 'pmruwire' ||
          _vm.isTextVerification,"color":"primary"},on:{"click":_vm.pay}},[_vm._v(" "+_vm._s(_vm.$t('pay'))+" ")]):_vm._e(),(_vm.isTextVerification)?_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.text,
          autoHide: false,
          placement: 'top-start',
          container: false,
        }),expression:"{\n          content: text,\n          autoHide: false,\n          placement: 'top-start',\n          container: false,\n        }"}],staticClass:"doc-info__button",attrs:{"icon":"warn","size":"medium","color":"del","tabindex":"-1"}}):_vm._e()],1),(_vm.isBank)?_c('plain-button',{staticClass:"doc-info__button",attrs:{"href":_vm.link,"target":'_blank',"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('download'))+" ")]):_vm._e()]},proxy:true}:null,(_vm.contextMenu.length)?{key:"more",fn:function(){return [_c('v-popover',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closePopoverOnOutside),expression:"closePopoverOnOutside"}],attrs:{"placement":"left","open":_vm.isPopoverOpen,"trigger":"manual","popover-class":"billing-docs-table-row__popover"},scopedSlots:_vm._u([{key:"popover",fn:function(){return [_c('base-context-menu',{staticClass:"billing-docs-table-row__context-menu",attrs:{"tariff":_vm.item,"menu":_vm.contextMenu,"module-main":_vm.moduleMain,"tools":_vm.tools}})]},proxy:true}],null,false,27144235)},[_c('plain-button',{staticClass:"billing-docs-table-row__more",attrs:{"icon":"more"},on:{"click":_vm.togglePopoverState}})],1)]},proxy:true}:null],null,true)},'base-table-row',_vm.item,false))
}
var staticRenderFns = []

export { render, staticRenderFns }